import questions from './selfAssessmentForm/questions';

const calculatePersona = selectedButton => {
  // Initialize criteria scores.
  const criteriaScores = {
    workfully: 0,
    respectfully: 0,
    kindfully: 0,
  };

  // Initialize principles scores.
  const principleScores = {
    fair: 0,
    understand: 0,
    laugh: 0,
    learn: 0,
    'ask why': 0,
  };

  const personas = {
    'Great Leader': {
      name: 'Great Leader',
      description: (
        <>
          <p>
            A "Great Leader" is someone who embodies high levels of respect,
            kindness, and diligent work ethics. They lead by example,
            demonstrating fairness and empathy in all their interactions. This
            individual understands the profound impact of their decisions and
            takes into account the perspectives of all parties involved.
          </p>
          <p>
            They foster a sense of inclusivity and respect diverse viewpoints,
            ensuring everyone feels heard and valued. A "Great Leader" also
            understands the value of humor in creating a relaxed and positive
            work environment, contributing to the team's well-being and
            camaraderie. They are lifelong learners, always open to new ideas
            and driven by curiosity.
          </p>
          <p>
            The "Great Leader" is known for their kindness and empathy. They
            strive to treat everyone fairly, taking time to understand others'
            perspectives. They acknowledge the restorative power of laughter and
            positivity on overall well-being. Recognizing the value of mistakes
            as learning opportunities, they embrace personal growth and
            evolution. Their habit of asking the reasons behind decisions
            reflects their desire for comprehensive understanding and
            transparency.
          </p>
          <p>
            When it comes to their work ethics, they demonstrate a commitment to
            equality, offering equal opportunities to everyone, regardless of
            background. They are meticulous in understanding the objectives of
            their tasks, maintaining an optimistic outlook even during
            challenging situations. They value diversity, actively seeking input
            from colleagues of different backgrounds and experiences. Their
            drive to challenge conventional practices encourages a culture of
            innovation and continuous improvement.
          </p>
          <p>
            Overall, a "Great Leader" is a beacon of inspiration. Their fair,
            understanding, and diligent approach fosters an atmosphere of
            positivity, inclusivity, and productivity, making them an invaluable
            asset to any team.
          </p>
        </>
      ),
    },

    'Tenacious Achiever': {
      name: 'Tenacious Achiever',
      description: (
        <>
          <p>
            The Tenacious Achiever is a driven individual with a strong work
            ethic, often seen prioritizing tasks and projects to ensure their
            successful completion. They are firm believers in providing equal
            opportunities for all, regardless of background, thus fostering a
            diverse and inclusive work environment. Their thoroughness in
            understanding the objectives of the projects or tasks they undertake
            contributes to their efficiency and effectiveness.
          </p>
          <p>
            Their resilience and ability to find reasons to smile or laugh when
            facing difficult situations are significant strengths that help them
            maintain positivity and morale. They actively seek input from
            colleagues with different backgrounds or experiences, which allows
            for a broader scope of ideas and viewpoints in their work.
            Additionally, they enjoy challenging discussions that question
            common perceptions or widely accepted practices, often leading to
            innovative solutions.
          </p>
          <p>
            However, despite these strengths, the Tenacious Achiever has areas
            they might need to work on. They may not always consider the impact
            of their decisions on all parties involved, which can lead to
            potential conflicts or issues down the line. Their open-mindedness
            can be limited, and they might struggle to understand viewpoints
            that are different from their own. While they recognize the value of
            humor in difficult situations, they may not always see it as a tool
            for building relationships.
          </p>
          <p>
            Furthermore, while they are fair in providing opportunities, their
            fairness might not extend to treating everyone equally, especially
            if personal feelings are involved. They might not often put
            themselves in others' shoes to understand their perspectives. While
            they believe in the positive impact of laughter, they might not see
            it as essential for overall well-being. Their focus on task
            completion might overshadow the importance of learning from
            mistakes, and they might accept decisions at face value rather than
            asking for the reasons behind them.
          </p>
          <p>
            In summary, the Tenacious Achiever is a hardworking, resilient
            individual who thrives in diverse, challenging environments.
            However, they might need to cultivate a more holistic approach that
            includes empathy, open-mindedness, and inquisitiveness to fully
            harness their potential and foster better relationships in the
            workplace.
          </p>
        </>
      ),
    },

    'Inquisitive Dynamo': {
      name: 'Inquisitive Dynamo',
      description: (
        <>
          <p>
            The Inquisitive Dynamo is a highly engaged and determined individual
            who shows a strong commitment to their work. They are firm believers
            in equal opportunities and make sure they fully understand the
            objectives of any project or task they are involved in. This person
            exhibits strong resilience in the face of adversity, often finding a
            reason to smile or laugh in difficult situations. This trait,
            coupled with their propensity to actively seek input from a diverse
            range of colleagues, fosters a dynamic and inclusive work
            environment.
          </p>
          <p>
            Moreover, they have a deep appreciation for open-mindedness and are
            adept at understanding viewpoints that differ from their own, which
            helps to broaden their perspective and enrich their problem-solving
            abilities. They also recognize the value of humor in building strong
            relationships, thereby contributing to team cohesion. Additionally,
            they exhibit a strong desire to learn and enjoy delving into various
            topics out of pure intellectual curiosity. This individual is also
            thorough in their understanding of new concepts or ideas, often
            asking "why" to fully grasp them.
          </p>
          <p>
            However, despite these strengths, the Inquisitive Dynamo has certain
            areas that might require improvement. They may struggle with
            consistently treating everyone fairly, particularly when their
            personal feelings are involved. They might also find it challenging
            to put themselves in others' shoes to better understand their
            perspective.
          </p>
          <p>
            While they appreciate the power of humor and laughter in work and
            relationship-building, they may not fully recognize its impact on
            overall well-being. Despite their inclination for understanding new
            concepts, they might not place the same emphasis on learning from
            personal mistakes, which can hinder their growth. Finally, they may
            tend to accept decisions at face value rather than questioning the
            reasons behind them, potentially limiting their understanding and
            involvement in the decision-making process.
          </p>
          <p>
            Overall, the Inquisitive Dynamo is a resilient, open-minded, and
            committed individual who thrives in diverse, challenging
            environments. However, to fully harness their potential, they might
            need to cultivate more empathy, self-reflection, and proactive
            questioning in their interactions and decision-making processes.
          </p>
        </>
      ),
    },

    'Reflective Equilibrator': {
      name: 'Reflective Equilibrator',
      description: (
        <>
          <p>
            The Reflective Equilibrator embodies the spirit of fairness,
            empathy, and open-mindedness. They are individuals who consider the
            impact of their decisions on all parties involved, taking into
            account diverse perspectives and showing respect for differing
            viewpoints. They greatly value open-mindedness and invest time and
            effort into understanding perspectives that differ from their own,
            fostering a culture of mutual respect and understanding.
          </p>
          <p>
            Humor plays a crucial role in their approach to relationship
            building, as they believe it strengthens connections and bridges
            gaps between team members. They are naturally curious and passionate
            learners who find joy in reading or researching various topics, not
            for a particular end goal but purely for the pleasure of learning.
            They see the value of asking 'why' to understand new concepts or
            ideas thoroughly.
          </p>
          <p>
            Moreover, the Reflective Equilibrator aims to treat everyone fairly,
            allowing them to set aside personal feelings and biases. They
            frequently empathize with others by putting themselves in their
            shoes, promoting a sense of camaraderie and understanding within the
            team. They recognize the positive impact of laughter on overall
            well-being and see the value in learning from mistakes as part of
            personal growth. Additionally, they prefer to understand the reasons
            behind decisions rather than accepting them at face value.
          </p>
          <p>
            However, when it comes to task-oriented attributes and taking
            initiative, the Reflective Equilibrator might face challenges. They
            might not focus as much on providing equal opportunities for all or
            ensuring they fully understand the objectives of projects or tasks.
            Their resilience in challenging situations could be limited,
            potentially impacting their morale during high-stress periods. They
            might not actively seek out diverse inputs or enjoy challenging
            commonly held perceptions, which could limit innovation and
            inclusivity.
          </p>
          <p>
            In summary, the Reflective Equilibrator is a kind, respectful, and
            empathetic team member who values understanding, learning, and
            laughter. They may, however, need to work on their initiative,
            resilience, and inclusion of diverse ideas to maximize their
            potential and contribution to the workplace.
          </p>
        </>
      ),
    },

    'Assertive Innovator': {
      name: 'Assertive Innovator',
      description: (
        <>
          <p>
            An Assertive Innovator is a strongly driven individual, eager to
            explore uncharted territories and push boundaries. They exhibit a
            powerful dedication to diversity and inclusivity, demonstrated by
            their belief in providing equal opportunities. This person is
            thorough in their approach to work, ensuring they fully understand
            the objectives of the tasks and projects they are involved in.
          </p>
          <p>
            The Assertive Innovator flourishes in challenging conventional
            wisdom, and their resiliency is pronounced as they tend to remain
            upbeat even during tough situations. They have a natural propensity
            to involve colleagues with diverse experiences, enriching the
            ideation and execution processes of their work. Their interest lies
            in stimulating discussions that shake common perceptions, leading to
            innovative and sometimes disruptive solutions.
          </p>
          <p>
            However, this individual's intense focus on innovation and task
            achievement might sometimes come at the cost of empathy and
            comprehensive decision making. They might not always fully consider
            the impact of their decisions on all parties involved, and they may
            not actively try to understand viewpoints that differ from their
            own.
          </p>
          <p>
            Their ability to see the humor in tough situations and belief in the
            positive impact of laughter on well-being are considerable, yet this
            does not translate into a tool for relationship-building for them.
            Their thirst for learning is primarily directed towards specific
            work-related objectives and not purely for the joy of learning.
          </p>
          <p>
            The Assertive Innovator can be rigid in their approach and prefers
            to challenge decisions rather than understanding the reasons behind
            them. They may sometimes neglect the importance of learning from
            mistakes, viewing them as setbacks rather than opportunities for
            growth.
          </p>
          <p>
            In summary, the Assertive Innovator is a forward-thinking
            individual, thriving in a dynamic work environment that values
            diversity and innovation. They are task-oriented and resilient, but
            might need to cultivate empathy, open-mindedness, and a deeper
            curiosity for learning to fully optimize their potential and
            interpersonal relationships.
          </p>
        </>
      ),
    },

    'Harmonious Explorer': {
      name: 'Harmonious Explorer',
      description: (
        <>
          <p>
            The Harmonious Explorer is a respectful and empathetic individual
            who deeply values open-mindedness and considers the impacts of their
            decisions on all parties involved. This individual tends to excel in
            interpersonal communication, often employing humor as a tool to
            foster strong relationships with others.
          </p>
          <p>
            One of their defining characteristics is their love for learning.
            Their innate curiosity drives them to delve deep into subjects, not
            necessarily for work-related purposes, but simply for the joy of
            broadening their understanding and knowledge base. They display a
            strong analytical inclination and are not shy to ask the 'why'
            behind new concepts and ideas.
          </p>
          <p>
            Despite their strengths, the Harmonious Explorer has areas where
            they might need to improve. They might struggle with equal
            opportunity practices, potentially being influenced by their own
            preconceptions or biases, consciously or subconsciously. Their
            approach to projects and tasks might lack the required rigor as they
            might not always ensure they fully comprehend the objectives.
          </p>
          <p>
            The Harmonious Explorer is not necessarily adept at seeking diverse
            opinions or challenging established norms, which can result in a
            lack of innovation. They might not always enjoy discussions that
            challenge common perceptions or widely accepted practices. They
            might also struggle to maintain positivity during challenging times,
            which can impact their resilience.
          </p>
          <p>
            Furthermore, while they show empathy and concern for the feelings of
            others when making decisions, they might struggle with fairness,
            particularly if their personal feelings toward individuals are
            involved. They may not always put themselves in others' shoes to
            understand their perspectives or believe in the healing power of
            laughter for overall well-being. Lastly, they might not place a high
            value on learning from their own mistakes or understanding the
            reasons behind decisions, preferring instead to accept them at face
            value.
          </p>
          <p>
            Overall, the Harmonious Explorer is a thoughtful and curious
            individual who fosters positive relationships and has a deep love
            for learning. To fully thrive, they may need to focus on embracing
            diversity, challenging the status quo, and learning from their own
            and others' experiences.
          </p>
        </>
      ),
    },

    'Empathetic Optimist': {
      name: 'Empathetic Optimist',
      description: (
        <>
          <p>
            The Empathetic Optimist is an individual who excels at interpersonal
            relationships due to their inherent kindness and empathy. This
            person strives to treat everyone fairly, often setting aside
            personal feelings to ensure justice and fairness in their
            interactions. They have a strong ability to empathize with others,
            often adopting their perspective to better understand their
            viewpoints and experiences.
          </p>
          <p>
            They firmly believe in the healing power of laughter and its
            positive impact on overall well-being. They are often seen as a
            source of positivity and resilience in a team, contributing to the
            overall emotional health of the group. Learning from mistakes is an
            integral part of their personal growth, demonstrating their humility
            and adaptability.
          </p>
          <p>
            Additionally, the Empathetic Optimist shows a pronounced inclination
            to question the reasons behind decisions rather than merely
            accepting them. This characteristic not only demonstrates their
            active engagement but also encourages transparency and
            accountability.
          </p>
          <p>
            However, the Empathetic Optimist may face challenges when it comes
            to task-oriented attributes. They may not always prioritize
            providing equal opportunities for everyone or fully grasp the
            objectives of projects they're involved in. Their ability to find
            humor and positivity in challenging situations might be limited,
            potentially affecting their resilience. They may also struggle with
            seeking diverse inputs or challenging common perceptions, which can
            limit innovation and inclusivity.
          </p>
          <p>
            Moreover, while they are empathetic, they may not always consider
            the broader impact of their decisions on all involved parties. Their
            curiosity may not extend to understanding different viewpoints or
            pursuing learning for its own sake. They may also underutilize humor
            as a tool for building relationships, and their questioning nature
            does not necessarily extend to understanding new concepts or ideas.
          </p>
          <p>
            In summary, the Empathetic Optimist is an individual who brings
            positivity and fairness to the workplace. They thrive in
            environments that value empathy and learning from mistakes. However,
            they might need to improve their inclusivity, resilience, and
            curiosity to reach their full potential and contribute more
            significantly to their work environment.
          </p>
        </>
      ),
    },

    'Singular Contributor': {
      name: 'Singular Contributor',
      description: (
        <>
          <p>
            A Singular Contributor can be quite challenging to work with due to
            their distinctive approach towards work and interpersonal
            interactions. When making decisions, they may not always consider
            the impact on all parties involved, potentially creating disconnects
            within the team.
          </p>
          <p>
            Their perspective can be rigid, often finding it hard to value
            open-mindedness or understand viewpoints different from their own.
            This lack of flexibility might inhibit their capacity to adapt to
            different situations or people.
          </p>
          <p>
            In the realm of interpersonal relationships, they may not see humor
            as an essential ingredient for building strong connections.
            Moreover, their interest in learning for its own sake can be
            minimal, and they might not invest time in reading or researching a
            topic unless it directly benefits their work. When encountering new
            concepts or ideas, they might not feel the need to ask 'why,'
            potentially missing out on a deeper understanding. Similarly, in
            their interactions, they might not always treat everyone fairly,
            especially when personal feelings come into play. Putting themselves
            in others' shoes to understand their perspective might not come
            naturally to them, which could lead to empathy gaps.
          </p>
          <p>
            Although laughter can have a positive impact on overall well-being,
            they might not fully acknowledge this. When it comes to personal
            growth, they might not see the value in learning from their
            mistakes, possibly hindering their development.
          </p>
          <p>
            Finally, when it comes to their work ethics, they might not fully
            commit to providing equal opportunities for all or ensuring that
            they understand the objectives of projects or tasks thoroughly.
            Resilience could be a challenge for them, as they may not always
            find reasons to smile or laugh when facing difficult situations.
            They may also not actively seek input from colleagues with different
            backgrounds or experiences, and might not enjoy discussions that
            challenge common perceptions or widely accepted practices.
          </p>
          <p>
            In conclusion, a Singular Contributor might struggle in a team
            environment due to their low scores in these key areas. However,
            it's important to remember that this description points out areas
            for potential growth and development. With the right coaching,
            training, and personal commitment, a Singular Contributor can
            cultivate these essential skills and traits, becoming a more
            well-rounded and effective team player.
          </p>
        </>
      ),
    },
  };

  for (const questionIndex in selectedButton) {
    const answer = parseInt(selectedButton[questionIndex]);
    const question = questions[questionIndex];

    if (question && question.criteria) {
      // Accumulate criteria scores.
      question.criteria.forEach(criteria => {
        criteriaScores[criteria] += answer;
      });
    }

    if (question && question.principle) {
      // Accumulate principle scores.
      question.principle.forEach(principle => {
        principleScores[principle] += answer;
      });
    }
  }

  const highCriteria = Object.keys(criteriaScores).filter(
    criteria => criteriaScores[criteria] >= 18
  ); // High criteria

  let persona;

  if (
    highCriteria.includes('workfully') &&
    highCriteria.includes('respectfully') &&
    highCriteria.includes('kindfully')
  ) {
    persona = personas['Great Leader'];
  } else if (
    highCriteria.includes('workfully') &&
    highCriteria.includes('respectfully')
  ) {
    persona = personas['Tenacious Achiever'];
  } else if (
    highCriteria.includes('workfully') &&
    highCriteria.includes('kindfully')
  ) {
    persona = personas['Inquisitive Dynamo'];
  } else if (
    highCriteria.includes('respectfully') &&
    highCriteria.includes('kindfully')
  ) {
    persona = personas['Reflective Equilibrator'];
  } else if (highCriteria.includes('workfully')) {
    persona = personas['Assertive Innovator'];
  } else if (highCriteria.includes('respectfully')) {
    persona = personas['Harmonious Explorer'];
  } else if (highCriteria.includes('kindfully')) {
    persona = personas['Empathetic Optimist'];
  } else {
    persona = personas['Singular Contributor'];
  }

  return { persona, criteriaScores, principleScores };
};

export default calculatePersona;
