import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import "@aws-amplify/ui-react/styles.css";
import { Auth, Hub } from 'aws-amplify';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import { useLoginTheme  } from './../LoginTheme.js';
import { useStyles } from './../RegisterStyles.js';
import { GrClose } from 'react-icons/gr';

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const theme = useLoginTheme();

  useEffect(() => {
    const checkUser = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    
    checkUser();
    const listener = Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        setUser(payload.data);
        navigate('/');
        window.location.reload();
      } else if (payload.event === 'signOut') {
        window.location.reload();
      }
    });

    return () => Hub.remove('auth', listener);
  }, []);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setLoggedIn(false);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const handleCloseAuthenticator = () => {
    navigate("/");
  };

  return (
    <div className="login-page" style={{ position: 'relative' }}>
      <div className={classes.authenticatorWrapper}>
        {!loggedIn && (
              <ThemeProvider theme={theme}>
          <Authenticator
            onStateChange={(authState) => {
              if (authState === 'signedIn') {
                setLoggedIn(true);
                window.location.reload();
              }
            }}
          />
            </ThemeProvider>
        )}
        {loggedIn && (
          <div>
            <p>You are already logged in.</p>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSignOut}
            >
              Sign Out
            </Button>
          </div>
        )}
              <div className={classes.closeIconContainer}>
        <button className={classes.closeIcon} onClick={handleCloseAuthenticator}>
          <GrClose />
        </button>
      </div>
      </div>
    </div>
  );
};

export default Login;