/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://vcsysgxi3bb2dkp2o4px4fmz5e.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-n3vblff2gja2rm5rbsffikwvza",
    "aws_cognito_identity_pool_id": "eu-central-1:51a84d57-a4e1-4974-ba06-8fbfc2c75cc4",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_n0J1XEcTY",
    "aws_user_pools_web_client_id": "22kfob4iqs3imsi8j4srq9chor",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
