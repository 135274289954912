/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPeerReview = /* GraphQL */ `
  mutation CreatePeerReview(
    $input: CreatePeerReviewInput!
    $condition: ModelPeerReviewConditionInput
  ) {
    createPeerReview(input: $input, condition: $condition) {
      id
      name
      description
      uniqueId
      owner
      reviewer
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePeerReview = /* GraphQL */ `
  mutation UpdatePeerReview(
    $input: UpdatePeerReviewInput!
    $condition: ModelPeerReviewConditionInput
  ) {
    updatePeerReview(input: $input, condition: $condition) {
      id
      name
      description
      uniqueId
      owner
      reviewer
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePeerReview = /* GraphQL */ `
  mutation DeletePeerReview(
    $input: DeletePeerReviewInput!
    $condition: ModelPeerReviewConditionInput
  ) {
    deletePeerReview(input: $input, condition: $condition) {
      id
      name
      description
      uniqueId
      owner
      reviewer
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSelfAssessment = /* GraphQL */ `
  mutation CreateSelfAssessment(
    $input: CreateSelfAssessmentInput!
    $condition: ModelSelfAssessmentConditionInput
  ) {
    createSelfAssessment(input: $input, condition: $condition) {
      id
      name
      description
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSelfAssessment = /* GraphQL */ `
  mutation UpdateSelfAssessment(
    $input: UpdateSelfAssessmentInput!
    $condition: ModelSelfAssessmentConditionInput
  ) {
    updateSelfAssessment(input: $input, condition: $condition) {
      id
      name
      description
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSelfAssessment = /* GraphQL */ `
  mutation DeleteSelfAssessment(
    $input: DeleteSelfAssessmentInput!
    $condition: ModelSelfAssessmentConditionInput
  ) {
    deleteSelfAssessment(input: $input, condition: $condition) {
      id
      name
      description
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEmailSubscription = /* GraphQL */ `
  mutation CreateEmailSubscription(
    $input: CreateEmailSubscriptionInput!
    $condition: ModelEmailSubscriptionConditionInput
  ) {
    createEmailSubscription(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEmailSubscription = /* GraphQL */ `
  mutation UpdateEmailSubscription(
    $input: UpdateEmailSubscriptionInput!
    $condition: ModelEmailSubscriptionConditionInput
  ) {
    updateEmailSubscription(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEmailSubscription = /* GraphQL */ `
  mutation DeleteEmailSubscription(
    $input: DeleteEmailSubscriptionInput!
    $condition: ModelEmailSubscriptionConditionInput
  ) {
    deleteEmailSubscription(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCandidate = /* GraphQL */ `
  mutation CreateCandidate(
    $input: CreateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    createCandidate(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCandidate = /* GraphQL */ `
  mutation UpdateCandidate(
    $input: UpdateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    updateCandidate(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCandidate = /* GraphQL */ `
  mutation DeleteCandidate(
    $input: DeleteCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    deleteCandidate(input: $input, condition: $condition) {
      id
      name
      email
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
