import React, { useState, useEffect } from 'react';
import './Carousel.css';
import Button from '@mui/material/Button';
import frames from '../../BlogData';
import { useNavigate } from 'react-router-dom';

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRotating, setIsRotating] = useState(true); // Add state variable for rotation
  const navigate = useNavigate(); // Add the useNavigate hook

  useEffect(() => {
    let interval;
    if (isRotating) {
      interval = setInterval(() => {
        setCurrentIndex(currentIndex => (currentIndex + 1) % frames.length);
      }, 8000);
    }

    return () => clearInterval(interval);
  }, [isRotating, frames.length]);

  const handlePrevClick = () => {
    setCurrentIndex(
      currentIndex => (currentIndex + frames.length - 1) % frames.length
    );
    setIsRotating(false); // Stop rotating when previous button is clicked
  };

  const handleNextClick = () => {
    setCurrentIndex(currentIndex => (currentIndex + 1) % frames.length);
    setIsRotating(false); // Stop rotating when next button is clicked
  };

  const handleReadMoreClick = () => {
    const link = frames[currentIndex].link;
    navigate(link);
  };

  return (
    <div className='fullscreen-carousel-container'>
      {frames.map((frame, index) => (
        <div
          key={index}
          className={`fullscreen-carousel-frame ${
            index === currentIndex ? 'active' : ''
          }`}
          style={{
            backgroundColor: frame.color,
            transition: 'opacity 0.5s ease',
            opacity: index === currentIndex ? 1 : 0,
          }}
        >
          <div
            className='carousel-item-details'
            style={{ backgroundColor: '#200063' }}
          >
            <h2>{frame.title}</h2>
            <h4>{frame.paragraph}</h4>

            <div className='carousel-read-more'>
              <Button
                onClick={() => handleReadMoreClick()}
                variant='contained'
                color='primary'
                style={{
                  textTransform: 'capitalize',
                  backgroundColor: '#0D99FF',
                  color: '#FEFEFE',
                }}
              >
                Read More
              </Button>
            </div>
          </div>
          <div className='carousel-image-container'>
            <img
              src={frame.image}
              alt={`Frame ${index}`}
              className='carousel-image'
            />
          </div>
        </div>
      ))}
      <div className='fullscreen-carousel-controls'>
        <Button
          onClick={handlePrevClick}
          variant='contained'
          color='primary'
          style={{
            textTransform: 'capitalize',
            backgroundColor: '#0D99FF',
            color: '#FEFEFE',
          }}
        >
          Back
        </Button>
        <Button
          style={{
            textTransform: 'capitalize',
            backgroundColor: '#0D99FF',
            color: '#FEFEFE',
          }}
          onClick={handleNextClick}
          variant='contained'
          color='primary'
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default Carousel;
