import React, { useState } from 'react';
import './ImageRightTextLeft.css';
import Button from '@mui/material/Button';

function ImageRightTextLeftBottom() {
  const [isVisible, setIsVisible] = useState(false);
  const [isFairVisiblew, setIsFairVisiblew] = useState(false);
  const [isUnderstandVisiblew, setIsUnderstandVisiblew] = useState(false);
  const [isLaughVisiblew, setIsLaughVisiblew] = useState(false);
  const [isLearnVisiblew, setIsLearnVisiblew] = useState(false);
  const [isAskWhyVisiblew, setIsAskWhyVisiblew] = useState(false);
  const [isFairVisibler, setIsFairVisibler] = useState(false);
  const [isUnderstandVisibler, setIsUnderstandVisibler] = useState(false);
  const [isLaughVisibler, setIsLaughVisibler] = useState(false);
  const [isLearnVisibler, setIsLearnVisibler] = useState(false);
  const [isAskWhyVisibler, setIsAskWhyVisibler] = useState(false);
  const [isFairVisiblek, setIsFairVisiblek] = useState(false);
  const [isUnderstandVisiblek, setIsUnderstandVisiblek] = useState(false);
  const [isLaughVisiblek, setIsLaughVisiblek] = useState(false);
  const [isLearnVisiblek, setIsLearnVisiblek] = useState(false);
  const [isAskWhyVisiblek, setIsAskWhyVisiblek] = useState(false);

  const toggleFairVisibilityw = () => {
    setIsFairVisiblew(!isFairVisiblew);
  };

  const toggleUnderstandVisibilityw = () => {
    setIsUnderstandVisiblew(!isUnderstandVisiblew);
  };

  const toggleLaughVisibilityw = () => {
    setIsLaughVisiblew(!isLaughVisiblew);
  };

  const toggleLearnVisibilityw = () => {
    setIsLearnVisiblew(!isLearnVisiblew);
  };

  const toggleAskWhyVisibilityw = () => {
    setIsAskWhyVisiblew(!isAskWhyVisiblew);
  };

  const toggleFairVisibilityr = () => {
    setIsFairVisibler(!isFairVisibler);
  };

  const toggleUnderstandVisibilityr = () => {
    setIsUnderstandVisibler(!isUnderstandVisibler);
  };

  const toggleLaughVisibilityr = () => {
    setIsLaughVisibler(!isLaughVisibler);
  };

  const toggleLearnVisibilityr = () => {
    setIsLearnVisibler(!isLearnVisibler);
  };

  const toggleAskWhyVisibilityr = () => {
    setIsAskWhyVisibler(!isAskWhyVisibler);
  };

  const toggleFairVisibilityk = () => {
    setIsFairVisiblek(!isFairVisiblek);
  };

  const toggleUnderstandVisibilityk = () => {
    setIsUnderstandVisiblek(!isUnderstandVisiblek);
  };

  const toggleLaughVisibilityk = () => {
    setIsLaughVisiblek(!isLaughVisiblek);
  };

  const toggleLearnVisibilityk = () => {
    setIsLearnVisiblek(!isLearnVisiblek);
  };

  const toggleAskWhyVisibilityk = () => {
    setIsAskWhyVisiblek(!isAskWhyVisiblek);
  };
  return (
    <div>
      <div className='image-right-text-left-container-bottom'>
        <div className='image-right-text-left-right-bottom'>
          <div className='image-right-text-left-content-bottom'>
            <h1>Fully Principles</h1>
          </div>
          <div className='image-right-text-left-content-bottom'></div>
        </div>
        <div className='image-right-text-left-left-bottom'>
          <img
            src='/images/wrkfully-image-2.png'
            alt='fully-principles'
            className='image-right-text-left-image-bottom'
          />
        </div>
      </div>
      <div className='body-image-rightc'>
        <br />
        <br />
        <div className='apply-it-content-a-title'>
          <h3>
            <strong>Pillar 1: Workfully</strong>
          </h3>
          <h3>
            Inspire innovation in a diverse and inclusive workplace, fostering a
            culture of continuous growth.
          </h3>
          <br />
          <p>
            <strong>Here’s how to apply it:</strong>
          </p>
        </div>
        <div className='apply-it-content-a'>
          <h4>
            <strong>Be Fair: </strong>
          </h4>
          <span>
            The value "be fair" in this context refers to cultivating an
            equitable and inclusive work environment that empowers everyone to
            contribute to their fullest potential. This approach can be critical
            for fostering creative thinking and innovation, as it encourages
            employees to think outside the box, share and experiment with new
            ideas.
          </span>
          <br />
          <Button
            onClick={toggleFairVisibilityw}
            variant='text'
            style={{ color: 'black' }}
          >
            {isFairVisiblew ? 'View less details' : 'View more details'}
          </Button>
          {isFairVisiblew && (
            <div>
              <p>
                <strong>Let's break it down: </strong>
                <br />
                Valuing Creative Thinking and Innovation: Promoting fairness
                includes recognizing and appreciating the importance of creative
                thinking and innovation in the workplace. It involves creating
                an environment that encourages employees to explore new ideas,
                take calculated risks, and experiment with innovative
                approaches.
                <br />
                <br />
                Empowering People to Think Outside the Box: Fairness means
                providing employees with the freedom and support to think beyond
                conventional boundaries. It involves empowering individuals to
                challenge established norms, question assumptions, and bring
                fresh perspectives to problem-solving and decision-making
                processes.
                <br />
                <br />
                Encouraging Sharing and Collaboration: Fairness fosters an
                atmosphere where knowledge and ideas are shared openly and
                collaboratively. It promotes a culture of constructive feedback,
                active listening, and collective problem-solving, where
                everyone's input is valued and considered.
                <br />
                <br />
                Promoting Psychological Safety: Creating a psychologically safe
                workplace is an essential component of fairness. It means
                cultivating an environment where employees feel comfortable
                speaking up, expressing their opinions, and taking risks without
                fear of negative consequences. Psychological safety encourages
                open communication, trust, and a sense of belonging.
                <br />
                <br />
                Recognizing and Rewarding Contributions: Fairness involves
                acknowledging and rewarding individual and collective
                contributions. It means ensuring that recognition, rewards, and
                opportunities for growth are distributed based on merit and
                contribution, fostering a sense of fairness and motivation among
                employees. In essence, this principle encourages a culture of
                inclusivity, and empowerment. Its about creating a space where
                everyone has an equal opportunity to contribute, experiment,
                innovate and grow. Its about doing what’s right and nurturing a
                psychologically safe workplace, that can significantly boost
                employee engagement, productivity, and overall job satisfaction.
                <br />
                <br />
                <strong>Example: </strong> <br />
                Imagine you are leading a project. Over the course of the
                project, you notice that one of your team members, Alice, has
                been putting in extra hours and effort to ensure the project
                stays on schedule. Despite not being the most senior member of
                the team, Alice's dedication has been key in overcoming several
                challenging obstacles. Recognizing this, you decide to publicly
                commend Alice during the next team meeting and also propose her
                for a company-wide recognition award. By doing so, you ensure
                that Alice's contribution is appropriately acknowledged and
                rewarded, demonstrating that fairness and meritocracy are deeply
                ingrained in your team's culture.
              </p>
            </div>
          )}
        </div>
        <div className='apply-it-content-a'>
          <h4>
            <strong>Understand:</strong>
          </h4>
          <span>
            Understand as a core value in this context emphasizes the importance
            of acknowledging and appreciating the unique perspectives,
            experiences, and backgrounds of all individuals in the workplace. It
            promotes a culture of inclusivity, diversity, and respect.{' '}
          </span>
          <br />
          <Button
            onClick={toggleUnderstandVisibilityw}
            variant='text'
            style={{ color: 'black' }}
          >
            {isUnderstandVisiblew ? 'View less details' : 'View more details'}
          </Button>
          {isUnderstandVisiblew && (
            <div>
              <p>
                <strong>Let's delve deeper into it's various facets: </strong>
                <br />
                Embracing Diversity and Inclusivity: Understanding the value of
                diverse perspectives starts with embracing diversity and
                creating an inclusive work environment. It involves actively
                seeking out and appreciating differences in backgrounds,
                experiences, and thoughts, and leveraging them as assets for the
                organization.
                <br />
                <br />
                Listening and Appreciating Different Perspectives: Understanding
                entails actively listening to and respecting the viewpoints of
                others. It means creating space for open dialogue, healthy
                debates, and constructive discussions where diverse perspectives
                can be shared, understood, and integrated.
                <br />
                <br />
                Enhancing Decision-Making through Diversity: Recognizing the
                value of diverse perspectives means leveraging them to make
                better decisions. It involves understanding that diverse teams
                can bring a broader range of insights and approaches to
                problem-solving, leading to more effective and well-rounded
                outcomes.
                <br />
                <br />
                Appreciating differences: Understanding diverse perspectives
                requires cultivating empathy. It involves developing an
                appreciation for the experiences and challenges faced by
                individuals from different backgrounds and actively seeking to
                understand and bridge any gaps in understanding.
                <br />
                <br />
                Promoting Collaboration and Teamwork: Understanding the value of
                diverse perspectives also means fostering collaboration and
                teamwork. It involves creating opportunities for
                cross-functional collaboration, encouraging diverse teams to
                work together, and recognizing the collective power of diverse
                voices to drive innovation and success.
                <br />
                <br />
                This principle helps foster an inclusive and diverse work
                environment that appreciates the richness and depth that every
                individual brings. It supports a culture where all voices are
                heard and valued, which can ultimately lead to more innovative
                and effective solutions.
                <br />
                <br />
                <strong>Example: </strong> <br />
                You are working on a team project, and you encounter colleagues
                with different work styles. Some are detail-oriented, some are
                idea-oriented, and others are cooperative or prefer a balance
                between solo and teamwork. You take the time to understand these
                different styles and adjust your expectations and communication,
                allowing each team member to contribute effectively according to
                their preferred work style. This fosters a culture of
                understanding and inclusivity, resulting in improved
                collaboration, increased productivity, and innovative solutions
                that leverage the strengths of each team member.
              </p>
            </div>
          )}
        </div>
        <div className='apply-it-content-a'>
          <h4>
            <strong>Laugh: </strong>
          </h4>
          <span>
            The value "laugh" in this context emphasizes the importance of
            creating a positive and enjoyable work atmosphere that embraces
            light-heartedness and celebrates success. It encourages a culture
            where laughter, positivity, and joy are valued and promoted.
          </span>
          <br />
          <Button
            onClick={toggleLaughVisibilityw}
            variant='text'
            style={{ color: 'black' }}
          >
            {isLaughVisiblew ? 'View less details' : 'View more details'}
          </Button>
          {isLaughVisiblew && (
            <div>
              <p>
                <strong>Let's explore its key elements: </strong>
                <br />
                Creating a Positive Atmosphere: The value of "laugh" is about
                establishing a work environment that is characterized by
                positivity, optimism, and enjoyment. It means creating a space
                where employees feel happy and engaged in their work, where they
                can build positive relationships with their colleagues, and
                where their overall well-being is prioritized.
                <br />
                <br />
                Embracing Light-Heartedness: Embracing light-heartedness
                involves encouraging a sense of humor and playfulness in the
                workplace. It means not taking oneself too seriously and being
                open to laughter, jokes, and enjoyable moments.
                Light-heartedness can help alleviate stress, create a more
                relaxed atmosphere, and improve interpersonal relationships.
                <br />
                <br />
                Celebrating Success: Recognizing and celebrating success is an
                essential aspect of the value "laugh." It involves acknowledging
                and appreciating achievements, milestones, and positive
                outcomes. By celebrating success, organizations can boost
                morale, motivation, and a sense of accomplishment among
                employees. This, in turn, contributes to a positive and
                uplifting work environment.
                <br />
                <br />
                Fostering Well-Being and Purpose: Embracing laughter and
                positivity in the workplace contributes to the overall
                well-being and sense of purpose among employees. When people
                enjoy their work and have positive interactions with their
                colleagues, it can enhance job satisfaction and engagement. A
                sense of purpose is also nurtured when employees feel a genuine
                connection to the organization's mission and goals, and when
                they experience joy and fulfillment in their work.
                <br />
                <br />
                Inspiration and Creativity: Creating a work environment that
                embraces joy and laughter can serve as a source of inspiration
                and fuel creativity. When people are in a positive state of
                mind, they are more likely to generate innovative ideas, think
                outside the box, and approach challenges with enthusiasm.
                Laughter and positivity can break down barriers, stimulate
                collaboration, and enhance the overall creative atmosphere
                within the organization.
                <br />
                <br />
                This principle promotes a work culture that prioritizes
                positivity, recognizing that happiness and a positive mindset
                can significantly impact well-being, productivity, and overall
                satisfaction. By celebrating success, incorporating humor and
                lightheartedness, and providing support for employee well-being,
                people feel happy and fulfilled, and are motivated to continue
                striving for excellence.
                <br />
                <br />
                <strong>Example: </strong> <br />
                After a significant product launch ends in a less than great
                outcome, morale among the team was low. Recognizing the gloomy
                atmosphere, you decide to lighten the mood during the
                post-launch meeting. You talk about some of your past failures,
                in a humorous way, reminding everyone that failure is the first
                step towards innovation and success, and praised the team's
                effort and dedication. The laughter and positive atmosphere
                shifts the focus from failure to learning and improvement,
                motivating the team to approach the next project with renewed
                energy and optimism.
              </p>
            </div>
          )}
        </div>
        <div className='apply-it-content-a'>
          <h4>
            <strong>Learn:</strong>
          </h4>
          <span>
            The value of "learn" in this context emphasizes embracing agile
            methodologies and flexible work practices to enhance productivity
            and adaptability. It nurtures a culture of collaboration,
            cross-functional teamwork, and effective communication to respond
            swiftly to changes and opportunities.
          </span>
          <br />
          <Button
            onClick={toggleLearnVisibilityw}
            variant='text'
            style={{ color: 'black' }}
          >
            {isLearnVisiblew ? 'View less details' : 'View more details'}
          </Button>
          {isLearnVisiblew && (
            <div>
              <p>
                <strong>Here are its key elements:</strong> <br />
                Agile Learning and Adaptability: This concept emphasizes a fast,
                flexible, and collaborative approach to learning, where
                employees are encouraged to adapt and respond quickly to changes
                together. Agile learning promotes continuous improvement,
                encouraging resilience in the face of changing circumstances. By
                being open to absorb new information, apply it, reflect on the
                outcome, and then modify actions based on feedback, teams can
                enhance their productivity and problem-solving abilities.
                <br />
                <br />
                Flexible Work Practices: Emphasizing flexible work practices
                such as remote work, flexible hours, and adaptable roles can
                enhance productivity and job satisfaction. This flexibility
                allows employees to balance their work and personal commitments,
                leading to increased motivation and retention, and creating an
                environment promoting learning and growth.
                <br />
                <br />
                Collaborative Learning: This involves learning from and with
                others, promoting knowledge exchange and shared problem-solving.
                It can involve sharing insights, skills, and experiences,
                working together to solve problems or complete tasks. This
                approach strengthens team bonds, allows for diverse perspectives
                to flourish, and promotes a deeper understanding of the task at
                hand.
                <br />
                <br />
                Cross-functional Teamwork: Encouraging cross-functional teamwork
                supports learning as diverse teams combine their unique skills
                and knowledge. Working with colleagues from different
                departments or specialities can lead to more innovative
                solutions and provides a broader understanding of the
                organization's context. This also allows for the development of
                versatile skill sets and promotes a culture of shared learning
                and growth.
                <br />
                <br />
                Effective Communication: This is fundamental for learning. Clear
                expression of ideas, active listening, and engagement in
                constructive feedback can promote mutual understanding and
                alignment within the team. Encouraging open and transparent
                communication fosters an environment where ideas and insights
                can be shared freely, fostering learning and innovation.
                <br />
                <br />
                By promoting agile learning, adaptability, and flexible work
                practices, the "learn" principle fosters a culture where
                employees continuously develop their skills and knowledge,
                better equipping them to manage change and solve problems
                collaboratively. Embracing agile methodologies and flexible work
                practices, promotes work-life balance and empowers employees to
                take ownership of their work and adapt their routines to
                maximize productivity.
                <br />
                <br />
                <strong>Example: </strong> <br />
                You are part of a project team that has just completed a
                significant milestone. Instead of immediately moving on to the
                next task, you suggest having a "retrospective", where everyone
                can share what they learned during this phase of the project and
                how the team can improve for the next steps. You not only share
                your own insights but also actively listen to others,
                strengthening the bonds between team members and boosting the
                overall effectiveness of the team.
              </p>
            </div>
          )}
        </div>
        <div className='apply-it-content-a'>
          <h4>
            <strong>Ask Why:</strong>
          </h4>
          <span>
            The value "Ask Why" promotes a culture of curiosity and critical
            thinking by encouraging individuals to question the status quo and
            explore innovative solutions. This value underpins the process of
            stimulating innovation and driving continuous growth.
          </span>
          <br />
          <Button
            onClick={toggleAskWhyVisibilityw}
            variant='text'
            style={{ color: 'black' }}
          >
            {isAskWhyVisiblew ? 'View less details' : 'View more details'}
          </Button>
          {isAskWhyVisiblew && (
            <div>
              <p>
                <strong>Key elements of "Ask Why" include:</strong>
                <br />
                Cultivating Curiosity: Fostering a culture of curiosity is about
                encouraging a deep-seated desire to learn and understand. In a
                curious culture, individuals feel motivated to seek knowledge,
                ask questions, and delve deeper into issues that intrigue them.
                This constant quest for understanding becomes a driver for
                innovation, fuels continuous learning, and enables individuals
                to gain a broader perspective of their work.
                <br />
                <br />
                Encouraging Critical Thinking: In this context, encouraging
                critical thinking is about fostering a mindset that questions
                assumptions, evaluates evidence, and assesses conclusions
                critically. This approach enables individuals to identify
                underlying problems, make well-informed decisions, and develop
                robust, well-considered solutions. Critical thinking not only
                strengthens problem-solving skills but also sparks creativity,
                paving the way for innovation and improvement.
                <br />
                <br />
                Questioning the Status Quo: The ability to question established
                practices and norms is a key aspect of "Ask Why". This value
                encourages individuals to scrutinize current processes,
                recognize inefficiencies, and bring forward innovative solutions
                that push boundaries. Encouraging this level of questioning
                stimulates continuous improvement and growth, fostering a
                dynamic and progressive work environment.
                <br />
                <br />
                Exploring Innovative Solutions: The "Ask Why" value stimulates
                creativity and exploration of fresh, unique solutions. When
                people are free to question and think critically, they're more
                likely to devise original, outside-the-box solutions.
                Encouraging this exploration not only fosters a culture of
                innovation, but also ensures the organization stays ahead in a
                competitive market by continuously adapting and evolving.
                <br />
                <br />
                Driving Continuous Growth: The principle of "Ask Why" supports
                the idea of continuous growth and development. By constantly
                questioning, learning, and innovating, individuals and teams can
                consistently improve and grow.
                <br />
                <br />
                This mindset, rooted in the curiosity to "Ask Why", fuels a
                cycle of progress and positive change, contributing to
                continuous growth and long-term success.
                <br />
                <br />
                <strong>Example: </strong>
                <br />
                You are part of a team that is working on a long-standing
                project, and you noticed that some processes appear to be
                repetitive or ineffective. Instead of accepting that this is
                "just the way it is," you decide to question the rationale
                behind these. You initiate a conversation with the team, that
                leads to the re-evaluation of existing procedures and ultimately
                the implementation of a more innovative and streamlined
                approach. Your curiosity and willingness to challenge the status
                quo drive continuous growth and foster a culture of learning
                within your team.
              </p>
            </div>
          )}
        </div>
        <br />
        <br />
        <div className='apply-it-content-b-title'>
          <h3>
            <strong>Pillar 2: Respectfully</strong>
          </h3>
          <h3>
            Support collaborative interactions, embrace continuous learning and
            appreciate different perspectives and diverse views.
          </h3>
          <br />
          <p>
            <strong>Here’s how to apply it:</strong>
          </p>
        </div>
        <div className='apply-it-content-b'>
          <h4>
            <strong>Be Fair:</strong>
          </h4>
          <span>
            The value "Be Fair" in the Respectfully context highlights the need
            for treating everyone with dignity and respect. It encompasses
            creating an environment of equity and inclusivity, where all
            individuals, regardless of their background or role, feel valued and
            acknowledged.
          </span>
          <br />
          <Button
            onClick={toggleFairVisibilityr}
            variant='text'
            style={{ color: 'black' }}
          >
            {isFairVisibler ? 'View less details' : 'View more details'}
          </Button>
          {isFairVisibler && (
            <div>
              <p>
                <strong>Let's break it down:</strong>
                <br />
                Treating with Respect and Dignity: Treating individuals with
                respect and dignity is fundamental to being fair. It's about
                acknowledging the inherent worth of every person, irrespective
                of their position, background, or ideas. It involves
                consistently demonstrating respect in all interactions,
                affirming that every person is valued for who they are and what
                they bring to the team.
                <br />
                <br />
                Creating an Inclusive Environment: Fairness is also about
                fostering an inclusive environment where everyone feels a sense
                of belonging. This involves not just tolerating, but genuinely
                embracing, diversity in all its forms - be it cultural,
                experiential, or cognitive. It means making a conscious effort
                to ensure that everyone feels comfortable expressing their
                authentic selves at work.
                <br />
                <br />
                Ensuring Everyone is Heard: An essential component of fairness
                is ensuring that all voices are heard. It's about creating
                channels for open communication, encouraging everyone to share
                their thoughts and ideas, and actively listening when they do.
                It's about promoting a culture where everyone's input is sought,
                considered, and valued.
                <br />
                <br />
                Valuing Individual Contributions: Fairness involves recognizing
                and appreciating each individual's efforts and contributions. It
                means giving credit where it's due, and affirming the unique
                value that each person adds to the team. It’s about fostering a
                culture where every team member feels their work is meaningful
                and appreciated.
                <br />
                <br />
                Promoting Equality in Opportunities: Fairness also involves
                providing equal opportunities for growth and advancement to all
                employees. It's about ensuring that assignments, promotions, and
                rewards are based on merit, not favouritism or bias. This helps
                cultivate a sense of fairness and motivation, enhancing
                commitment and job satisfaction.
                <br />
                <br />
                In essence, the principle of "Be Fair" in the Respectfully
                context emphasizes a culture of respect, inclusivity, and
                equality. It's about building a supportive workplace where
                everyone feels valued and acknowledged, ensuring equal
                opportunities, and fostering an environment where all voices are
                heard, and individual contributions are appreciated.
                <br />
                <br />
                <strong>Example: </strong>
                <br />
                Suppose you're leading a team meeting where several members have
                shared their ideas for an upcoming project. However, you notice
                that one of your colleagues, Mark, hasn't spoken up yet. Rather
                than proceeding, you turn to Mark and encourage him to share his
                thoughts, ensuring his voice is heard. You thank him for his
                unique perspective, which sparks a new direction in the team's
                discussion. Later, you highlight Mark’s contribution in a
                company-wide meeting, demonstrating your commitment to
                recognizing and appreciating individual inputs and fostering a
                culture of fairness.
              </p>
            </div>
          )}
        </div>
        <div className='apply-it-content-b'>
          <h4>
            <strong>Understand:</strong>
          </h4>
          <span>
            The value "Understand" in this context advocates for nurturing
            empathy, and open communication within the workspace, enhancing the
            bonds of respect among the team members.
          </span>
          <br />
          <Button
            onClick={toggleUnderstandVisibilityr}
            variant='text'
            style={{ color: 'black' }}
          >
            {isUnderstandVisibler ? 'View less details' : 'View more details'}
          </Button>
          {isUnderstandVisibler && (
            <div>
              <p>
                <strong>Let's delve deeper into this:</strong>
                <br />
                Develop Empathy: Encouraging understanding involves developing
                empathy as a core value within the workplace. This means
                actively striving to comprehend the feelings, thoughts, and
                experiences of others. It's about acknowledging the diverse
                perspectives present in the team and fostering an environment
                where empathy is expressed and appreciated.
                <br />
                <br />
                Understanding Different Views: Understanding entails an
                open-minded approach to diversity and inclusivity. It means
                appreciating the unique experiences and insights that every team
                member brings to the table and recognizing that these diverse
                perspectives contribute to a more vibrant and innovative
                workspace.
                <br />
                <br />
                Empathizing in Interactions: "Understanding" in interactions
                goes beyond merely hearing others - it requires truly
                empathizing with them. This involves imagining oneself in
                another's situation, considering their feelings, and responding
                with kindness and respect.
                <br />
                <br />
                Creating Opportunities for Communication: Promoting
                understanding also requires facilitating meaningful
                communication. This involves creating platforms and
                opportunities for individuals to express their views, share
                their experiences, and engage in thoughtful discussions. It's
                about fostering a dialogue that respects and values everyone's
                inputs.
                <br />
                <br />
                Fostering Compassion: Understanding is closely tied to
                compassion. It's about acknowledging the challenges and
                struggles others may be facing, offering support and assistance,
                and contributing to a caring and compassionate work environment.
                <br />
                <br />
                In essence, this principle underlines the significance of
                empathy and understanding in respectful interactions. It's about
                listening actively, empathizing genuinely, communicating openly,
                and displaying compassion consistently. By promoting
                understanding, we cultivate a workplace where respect and
                dignity are the norm, not the exception.
                <br />
                <br />
                <strong>Example: </strong>
                <br />
                Your team is working on a tight deadline project. One of the
                members, Robert, seems to be struggling and not meeting his
                deadlines. Instead of jumping to conclusions or feeling
                frustrated, you decide to have a one-on-one with Robert. You
                listen empathetically to his challenges, showing genuine concern
                and understanding. This helps you understand his perspective and
                work together on a plan to improve his performance. Your
                approach creates a supportive atmosphere that respects and
                values individual experiences, fostering better communication
                and team cohesion.{' '}
              </p>
            </div>
          )}
        </div>
        <div className='apply-it-content-b'>
          <h4>
            <strong>Laugh:</strong>
          </h4>
          <span>
            The "Laugh" value in this context highlights the role of positivity,
            humor, and a friendly atmosphere in cultivating a respectful work
            environment.
          </span>
          <br />
          <Button
            onClick={toggleLaughVisibilityr}
            variant='text'
            style={{ color: 'black' }}
          >
            {isLaughVisibler ? 'View less details' : 'View more details'}
          </Button>
          {isLaughVisibler && (
            <div>
              <p>
                <strong>Here are its key components:</strong>
                <br />
                Nurture Positivity: Fostering a positive work atmosphere is
                about more than just ensuring that the work gets done. It's
                about creating a space that nurtures positivity, where humor,
                laughter, and happiness are celebrated. It's about using humor
                as a tool to build bridges, connect with others, and spread joy,
                thus fostering a sense of belonging.
                <br />
                <br />
                Use Humor to Reduce Tension: Laughter is an excellent tension
                diffuser. In stressful situations, or during challenging times,
                a bit of humor can help lighten the mood, reduce anxiety, and
                restore balance. In this context, "Laugh" is about recognizing
                the power of humor to transform workspaces, relieve tension, and
                foster a more relaxed and enjoyable atmosphere.
                <br />
                <br />
                Build Meaningful Connections: Laughter and humor have a unique
                power to bring people together, foster common ground, and build
                meaningful connections. Promoting a culture where humor is
                appreciated and shared helps strengthen bonds among team
                members, improve communication, and enhance mutual
                understanding.
                <br />
                <br />
                Create a Sense of Belonging: In a respectful work environment,
                creating a sense of belonging is crucial. "Laugh" encapsulates
                this aspect by promoting an inclusive atmosphere where everyone
                feels valued, appreciated, and part of the team. Shared laughter
                and humor can play a vital role in nurturing this feeling of
                community and togetherness.
                <br />
                <br />
                Supporting an Inclusive Environment: The "Laugh" value is also
                about supporting an inclusive environment, where everyone,
                regardless of their backgrounds or perspectives, feels welcome.
                It's about acknowledging and respecting diversity, and ensuring
                that humor and laughter are used appropriately, with sensitivity
                and respect for all members of the organization.
                <br />
                <br />
                In essence, "Laugh" underlines the role of positivity and humor
                in fostering a respectful and enjoyable work atmosphere. It's
                about using humor as a catalyst for connection, a tool for
                tension reduction, and a medium for building an inclusive,
                happy, and engaged workforce.
                <br />
                <br />
                <strong>Example: </strong>
                <br />
                Imagine you are attending a brainstorming session for an
                important project. The stress levels are high as everyone is
                trying to come up with a viable solution. You notice the tension
                and decide to interject with a light-hearted joke related to the
                project, which gets everyone laughing. This brief moment of
                shared laughter reduces the stress in the room, helps people
                feel more connected, and sets the stage for a more relaxed and
                productive discussion.
              </p>
            </div>
          )}
        </div>
        <div className='apply-it-content-b'>
          <h4>
            <strong>Learn:</strong>
          </h4>
          <span>
            The "Learn" value in this context emphasizes the importance of
            continuous learning, growth, and embracing new perspectives in
            cultivating a respectful work environment.
          </span>
          <br />
          <Button
            onClick={toggleLearnVisibilityr}
            variant='text'
            style={{ color: 'black' }}
          >
            {isLearnVisibler ? 'View less details' : 'View more details'}
          </Button>
          {isLearnVisibler && (
            <div>
              <p>
                <strong>Here are its key components:</strong>
                <br />
                Promote a Culture of Continuous Learning: Respectful work
                cultures don't stagnate; they evolve. Promoting a culture of
                continuous learning means creating an environment where gaining
                new knowledge and skills is valued and encouraged. It's about
                recognizing that learning is a lifelong journey, not a
                destination, and fostering an atmosphere where everyone is
                always ready to learn and grow.
                <br />
                <br />
                Embrace New Ideas and Perspectives: Learning isn't just about
                gaining knowledge; it's also about broadening our horizons.
                Embracing new ideas and perspectives involves promoting
                diversity in thought, encouraging people to think critically and
                challenge established notions, and celebrating the rich tapestry
                of unique insights and experiences that each person brings to
                the table.
                <br />
                <br />
                Encourage Open-Mindedness: A critical aspect of learning is
                open-mindedness. Encouraging open-mindedness means fostering a
                culture where people are ready to consider different
                perspectives and ideas, where they don't let preconceived
                notions limit their understanding, and where they see the value
                in learning from a variety of sources.
                <br />
                <br />
                Foster a Willingness to Learn from Others: No one is a
                repository of all knowledge, and everyone has something valuable
                to offer. Fostering a willingness to learn from others means
                nurturing a culture where everyone recognizes this fact and is
                ready to learn from their colleagues, their leaders, and their
                subordinates. It's about understanding that everyone has a
                unique perspective and a unique set of experiences that can
                enrich our own understanding.
                <br />
                <br />
                Cultivate Respectful Learning Interactions: Learning should be a
                respectful process. This means that people feel safe expressing
                their ideas and thoughts, they feel heard and acknowledged when
                they share their knowledge, and they feel valued for their
                contributions to the collective learning of the organization.
                <br />
                <br />
                In essence, "Learn" in this context is about promoting a
                respectful culture of continuous learning. It's about fostering
                an environment that values the acquisition of new knowledge,
                that embraces diverse ideas and perspectives, and that
                recognizes the inherent value in learning from others.
                <br />
                <br />
                <strong>Example: </strong>
                <br />
                Picture yourself in a team meeting where you are discussing a
                project hiccup. One of the junior team members, fresh out of
                college, suggests a solution that's different from your standard
                operating procedures. Instead of dismissing the idea due to
                their inexperience, you embrace it, explore it, and eventually,
                it turns out to be an effective solution. This open-mindedness
                not only solves the project issue but also creates a respectful
                atmosphere where everyone's ideas are valued.
              </p>
            </div>
          )}
        </div>
        <div className='apply-it-content-b'>
          <h4>
            <strong>Ask Why:</strong>
          </h4>
          <span>
            The "Ask Why" value in this context centers around promoting
            critical thinking, challenging assumptions, and fostering curiosity
            in a respectful work environment. This pursuit of understanding not
            only bolsters informed decision-making but also drives meaningful
            and positive change.
          </span>
          <br />
          <Button
            onClick={toggleAskWhyVisibilityr}
            variant='text'
            style={{ color: 'black' }}
          >
            {isAskWhyVisibler ? 'View less details' : 'View more details'}
          </Button>
          {isAskWhyVisibler && (
            <div>
              <p>
                <strong>Here are its key elements: </strong>
                <br />
                Asking Questions: Encouraging a culture of asking questions is
                essential in fostering a respectful work environment. It
                involves promoting an atmosphere where curiosity is valued,
                where people feel comfortable seeking clarification or deeper
                understanding, and where the pursuit of knowledge is seen as a
                strength. It's about breaking away from the notion that asking
                questions signifies weakness and instead recognizing it as an
                integral part of learning and growth.
                <br />
                <br />
                Challenging Assumptions: To respect different perspectives, it's
                essential to challenge our assumptions. This involves
                encouraging a mindset where assumptions, preconceived notions,
                and biases are not taken for granted but are questioned and
                tested. It's about promoting a culture that values evidence over
                assumption, and truth over convenience.
                <br />
                <br />
                Considering the Status Quo: Respecting different perspectives
                also involves considering why things are the way they are. This
                isn't about accepting the status quo blindly but about
                understanding the underlying reasons and dynamics. It's about
                promoting a culture that values analysis and introspection, and
                that understands the importance of context in decision-making.
                <br />
                <br />
                Making Better-Informed Decisions: Respectful work cultures value
                informed decision-making. By asking questions, challenging
                assumptions, and considering the status quo, we can make
                decisions that are not only informed but also respectful of the
                different perspectives and realities that people bring to the
                table. It's about recognizing that informed decisions are
                invariably better decisions.
                <br />
                <br />
                Encouraging Others to Ask Why: Asking why isn't a solo activity;
                it's a culture. Encouraging others to ask why means fostering an
                environment where everyone feels comfortable seeking clarity,
                where curiosity is collectively valued, and where the pursuit of
                understanding is a shared endeavour.
                <br />
                <br />
                In essence, the principle of "Ask Why" in this context promotes
                a respectful work culture that values curiosity, challenges
                assumptions, and values informed decision-making.
                <br />
                <br />
                <strong>Example: </strong>
                <br />
                Imagine being part of a team that's working on an important
                project. Midway, you notice that the project seems to be veering
                off course. Instead of blindly pushing through, you decide to
                ask why. You challenge the current plan, question assumptions,
                and open up a dialogue for the team to reassess. This behavior
                not only helps correct the course of the project but also
                fosters a culture of respect, where everyone's perspectives are
                heard and valued.{' '}
              </p>
            </div>
          )}
        </div>
        <br />
        <br />
        <div className='apply-it-content-c-title'>
          <h3>
            <strong>Pillar 3: Kindfully</strong>
            <br />
            Practice compassion and empathy, strive for continuous improvement,
            and build stronger relationships.
          </h3>
          <br />
          <p>
            <strong>Here’s how to apply it:</strong>
          </p>
        </div>
        <div className='apply-it-content-c'>
          <h4>
            <strong>Be Fair:</strong>
          </h4>
          <span>
            "Be Fair" in the context of Kindfully means cultivating an
            environment where everyone feels welcome, and a part of the team,
            fostering fairness as a core foundation for building strong,
            trusting, and positive relationships.
          </span>
          <br />
          <Button
            onClick={toggleFairVisibilityk}
            variant='text'
            style={{ color: 'black' }}
          >
            {isFairVisiblek ? 'View less details' : 'View more details'}
          </Button>
          {isFairVisiblek && (
            <div>
              <p>
                <strong>Let's delve deeper into this principle:</strong>
                <br />
                Embracing Everyone: Establishing fairness begins with embracing
                everyone as an integral part of the team. It's about creating an
                environment where everyone feels welcome, valued, and important.
                It encourages the recognition of each individual's unique
                contribution and ensures that everyone feels connected and vital
                to the team's success.
                <br />
                <br /> Eliminating Bias: Fairness requires eliminating any form
                of bias or prejudice. It's about making conscious efforts to
                avoid bias in decision-making processes, ensuring that actions
                and decisions are based on merit and not influenced by personal
                feelings or preconceptions.
                <br />
                <br />
                Building Positive Relationships: Fairness lays the groundwork
                for building positive relationships. It's about cultivating an
                atmosphere of trust, where individuals feel comfortable and
                secure. This sense of trust, built on fairness, encourages open
                communication, mutual respect, and cooperation.
                <br />
                <br />
                Promoting Equality: Fairness promotes a culture of equality
                where everyone is treated with equal respect and dignity. It
                involves creating an environment where opportunities, resources,
                and benefits are distributed equitably, ensuring everyone has
                equal chances to grow and succeed.
                <br />
                <br />
                Encouraging Trust: Trust, an important outcome of fairness, is
                essential in maintaining strong relationships. When people feel
                they are treated fairly, trust is strengthened, boosting morale,
                productivity, and fostering a positive, kind work environment.
                <br />
                <br />
                In essence, the principle of "Be Fair" in the context of
                Kindfully promotes an atmosphere of equality, inclusivity, and
                trust. It's about treating all individuals equally, free from
                bias, and creating a welcoming environment that builds strong,
                positive relationships.
                <br />
                <br />
                <strong>Example: </strong>
                <br />
                Imagine being a team leader with diverse members, each with
                unique skills and experiences. You recognize the importance of
                fairness and ensure every member feels valued and heard. You
                take extra care to eliminate any biases in assigning tasks,
                distributing resources, and in making important decisions. You
                treat everyone equally and promote a culture of trust. Your
                actions not only foster a sense of fairness in the team but also
                build strong, positive, and trusting relationships among the
                team members.
              </p>
            </div>
          )}
        </div>
        <div className='apply-it-content-c'>
          <h4>
            <strong>Understand:</strong>
          </h4>
          <span>
            "Understand" in the context of Kindfully refers to cultivating
            empathy by seeking to comprehend others' emotions, perspectives, and
            experiences. It's about fostering a culture where active listening
            is encouraged, and people feel genuinely heard, acknowledged, and
            supported.
          </span>
          <br />
          <Button
            onClick={toggleUnderstandVisibilityk}
            variant='text'
            style={{ color: 'black' }}
          >
            {isUnderstandVisiblek ? 'View less details' : 'View more details'}
          </Button>
          {isUnderstandVisiblek && (
            <div>
              <p>
                <strong>Let's break this down further:</strong>
                <br />
                Cultivating Empathy: Understanding involves cultivating empathy
                - a conscious effort to comprehend and share the feelings and
                perspectives of others. This action fosters stronger
                connections, promotes effective communication, and creates an
                environment where everyone feels seen, understood, and valued.
                <br />
                <br />
                Actively Listening: Active listening is a key component of
                understanding. It entails giving your complete attention to
                others, asking insightful questions, and acknowledging their
                thoughts and feelings. This approach makes individuals feel
                genuinely heard, supported, and appreciated.
                <br />
                <br />
                Encouraging Emotional Awareness: Understanding encourages
                emotional awareness, a crucial aspect of empathy. This involves
                being mindful of one's own emotions and the emotions of others.
                It fosters emotional intelligence, facilitating better
                communication and healthier relationships.
                <br />
                <br />
                Understanding Different Perspectives: Understanding different
                perspectives is a key aspect of empathy. It requires viewing
                situations from others' viewpoints, considering their thoughts,
                feelings, and experiences. This openness to diverse perspectives
                promotes inclusivity, fosters respect, and strengthens
                relationships.
                <br />
                <br />
                Showing Genuine Care and Support: Understanding entails showing
                genuine care and support to others. It's about being there for
                others during challenging times, offering support and
                encouragement. This builds trust, strengthens bonds, and
                contributes to a happier work environment.
                <br />
                <br />
                In essence, the principle of "Understand" within the context of
                Kindfully emphasizes fostering a culture of empathy, active
                listening, emotional awareness, and genuine care and support.
                It's about creating an environment that encourages trust,
                understanding, and authentic connections.
                <br />
                <br />
                <strong>Example: </strong>
                <br /> Imagine a coworker struggling with a challenging project.
                Instead of offering immediate advice, you take time to truly
                understand their situation. You listen attentively to their
                concerns, try to perceive the project from their viewpoint, and
                empathize with their feelings of stress. Your understanding
                approach makes them feel genuinely heard and supported, building
                a stronger, more empathetic connection between you both.
              </p>
            </div>
          )}
        </div>
        <div className='apply-it-content-c'>
          <h4>
            <strong>Laugh:</strong>
          </h4>
          <span>
            "Laugh" in the context of Kindfully refers to encouraging a
            workplace where laughter and light-heartedness are embraced,
            creating a joyful atmosphere. It's about using humor as a tool to
            build trust, promote open-mindedness, and forge deeper connections
            with others.
          </span>
          <br />
          <Button
            onClick={toggleLaughVisibilityk}
            variant='text'
            style={{ color: 'black' }}
          >
            {isLaughVisiblek ? 'View less details' : 'View more details'}
          </Button>
          {isLaughVisiblek && (
            <div>
              <p>
                <strong>Let's delve deeper into this:</strong>
                <br />
                Fostering a Light-Hearted Atmosphere: Laughing in a kind manner
                involves promoting a light-hearted and joyful atmosphere in the
                workplace. This environment fosters positivity, reduces stress,
                and increases overall job satisfaction. A workplace that laughs
                together tends to foster more harmonious relationships and
                increased cooperation among its members.
                <br />
                <br />
                Building Trust Through Humor: Humor, when used appropriately,
                can act as a bridge, fostering connections and building trust
                among team members. Laughing together can break down barriers,
                allowing people to feel more comfortable and open with each
                other, which in turn fosters more honest and productive
                communication.
                <br />
                <br />
                Encouraging Open-Mindedness: A workplace that encourages
                laughter is often one that nurtures open-mindedness. A sense of
                humor can help us to see things from a different perspective,
                encourage creative thinking, and approach problems with a fresh
                mindset.
                <br />
                <br />
                Facilitating Meaningful Interactions: Shared laughter leads to
                more meaningful interactions. When we laugh with others, it
                creates a sense of camaraderie and mutual understanding,
                strengthening our connections with them and contributing to a
                more unified team.
                <br />
                <br />
                Using Humor to Diffuse Tension: Humor can be a powerful tool to
                diffuse tension and overcome conflict. A well-timed joke or
                shared laugh can ease a tense situation, allowing for better
                resolution and understanding.
                <br />
                <br />
                In essence, "Laugh" within the context of Kindfully is about
                fostering a positive, light-hearted atmosphere that facilitates
                trust, encourages open-mindedness, and nurtures meaningful
                interactions. It's about utilizing humor as a tool to diffuse
                tension, build connections, and promote a more joyful, inclusive
                work environment.
                <br />
                <br />
                <strong>Example: </strong> <br />
                Picture a team meeting where a disagreement has created a tense
                atmosphere. To lighten the mood, you tactfully share a
                light-hearted anecdote related to the situation. This brings a
                round of laughter, easing the tension and opening the floor for
                more constructive discussion. Your use of humor helped to
                diffuse a potentially difficult situation, promoting a kinder,
                more open-minded dialogue.
              </p>
            </div>
          )}
        </div>
        <div className='apply-it-content-c'>
          <h4>
            <strong>Learn:</strong>
          </h4>
          <span>
            "Learn" in the context of Kindfully refers to embracing a mindset of
            continuous improvement, encouraging individuals to learn from each
            other's experiences, and fostering collaboration and mutual support.
            It's about creating an environment where shared knowledge and
            collective wisdom drive growth.
          </span>
          <br />
          <Button
            onClick={toggleLearnVisibilityk}
            variant='text'
            style={{ color: 'black' }}
          >
            {isLearnVisiblek ? 'View less details' : 'View more details'}
          </Button>
          {isLearnVisiblek && (
            <div>
              <p>
                <strong>Let's delve deeper into this:</strong>
                <br />
                Promoting Continuous Improvement: The kind approach to learning
                involves fostering an atmosphere of ongoing improvement, where
                each person is encouraged to continuously develop their skills
                and capabilities. In this environment, challenges are seen as
                opportunities to grow and mistakes are treated as stepping
                stones towards success.
                <br />
                <br />
                Encouraging Shared Learning: Kind learning requires encouraging
                the sharing of experiences and insights among team members. It's
                about valuing the collective wisdom and diverse perspectives
                that come from open communication and mutual sharing, enabling
                everyone to learn and grow together.
                <br />
                <br />
                Fostering Collaboration and Mutual Support: A kind learning
                environment fosters collaboration and mutual support among team
                members. It encourages individuals to help each other, share
                knowledge, and work together towards common goals, fostering a
                sense of camaraderie and collective achievement.
                <br />
                <br />
                Promoting Empathy in Learning: In a kind learning environment,
                empathy plays a crucial role. Understanding and respecting each
                other's learning styles and paces, and offering support when
                needed, creates a nurturing and inclusive atmosphere conducive
                to learning.
                <br />
                <br />
                Valuing Diverse Perspectives: In the spirit of kindness,
                learning involves appreciating and valuing diverse perspectives.
                It's about understanding that everyone has unique experiences
                and insights to offer, and that these differences can greatly
                enrich collective learning and problem-solving.
                <br />
                <br />
                In essence, "Learn" within the context of Kindfully is about
                fostering a culture of continuous improvement driven by shared
                knowledge, collaboration, empathy, and respect for diverse
                perspectives. It's about creating an inclusive, supportive
                learning environment where everyone is encouraged to grow and
                improve, and where collective wisdom is valued.
                <br />
                <br />
                <strong>Example: </strong>
                <br />
                Imagine being part of a project where your team encounters an
                unexpected challenge. Instead of pointing fingers, you initiate
                a learning discussion where each member can share their
                perspective on what went wrong and how to prevent similar issues
                in the future. Through this collaborative dialogue, not only is
                the problem solved, but everyone learns valuable lessons for
                future projects. This is the essence of kind learning.
              </p>
            </div>
          )}
        </div>
        <div className='apply-it-content-c'>
          <h4>
            <strong>Ask Why:</strong>
          </h4>
          <span>
            "Ask Why" in the context of Kindfully emphasizes fostering a culture
            of curiosity, promoting open dialogue, and encouraging individuals
            to question the status quo and seek a deeper understanding. It's
            about harnessing curiosity and critical thinking to enable positive
            change, all while respecting and appreciating each other's
            perspectives.
          </span>
          <br />
          <Button
            onClick={toggleAskWhyVisibilityk}
            variant='text'
            style={{ color: 'black' }}
          >
            {isAskWhyVisiblek ? 'View less details' : 'View more details'}
          </Button>
          {isAskWhyVisiblek && (
            <div>
              <p>
                <strong>Let's examine this in detail:</strong>
                <br />
                Fostering a Culture of Curiosity: In a kind environment,
                curiosity is nurtured and celebrated. Everyone is encouraged to
                ask questions, seek knowledge, and explore new possibilities.
                This curiosity-driven culture not only promotes continuous
                learning but also enables the discovery of innovative solutions
                and ideas.
                <br />
                <br />
                Promoting Open Dialogue: Kindly asking "Why" involves
                facilitating open dialogue. It's about fostering a safe space
                where everyone feels comfortable expressing their views, asking
                questions, and sharing their insights. This promotes deeper
                understanding and enables the co-creation of knowledge and
                solutions.
                <br />
                <br />
                Encouraging Critical Thinking: A kind approach to asking "Why"
                involves encouraging critical thinking. It's about empowering
                individuals to question the status quo, examine assumptions, and
                make well-informed decisions. This attitude can drive positive
                change and improve outcomes.
                <br />
                <br />
                Appreciating Perspectives: When asking "Why", it's important to
                be kind and appreciate others' perspectives. Understanding that
                everyone has unique insights to offer promotes inclusivity and
                enriches the collective wisdom of the group.
                <br />
                <br />
                Enabling Positive Change: Asking "Why" is about enabling
                positive change. By fostering curiosity, open dialogue, and
                critical thinking, we can uncover areas for improvement, drive
                innovation, and enable continuous growth and development.
                <br />
                <br />
                In essence, "Ask Why" in the Kindfully context is about
                promoting curiosity, facilitating open dialogue, encouraging
                critical thinking, and enabling positive change, all in a kind
                and appreciative manner.
                <br />
                <br />
                <strong>Example: </strong>
                <br />
                Imagine you're in a meeting where a longstanding company
                procedure is being discussed. Instead of accepting it as a
                given, you kindly ask, "Why do we do it this way?" This question
                encourages dialogue, prompts critical thinking, and opens the
                floor to potentially better alternatives. By asking "Why"
                kindly, you stimulate constructive conversation and contribute
                to positive change.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ImageRightTextLeftBottom;
