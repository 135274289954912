const questions = [
  // wrkfully
  {
    name: 'I believe in providing equal opportunities for all, regardless of their background.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['workfully'],
    principle: ['fair'],
  },
  {
    name: 'I make sure to fully understand the objectives of the projects or tasks I am involved in.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['workfully'],
    principle: ['understand'],
  },
  {
    name: 'When facing difficult situations, I often find a reason to smile or laugh.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['workfully'],
    principle: ['laugh'],
  },
  {
    name: 'I actively seek input from colleagues with different backgrounds or experiences.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['workfully'],
    principle: ['learn'],
  },
  {
    name: 'I enjoy discussions that challenge common perceptions or widely accepted practices.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['workfully'],
    principle: ['ask why'],
  },
  // Respectfully
  {
    name: 'When making decisions, I consider the impact on all parties involved.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['respectfully'],
    principle: ['fair'],
  },
  {
    name: 'I value open-mindedness and try to understand viewpoints that are different from my own.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['respectfully'],
    principle: ['understand'],
  },
  {
    name: 'I believe humor contributes to building strong relationships.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['respectfully'],
    principle: ['laugh'],
  },
  {
    name: 'I often find myself reading or researching a topic just for the joy of learning.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['respectfully'],
    principle: ['learn'],
  },
  {
    name: "Asking 'why' is crucial for me to understand new concepts or ideas.",
    left: 'Disagree',
    right: 'Agree',
    criteria: ['respectfully'],
    principle: ['ask why'],
  },
  // kindfully
  {
    name: 'I try to treat everyone fairly, regardless of my personal feelings towards them.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['kindfully'],
    principle: ['fair'],
  },
  {
    name: "I often put myself in others' shoes to better understand their perspective.",
    left: 'Disagree',
    right: 'Agree',
    criteria: ['kindfully'],
    principle: ['understand'],
  },
  {
    name: 'I believe laughter can have a positive impact on overall well-being.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['kindfully'],
    principle: ['laugh'],
  },
  {
    name: 'Learning from my mistakes is an essential part of my personal growth.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['kindfully'],
    principle: ['learn'],
  },
  {
    name: 'I prefer asking the reasons behind decisions made, rather than just accepting them.',
    left: 'Disagree',
    right: 'Agree',
    criteria: ['kindfully'],
    principle: ['ask why'],
  },
];
const principles = Object.fromEntries(
  Array.from(new Set(questions.flatMap(q => q.principle))).map(principle => [
    principle,
    0,
  ])
);

export default questions;
export { principles };
