import React from 'react';
import { withAuthenticator, ThemeProvider } from '@aws-amplify/ui-react';
import MyProfile from './MyProfile';
import { useLoginTheme } from './../LoginTheme.js';
import './MyProfileContainer.css';

const MyProfileContainer = () => {
  const theme = useLoginTheme();

  const MyProfileWithAuth = withAuthenticator(MyProfile);

  return (
    <ThemeProvider theme={theme}>
      <MyProfileWithAuth />
    </ThemeProvider>
  );
};

export default MyProfileContainer;
