import React, { useState, useEffect } from 'react';
import './ContactUs.css';
import Faq from './FAQ';
import { SES } from 'aws-sdk';

function ContactUs() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const emailVerification = 'info@wrkfully.com';
  useEffect(() => {
    let timeout;
    if (statusMessage) {
      timeout = setTimeout(() => {
        setStatusMessage('');
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [statusMessage]);

  const handleSubmit = async event => {
    event.preventDefault();
    console.log(`Name: ${name}\nEmail: ${email}\nMessage: ${message}`);
    try {
      await sendEmailToGrayNews();
      setName('');
      setEmail('');
      setMessage('');
      setStatusMessage('Thank you for your message!');
    } catch (error) {
      console.error('Error occurred while sending email:', error);
      setStatusMessage('Failed to send email. Please try again.');
    }
  };

  const sendEmailToGrayNews = async () => {
    try {
      const ses = new SES({
        region: 'eu-central-1',
        accessKeyId: 'AKIAQT5ADM4N2T36I2V3', // Replace with your AWS access key ID
        secretAccessKey: 'MKAKp7rGfs8gjLEDZAlAQAWfPrYq1ZnVuCUbT0EV', // Replace with your AWS secret access key
      }); // Use the region from awsconfig
      await ses
        .sendEmail({
          Source: emailVerification, // Pass the email string directly
          Destination: {
            ToAddresses: ['graynews.today@gmail.com'], // Replace with the email address where you want to receive the messages
          },
          Message: {
            Subject: {
              Data: 'wrkfully message',
            },
            Body: {
              Text: {
                Data: `Name: ${name}\nEmail: ${email}\nMessage: ${message}`,
              },
            },
          },
        })
        .promise();

      console.log('Email sent successfully');
    } catch (error) {
      console.error('Error occurred while sending email:', error);
      throw error;
    }
  };

  return (
    <div className='contact-us-main'>
      <Faq />
      <div className='contact-container'>
        <div className='contact-form-container'>
          <h2>Contact Us</h2>
          <h3>
            Please reach out with any feedback, suggestions, feature requests,
            collaboration requests, questions, further information. Feedback is
            key for the wrkfully framework to grow and improve.
          </h3>
          <br />
          <form onSubmit={handleSubmit}>
            <label htmlFor='name'>Name:</label>
            <input
              type='text'
              id='name'
              name='name'
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <label htmlFor='email'>Email:</label>
            <input
              type='email'
              id='email'
              name='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />

            <label htmlFor='message'>Message:</label>
            <textarea
              id='message'
              name='message'
              value={message}
              onChange={e => setMessage(e.target.value)}
            ></textarea>

            <div className='status-message-container'>
              {statusMessage && (
                <div
                  className={`status-message ${
                    statusMessage.includes('Failed') ? 'error' : 'success'
                  }`}
                >
                  {statusMessage}
                </div>
              )}
            </div>

            <div className='submit-button-container'>
              <button type='submit' className='submit-button'>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
