import React from 'react';
import './PeerReviewInfo.css';
import HeroFramework from './heroFramework/HeroFramework';

function PeerReviewInfo() {
  return (
    <div className='peer-review-info-container'>
      <HeroFramework />
    </div>
  );
}

export default PeerReviewInfo;
