import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className='privacy-container'>
      <div className='privacy-policy-title'>
        <h1>Privacy Policy</h1>
      </div>
      <br />
      Last Updated: June 9, 2023
      <br />
      <br />
      <b>Introduction:</b>
      <p>
        At wrkfully, we deeply value your privacy and are committed to
        protecting your personal information. We promise never to sell your data
        to anyone, a commitment we've always maintained.
      </p>
      <b>Introduction:</b>
      <p>
        When you use our services, we collect the following types of
        information:
        <br />
        Personal Information: This is information that can be used to identify
        you personally and includes information such as your username and email
        address.
        <br />
        Assessment Information: We collect the answers to any questionnaires or
        assessments you complete.
        <br />
        Results Information: We collect any results from completed assessments
        or questionnaires.
        <br />
        Technical Information: This includes your IP address and any data
        collected through Google ads.
      </p>
      <b>How We Use Your Information:</b>
      <p>
        We use your information in the following ways:
        <br />
        To provide, improve, and personalize our services.
        <br />
        To communicate with you, including responding to your comments or
        questions, and providing customer support.
        <br />
        To monitor and analyze trends, usage, and activities in connection with
        our services.
        <br />
        To comply with legal obligations, resolve disputes, and enforce our
        agreements.
      </p>
      <b>How We Share Your Information:</b>
      <p>
        We do not sell your personal information to third parties. We may share
        your information in the following circumstances: With your consent or at
        your direction. As necessary to comply with applicable law, regulation,
        legal process, or governmental request. To protect the rights, property,
        or personal safety of our company, its users, or the public.
      </p>
      <b>Security:</b>
      <p>
        We use appropriate safeguards that are designed to improve the integrity
        and security of your information. However, no security measures are
        perfect, and we cannot ensure or warrant the security of any information
        you transmit to us.
      </p>
      <b>Your Rights Under GDPR:</b>
      <p>
        If you are a resident of the European Union, you have the following
        rights under the GDPR:
        <br />
        The right to be informed about our data collection and use
        <br />
        The right of access to your data
        <br />
        The right to rectification if your data is inaccurate or incomplete
        <br />
        The right to erasure of your data
        <br />
        The right to restrict processing
        <br />
        The right to data portability
        <br />
        The right to object to processing
        <br />
        Rights in relation to automated decision making and profiling
        <br />
        Right to lodge a complaint with a supervisory authority: If you consider
        that our processing of your personal information infringes the GDPR. You
        may do so in the EU member state of your habitual residence, your place
        of work or the place of the alleged infringement.
        <br />
        <br />
        If we transfer your personal data to countries outside the European
        Economic Area, we will ensure that it is protected and transferred in a
        manner consistent with legal requirements.
      </p>
      <b>Changes to This Privacy Policy:</b>
      <p>
        We may update this Privacy Policy from time to time. When we do, we will
        revise the "last updated" date at the top of the Privacy Policy.
      </p>
      <b>Contact Us:</b>
      <p>
        If you have any questions about these Terms, please contact us at
        info@wrkfully.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
