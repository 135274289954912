export const formFields = {
    signUp: {
      name: {
        order: 1
      },
      email: {
        order:2
      },
      password: {
        order: 3
      },
      confirm_password: {
        order: 4
      }
    },
  };
  