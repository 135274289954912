// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { PeerReview, SelfAssessment, EmailSubscription } = initSchema(schema);

export {
  PeerReview,
  SelfAssessment,
  EmailSubscription
};