import HeroFramework from './components/heroFramework/HeroFramework';
import ImageRightTextLeft from './components/imageRightTextLeft/ImageRightTextLeft';
import ImageRightTextLeftBottom from './components/imageRightTextLeftBottom/ImageRightTextLeft';
import ImageLeftTextRight from './components/imageLeftTextRight/ImageLeftTextRight';
import AccordionFramework from './components/accordionFramework/AccordionFramework';
import { PillarOneContent } from './PillarOneContent.js';
import { PillarTwoContent } from './PillarTwoContent.js';
import { PillarThreeContent } from './PillarThreeContent.js';
import './Framework.css';

function Framework() {
  const pillarOneTitleStyle = { backgroundColor: '#200063', color: 'white' };
  const pillarTwoTitleStyle = { backgroundColor: '#200063', color: 'white' };
  const pillarThreeTitleStyle = { backgroundColor: '#200063', color: 'white' };

  return (
    <div>
      <HeroFramework />
      <div className='accordion-wrk-container'>
        <div className='framework-accordion-framework-container'>
          <div className='framework-tab-info'>
            <div className='framework-accordion-data'>
              {PillarOneContent.map(({ title, links }, index) => (
                <AccordionFramework
                  key={`pillar-one-${index}`}
                  title={title}
                  links={links}
                  style={pillarOneTitleStyle}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='framework-accordion-framework-container'>
          <div className='framework-tab-info'>
            <div className='framework-accordion-data'>
              {PillarTwoContent.map(({ title, links }, index) => (
                <AccordionFramework
                  key={`pillar-two-${index}`}
                  title={title}
                  links={links}
                  style={pillarTwoTitleStyle}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='framework-accordion-framework-container'>
          <div className='framework-tab-info'>
            <div className='framework-accordion-data'>
              {PillarThreeContent.map(({ title, links }, index) => (
                <AccordionFramework
                  key={`pillar-three-${index}`}
                  title={title}
                  links={links}
                  style={pillarThreeTitleStyle}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <ImageRightTextLeft />
      <ImageLeftTextRight />
      <ImageRightTextLeftBottom />
    </div>
  );
}

export default Framework;
