export const PillarOneContent = [
  {
    key: 'be-fair',
    title:
      'Pillar 1: Workfully - Inspire innovation in a diverse and inclusive workplace, fostering a culture of continuous growth.',
    links: [
      {
        key: 'link-be-fair',
        title: 'Be fair',
        text: 'Encourage a work environment that values and nurtures creative thinking and innovation, empowering employees to think outside the box, share and experiment with new ideas. (Promoting fairness by providing a psychologically safe workplace)',
      },
      {
        key: 'link-understand',
        title: 'Understand',
        text: 'Recognize and appreciate the unique perspectives that everyone brings to the table, promoting inclusivity in a workplace where diverse voices are heard and valued. (Understanding the value of diverse perspectives)',
      },
      {
        key: 'link-laugh',
        title: 'Laugh',
        text: 'Create a positive and enjoyable work atmosphere that embraces light-heartedness and celebrates success. Cultivate an environment where laughter and positivity thrive, fostering a sense of well-being and purpose. (Embracing joy as a source of inspiration)',
      },
      {
        key: 'link-learn',
        title: 'Learn',
        text: 'Embrace agile methodologies and flexible work practices to enhance productivity and adaptability. Foster collaboration, cross-functional teamwork, and effective communication to respond swiftly to changes and opportunities. (Learning through collaboration and adaptability)',
      },
      {
        key: 'link-ask-why',
        title: 'Ask why',
        text: 'Foster a culture of curiosity and critical thinking by encouraging people to question the status quo and explore innovative solutions. (Stimulating innovation and driving continuous growth)',
      },
    ],
  },
];
