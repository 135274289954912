import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineRight, AiOutlineDown } from 'react-icons/ai';
import './Accordion.css';

const Accordion = ({ title, links, onClose, handleSignOut }) => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const handleAccordionClick = (url) => {
    if (url) {
      navigate(url);
      onClose();
    } else {
      setIsActive(!isActive);
    }
  };

  const NoIconPlaceholder = () => <div style={{ width: '1em' }} />;

  if (links.length === 1) {
    return (
      <div className="accordion-item">
        <div
          className="accordion-title"
          onClick={() => handleAccordionClick(links[0].url)}
        >
          <div>
            <NoIconPlaceholder />
            {title}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => handleAccordionClick()}>
        <div>
          {isActive ? <AiOutlineDown /> : <AiOutlineRight />}
          {title}
        </div>
      </div>
      {isActive && (
        <div className="accordion-content">
          {links.map((link, index) => (
  <a key={index} href={link.url} className="accordion-link">
    {link.title}
  </a>
))}
{handleSignOut && (
  <button onClick={handleSignOut} className="accordion-link">
    Logout
  </button>
)}

        </div>
      )}
    </div>
  );
};

export default Accordion;
