// RegisterStyles.js
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    closeIcon: {
        position: 'fixed',
        top: '80px',
        right: '460px',
        fontSize: '24px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
  },
  signUpTab: {
    display: 'none',
  },
  authenticatorWrapper: {
    paddingTop: 100,
    paddingBottom: 100,
  },
  closeIconContainer: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 21,
  },
  authenticatorContainer: {
    position: 'fixed',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    align: 'center',
    justify: 'center',
  },
});
