import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useNavigate } from 'react-router-dom';
import './CookieConsentBar.css';

function CookieConsentBar() {
  const navigate = useNavigate();
  return (
    <div className='cookie-consent-container'>
      <CookieConsent
        location='bottom'
        buttonText='Accept'
        cookieName='cookieConsentFooter'
        style={{
          background: '#ffffe0',
          textAlign: 'center',
          fontSize: '12px',
          margin: '0px',
          padding: '0px',
          justifyContent: 'center',
          position: 'fixed', // Set the position to fixed
          left: 0, // Position at the left edge
          bottom: 0, // Position at the bottom
          width: '100%', // Occupy the full width
          zIndex: 9999, // Set a high z-index to ensure it appears above other elements
        }}
        buttonStyle={{
          color: 'white',
          background: '#05A6F0',
          margin: '0px',
          marginLeft: '10px',
          marginRight: '16px',
        }}
        enableDeclineButton
        declineButtonStyle={{
          color: 'white',
          background: '#05A6F0',
          margin: '0px',
          marginBottom: '8px',
        }}
        declineButtonText='Reject'
        expires={365}
      >
        <p style={{ margin: '0px', color: 'black' }}>
          This website uses cookies to enhance the user experience.{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate('/cookie-policy')}
          >
            Learn more
          </span>
        </p>
      </CookieConsent>
    </div>
  );
}

export default CookieConsentBar;
