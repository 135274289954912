const books = [
  {
    id: 1,
    title: 'Getting Things Done',
    author: 'David Allen',
    summary:
      'Is a productivity book that provides a practical system for managing tasks, projects, and responsibilities. It emphasizes capturing and clarifying information, defining actionable steps, and establishing reliable systems to enhance focus and productivity. The book offers techniques to achieve clarity, reduce stress, and effectively manage commitments for increased productivity.',
    affiliateLinkUK:
      'https://www.amazon.co.uk/Getting-Things-Done-Stress-free-Productivity/dp/0349408947/ref=tmm_pap_swatch_0?_encoding=UTF8&amp;qid=1686989358&amp;sr=8-1&_encoding=UTF8&tag=wrkfully-21&linkCode=ur2&linkId=4d784055e96c71935a181f80ed4586ce&camp=1634&creative=6738',
    affiliateLinkUS:
      'https://www.amazon.com/Getting-Things-Done-Stress-Free-Productivity/dp/0143126563/?_encoding=UTF8&amp;pd_rd_w=Doz97&amp;content-id=amzn1.sym.909f85fd-feef-438b-8d6d-2b18aae2bddb&amp;pf_rd_p=909f85fd-feef-438b-8d6d-2b18aae2bddb&amp;pf_rd_r=RFDXP4CJCAXVDMJ4MSTK&amp;pd_rd_wg=B5zll&amp;pd_rd_r=4d989ae8-43cf-4492-b502-e45e6e9c61bf&amp;ref_=pd_gw_ci_mcx_mr_hp_atf_m&_encoding=UTF8&tag=wrkfully-20&linkCode=ur2&linkId=2de13bd6c7d4724ab53c1a9c58569b4f&camp=1789&creative=9325',
    audioBookAffiliateLink:
      'https://www.amazon.com/Getting-Things-Done-David-Allen-audiobook/dp/B01B6WSK5C/ref=tmm_aud_swatch_0?_encoding=UTF8&amp;qid=&amp;sr=&_encoding=UTF8&tag=wrkfully-20&linkCode=ur2&linkId=12a738281a91d32253b9bdbd3fafb4eb&camp=1789&creative=9325',
    coverImage: '/getting-things-done.jpg',
  },
  {
    id: 2,
    title: 'Accelerate',
    authors: ['Nicole Forsgren PhD', 'Jez Humble', 'Gene Kim'],
    summary:
      'Is a productivity book that offers practical insights and strategies for managing tasks, projects, and responsibilities. It provides a comprehensive system for enhancing focus and productivity by capturing and clarifying information, defining actionable steps, and establishing reliable systems. The book emphasizes the importance of clarity, stress reduction, and effective commitment management in achieving increased productivity.',
    affiliateLinkUK:
      'https://www.amazon.co.uk/Accelerate-Software-Performing-Technology-Organizations/dp/1942788339/ref=sr_1_1?crid=J9JW4HP7G1FR&amp;keywords=accelerate&amp;qid=1686989544&amp;sprefix=accelerate%252Caps%252C118&amp;sr=8-1&_encoding=UTF8&tag=wrkfully-21&linkCode=ur2&linkId=9f1d02f0bd64c21fed559b0ea6824b2a&camp=1634&creative=6738',
    affiliateLinkUS:
      'https://www.amazon.com/Accelerate-Software-Performing-Technology-Organizations/dp/1942788339/ref=tmm_pap_swatch_0?_encoding=UTF8&amp;qid=1686989523&amp;sr=1-1&_encoding=UTF8&tag=wrkfully-20&linkCode=ur2&linkId=2cf3a44ac0e5bcf08dbf449d3f19e916&camp=1789&creative=9325',
    audioBookAffiliateLink:
      'https://www.amazon.com/Accelerate-Building-Performing-Technology-Organizations/dp/B07BMBYHXL/ref=tmm_aud_swatch_0?_encoding=UTF8&amp;qid=1686989523&amp;sr=1-1&_encoding=UTF8&tag=wrkfully-20&linkCode=ur2&linkId=ee4fa9d827429932c17f118b9e48cc28&camp=1789&creative=9325',
    coverImage: '/accelerate.jpg',
  },
  {
    id: 3,
    title: 'Training From the Back of the Room',
    author: 'Sharon L. Bowman',
    summary:
      'Is a transformative training by introducing brain-based learning techniques. With practical strategies like the 4C-Model and 4Ts-Model, trainers create engaging sessions that foster connections, present concepts effectively, encourage practice, and promote reflection. This book empowers trainers to deliver impactful learning experiences that optimize engagement, retention, and real-world application of skills.',
    affiliateLinkUK:
      'https://www.amazon.co.uk/Training-Back-Room-Aside-Learn/dp/0787996629/ref=sr_1_1?keywords=training+from+the+back+of+the+room&amp;qid=1686992716&amp;sprefix=training+from+the+back%252Caps%252C122&amp;sr=8-1&_encoding=UTF8&tag=wrkfully-21&linkCode=ur2&linkId=8f36c9b23a0aee0f1c50ce2b9a28e703&camp=1634&creative=6738',
    affiliateLinkUS:
      'https://www.amazon.com/Training-Back-Room-Sharon-Bowman/dp/0787996629/ref=sr_1_1?crid=VY332745DXJC&amp;keywords=training+from+the+back+of+the+room&amp;qid=1686993960&amp;s=audible&amp;sprefix=training+from+the+back+of+the+room%252Caudible%252C197&amp;sr=1-1-catcorr&_encoding=UTF8&tag=wrkfully-20&linkCode=ur2&linkId=cc3650437e33d3369611b40a1c5e3047&camp=1789&creative=9325',
    coverImage: '/training-from-the-back-of-the-room.jpg',
  },
  {
    id: 4,
    title: 'Implementing Lean Software Development',
    authors: ['Mary Poppendieck', 'Tom Poppendieck'],
    summary:
      'Is a comprehensive guide that provides practical insights and strategies for implementing Lean principles in software development. It covers the entire software development lifecycle and emphasizes the importance of collaboration, learning, and customer focus. The book offers techniques such as value stream mapping and kanban, addresses common challenges, and empowers teams to streamline processes, increase productivity, and deliver value to customers efficiently. By adopting the principles outlined in this book, organizations can optimize their workflows, enhance customer satisfaction, and achieve better business outcomes in software development.',
    affiliateLinkUK:
      'https://www.amazon.co.uk/Implementing-Lean-Software-Development-Addison-Wesley/dp/0321437381/ref=sr_1_3?crid=2AF0E8XT32DRL&amp;keywords=Implementing+Lean+Software+Development%253A+From+Concept+to+Cash&amp;qid=1687012721&amp;sprefix=implementing+lean+software+development+from+concept+to+cash%252Caps%252C219&amp;sr=8-3&_encoding=UTF8&tag=wrkfully-21&linkCode=ur2&linkId=54630af174be63d2a4c5995462e06d08&camp=1634&creative=6738',
    affiliateLinkUS:
      'https://www.amazon.com/Implementing-Lean-Software-Development-Concept/dp/0321437381/ref=sr_1_1?crid=2HBN402F9RF2Z&amp;keywords=Mary+Poppendieck&amp;qid=1687012082&amp;s=books&amp;sprefix=mary+poppendieck+%252Cstripbooks-intl-ship%252C188&amp;sr=1-1&_encoding=UTF8&tag=wrkfully-20&linkCode=ur2&linkId=66dcf8134c0377be335324bfc4537402&camp=1789&creative=9325',
    coverImage: '/implementing-lean.jpg',
  },
  {
    id: 5,
    title: 'Start with Why: How Great Leaders Inspire Everyone to Take Action',
    author: 'Simon Sinek',
    summary:
      'Is a thought-provoking book that explores the power of purpose and its impact on individual and organizational success. The author delves into the concept of the Golden Circle, which centers around the question of "Why" rather than "What" or "How." By understanding and communicating the underlying motivations and beliefs that drive actions, leaders can inspire others and create a loyal following. The book highlights the importance of aligning personal and organizational values, fostering a sense of meaning and fulfillment, and ultimately achieving greater innovation and success. It encourages individuals and businesses to embrace their unique purpose and use it as a guiding force to make a positive difference in the world.',
    affiliateLinkUK:
      'https://www.amazon.co.uk/Start-Why-Leaders-Inspire-Everyone/dp/0241958229/ref=sr_1_1?crid=2M4KC5GAKE3TR&amp;keywords=start+with+why&amp;qid=1687013142&amp;sprefix=start+with+why%252Caps%252C123&amp;sr=8-1&_encoding=UTF8&tag=wrkfully-21&linkCode=ur2&linkId=9386cc67ab82c0d4310c1728896e4e67&camp=1634&creative=6738',
    affiliateLinkUS:
      'https://www.amazon.com/Start-Why-Leaders-Inspire-Everyone/dp/1591846447/ref=sr_1_1?keywords=start+with+why+by+simon+sinek&amp;qid=1687012807&amp;s=books&amp;sprefix=start+with%252Cstripbooks-intl-ship%252C218&amp;sr=1-1&_encoding=UTF8&tag=wrkfully-20&linkCode=ur2&linkId=fb366a03e883669a7967207942cab087&camp=1789&creative=9325',
    audioBookAffiliateLink:
      'https://www.amazon.com/Start-Why-Leaders-Inspire-Everyone/dp/B09J5HGNGD/ref=tmm_aud_swatch_0?_encoding=UTF8&amp;qid=1687012807&amp;sr=1-1&_encoding=UTF8&tag=wrkfully-20&linkCode=ur2&linkId=0418e133c0235327847847ac7fa63ea7&camp=1789&creative=9325',
    coverImage: '/start-with-why.jpg',
  },
  {
    id: 6,
    title: 'Thinking, Fast and Slow',
    author: 'Daniel Kahneman',
    summary:
      "Is a groundbreaking book that explores the intricacies of human thinking and decision-making. It introduces the concept of two thinking systems, fast and intuitive versus slow and deliberate, and examines the cognitive biases that influence our judgments. Kahneman's research on prospect theory and the impact of biases on our choices revolutionized the field of behavioral economics. The book emphasizes the importance of recognizing and managing these biases to make better decisions and improve our overall well-being. It offers practical insights and strategies to navigate the complexities of our minds and provides a thought-provoking examination of human cognition.",
    affiliateLinkUK:
      'https://www.amazon.co.uk/Thinking-Fast-Slow-Daniel-Kahneman/dp/0141033576/ref=sr_1_1?crid=3IGQHOX897TSX&amp;keywords=Thinking%252C+fast+and+slow&amp;qid=1687338367&amp;sprefix=thinking+fast+and+slow%252Caps%252C121&amp;sr=8-1&_encoding=UTF8&tag=wrkfully-21&linkCode=ur2&linkId=5cf3692453cc62e98eade5e71f98ff15&camp=1634&creative=6738',
    affiliateLinkUS:
      'https://www.amazon.com/Thinking-Fast-Slow-Daniel-Kahneman/dp/0374533555/ref=sr_1_1?keywords=thinking+fast+and+slow+by+daniel+kahneman&amp;qid=1687338566&amp;sprefix=thinking+f%252Caps%252C221&amp;sr=8-1&_encoding=UTF8&tag=wrkfully-20&linkCode=ur2&linkId=f4e8cbd67b5956a4526abac665dd2492&camp=1789&creative=9325',
    audioBookAffiliateLink:
      'https://www.amazon.com/Thinking-Fast-and-Slow-audiobook/dp/B006QVEQ1Q/ref=tmm_aud_swatch_0?_encoding=UTF8&amp;qid=1687338566&amp;sr=8-1&_encoding=UTF8&tag=wrkfully-20&linkCode=ur2&linkId=1453e6efd1c77c29accca778c092081f&camp=1789&creative=9325',
    coverImage: '/thinking-fast-and-slow.jpg',
  },
  {
    id: 7,
    title: 'The Power of Habit',
    author: 'Charles Duhigg',
    summary:
      'Is a captivating exploration of the science behind habits and their profound impact on our lives. The book introduces the concept of the habit loop, consisting of cue, routine, and reward, and how this loop shapes our behaviors. Duhigg delves into the neurological and psychological mechanisms that drive habits and unveils compelling stories that demonstrate their power in individual lives, organizations, and societies. By understanding the science of habits, readers can learn how to identify and modify their own habits, leading to positive personal and professional transformations. The book offers valuable insights and practical strategies to harness the power of habits and create lasting change.',
    affiliateLinkUK:
      'https://www.amazon.co.uk/Power-Habit-Why-What-Change/dp/1847946240/ref=tmm_pap_swatch_0?_encoding=UTF8&amp;qid=1687338999&amp;sr=8-1&_encoding=UTF8&tag=wrkfully-21&linkCode=ur2&linkId=e3196a728c0dde8c458c3725b4b8e152&camp=1634&creative=6738',
    affiliateLinkUS:
      'https://www.amazon.com/Power-Habit-What-Life-Business/dp/081298160X/ref=tmm_pap_swatch_0?_encoding=UTF8&amp;qid=1687339016&amp;sr=1-1&_encoding=UTF8&tag=wrkfully-20&linkCode=ur2&linkId=2a3961e807226f9277cd6d89bc007eaa&camp=1789&creative=9325',
    audioBookAffiliateLink:
      'https://www.amazon.com/The-Power-of-Habit-Charles-Duhigg-audiobook/dp/B007RJCK1E/ref=sr_1_1?crid=3ONHY6N5QE3LP&amp;keywords=The+Power+of+Habit&amp;qid=1687339016&amp;s=audible&amp;sprefix=the+power+of+habit%252Caudible%252C397&amp;sr=1-1&_encoding=UTF8&tag=wrkfully-20&linkCode=ur2&linkId=8e74d70e111096d2df513f285b1aa162&camp=1789&creative=9325',
    coverImage: '/the-power-of-habit.jpg',
  },
];

export default books;
