import Hero from '../components/hero/Hero';
import FeaturedProducts3Columns from '../components/featuredProducts3Columns/FeaturedProducts3Columns';
import WrkfullyFramework from '../components/wrkfullyFramework/WrkfullyFramework.js';
import Carousel from '../components/carousel/Carousel.js';

function Home() {
  return (
    <div className='homepage-container'>
      <Hero />
      <FeaturedProducts3Columns />
      <WrkfullyFramework />
      <Carousel />
    </div>
  );
}

export default Home;
