import React from 'react';
import './FeaturedProducts3Columns.css';
import MyButton from '../button/Button.js';
import { useNavigate } from 'react-router-dom';

function FeaturedProducts3Columns() {
  const navigate = useNavigate();

  return (
    <div>
      <div className='feature-3-border-bottom'> </div>
      <div className='three-product-full-container'>
        <div className='three-products-container'>
          <div className='three-product-box'>
            <div className='three-product-box-text'>
              <div className='feature-title'>
                <h2>Try Our Self Assessment</h2>
              </div>
              <h4 className='feature-information'>
                Want to be happier at work, know yourself, grow yourself, and be
                the change you want to see? Uncover your unique work persona
                with our simple questionnaire. Get your results and personalized
                tips and start improving today. Embrace self-improvement and
                make a positive impact.
              </h4>
              <div className='feature-nav-link'>
                <MyButton
                  size='medium'
                  onClick={() => navigate('/self-assessment')}
                  style={{ color: '#0D99FF', backgroundColor: 'transparent' }}
                >
                  Start Self Assessment
                </MyButton>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src='../self-assessmenrt.png'
                alt='feature-self-assessment'
                className='feature-picture-1'
              />
            </div>
          </div>
          <div className='three-product-box'>
            <div className='three-product-box-text'>
              <div className='feature-title'>
                <h2>Request Peer Review</h2>
              </div>
              <h4 className='feature-information'>
                Ready for feedback? Request a Peer Review and discover your work
                persona through the eyes of others. Get your results and
                valuable insights from your colleagues and friends. Improve
                further and take the next step towards creating a happier work
                reality.
              </h4>
              <div className='feature-nav-link'>
                <MyButton
                  size='medium'
                  onClick={() => navigate('/peer-review')}
                  style={{ color: '#0D99FF', backgroundColor: 'transparent' }}
                >
                  Request Peer Review
                </MyButton>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src='../images/peer-review.png'
                alt='your-mission'
                className='feature-picture-2'
              />
            </div>
          </div>
          <div className='three-product-box'>
            <div className='three-product-box-text'>
              <div className='feature-title'>
                <h2>Your wrkfully Journey</h2>
              </div>
              <h4 className='feature-information'>
                Our 5-step journey is simple: assess, learn, implement, review,
                and improve. Our values are clear: be fair, understand, laugh,
                learn, ask why. Apply our “fully” principles and transform your
                workplace. Lets not just work together – lets innovate and
                succeed together.
              </h4>
              <div className='feature-nav-link'>
                <MyButton
                  size='medium'
                  onClick={() => navigate('/your-journey')}
                  style={{ color: '#0D99FF', backgroundColor: 'transparent' }}
                >
                  Read more
                </MyButton>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src='../your-journey.png'
                alt='peer-assessment'
                className='feature-picture-3'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='feature-3-border-bottom'> </div>
    </div>
  );
}

export default FeaturedProducts3Columns;
