import React, { useState, useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
import './MyProfile.css';
import '@aws-amplify/ui-react/styles.css';
import { Flex, Text, View } from '@aws-amplify/ui-react';
import { useLocation } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { SelfAssessment } from '../../models';
import { PeerReview } from '../../models';
import { v4 as uuidv4 } from 'uuid';
import ShareBox from './ShareBox';
import MyButton from '@mui/material/Button';
import calculatePersona from '../calculatePersona';
import principleFeedback from '../principleFeedback';
import { getScoreMessage } from '../wrkScore';
import Button from '@mui/material/Button';

const MyProfile = () => {
  const [showShareBox, setShowShareBox] = useState(false);
  const [selfAssessments, setSelfAssessments] = useState([]);
  const [peerReviews, setPeerReviews] = useState([]);
  const location = useLocation();
  const finalScore = location.state?.finalScore;
  const finalMessage = location.state?.finalMessage;
  const assessmentType = location.state?.assessmentType;
  const [shouldSaveData, setShouldSaveData] = useState(false);
  const [user, setUser] = useState(null);
  const [showAllResults, setShowAllResults] = useState(false);
  const peerReviewRef = useRef(null);
  const selfAssessmentRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const principles = ['fair', 'understand', 'laugh', 'learn', 'ask why'];
  const criterias = ['workfully', 'respectfully', 'kindfully'];
  const [isVisiblePrinciple, setIsVisiblePrinciple] = useState(
    principles.map(() => false)
  );
  const [isVisibleCriteria, setIsVisibleCriteria] = useState(
    criterias.map(() => false)
  );
  const [isVisiblePrinciple2, setIsVisiblePrinciple2] = useState(
    principles.map(() => false)
  );
  const [isVisibleCriteria2, setIsVisibleCriteria2] = useState(
    criterias.map(() => false)
  );

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const toggleVisibilityPrinciple = index => {
    setIsVisiblePrinciple(prevState => {
      const updatedVisibility = [...prevState];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };

  const toggleVisibilityCriteria = index => {
    setIsVisibleCriteria(prevState => {
      const updatedVisibility = [...prevState];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };

  const toggleVisibility2 = () => {
    setIsVisible2(!isVisible2);
  };

  const toggleVisibilityPrinciple2 = index => {
    setIsVisiblePrinciple2(prevState => {
      const updatedVisibility = [...prevState];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };

  const toggleVisibilityCriteria2 = index => {
    setIsVisibleCriteria2(prevState => {
      const updatedVisibility = [...prevState];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      console.log(user);
      setUser(user);
    });
    fetchAssessments();
  }, []);

  useEffect(() => {
    const subscription = DataStore.observe(SelfAssessment).subscribe(() => {
      fetchAssessments();
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (finalScore !== null && finalMessage !== null && assessmentType) {
      if (assessmentType === 'self') {
        saveSelfAssessmentData(finalScore, finalMessage);
      } else if (assessmentType === 'peer') {
        // handle peer assessment
      }
      setShouldSaveData(false);
    }
  }, [finalScore, finalMessage, assessmentType, shouldSaveData]);

  async function saveSelfAssessmentData(score, message) {
    try {
      await DataStore.save(
        new SelfAssessment({
          name: score.toString(),
          description: message,
          owner: user.attributes.sub,
        })
      );
      fetchAssessments();
    } catch (error) {
      console.error('Error saving self-assessment:', error);
    }
  }

  async function fetchAssessments() {
    try {
      const apiDataSelfAssessment = await DataStore.query(SelfAssessment);
      console.log(apiDataSelfAssessment);
      setSelfAssessments(apiDataSelfAssessment);
      const apiDataPeerReview = await DataStore.query(PeerReview);
      console.log(apiDataPeerReview);
      setPeerReviews(apiDataPeerReview);
    } catch (error) {
      console.error('Error fetching assessments:', error);
    }
  }

  const getPrincipleFeedback = (principle, score) => {
    const feedbackRange = Object.keys(principleFeedback[principle]);
    const [min, max] = feedbackRange
      .find(range => isScoreInRange(score, range))
      .split('-');
    return principleFeedback[principle][`${min}-${max}`];
  };

  const getScore = score => {
    const scorePercent = (score / 25) * 100;
    return scorePercent;
  };

  // Function to check if the score is within the given range
  const isScoreInRange = (score, range) => {
    const [min, max] = range.split('-');
    return score >= parseInt(min) && score <= parseInt(max);
  };

  function generatePeerReviewLink() {
    const uniqueId = uuidv4();
    const currentURL = window.location.origin;
    const owner = user?.attributes.sub;
    const peerReviewLink = `${currentURL}/peer-review/${uniqueId}?owner=${owner}`;
    return peerReviewLink;
  }

  const displayResults = showAllResults
    ? selfAssessments.slice(-5).reverse()
    : selfAssessments.slice(-1).reverse();

  const displayResultsPeer = showAllResults
    ? peerReviews.slice(-5).reverse()
    : peerReviews.slice(-1).reverse();

  const handleToggleShowAllResults = () => {
    // Toggle the showAllResults state
    setShowAllResults(!showAllResults);

    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Use smooth scrolling behavior
    });
  };

  const scrollToSection = ref => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className='my-profile-container'>
      <Flex justifyContent='center' mt='1rem'>
        <MyButton
          style={{
            size: 'small',
            textTransform: 'capitalize',
            color: '#FEFEFE',
          }}
          onClick={() => scrollToSection(peerReviewRef)}
        >
          View Peer Review
        </MyButton>
        <MyButton
          style={{
            size: 'small',
            textTransform: 'capitalize',
            color: '#FEFEFE',
          }}
          onClick={() => scrollToSection(selfAssessmentRef)}
        >
          View Self Assessment
        </MyButton>
      </Flex>
      <View className='self-assessment' ref={peerReviewRef}>
        <div className='profile-section'>
          <h2>Peer Review Info</h2>
          <br />
          <Flex direction='column' justifyContent='center' alignItems='center'>
            <MyButton
              className='request-button'
              onClick={() =>
                setShowShareBox(prevShowShareBox => !prevShowShareBox)
              }
              size='medium'
              outline='true'
              style={{
                textTransform: 'capitalize',
                backgroundColor: '#0D99FF',
                color: '#FEFEFE',
              }}
            >
              Request Peer Review
            </MyButton>
          </Flex>
          {showShareBox && (
            <div className='share-box'>
              <br />
              <div className='social-media-icons'>
                <ShareBox link={generatePeerReviewLink()} />
              </div>
            </div>
          )}
          <View margin='1rem 0'>
            {displayResultsPeer.map(peerReview => {
              const selectedButtonPeer = JSON.parse(peerReview.name);
              const personaPeer = calculatePersona(selectedButtonPeer);
              const commentByReviewer = peerReview.comment;
              const reviewer = peerReview.reviewer;
              const formattedDate = new Date(
                peerReview.createdAt
              ).toLocaleString();
              const { principleScores } = personaPeer;
              return (
                <div
                  className='result-box'
                  key={peerReview.id || peerReview.name}
                >
                  <Flex
                    justifyContent={['center', 'flex-start']}
                    direction='column'
                    alignItems='center'
                  >
                    <h2 as='strong'>{personaPeer?.persona.name}</h2>
                    <div style={{ textAlign: 'center' }}>
                      Result Date: {formattedDate}
                      <br />
                      Reviewer (Optional): {reviewer}
                      <br />
                      Comment from Reviewer (Optional): {commentByReviewer}
                    </div>
                    {/*<Button
                      onClick={toggleVisibility}
                      variant='text'
                      style={{ color: 'white' }}
                    >
                      {isVisible ? 'View less details' : 'View more details'}
                    </Button>
                    {isVisible && (
                      <div>
                        <h4>{personaPeer?.persona.description}</h4>
                      </div>
                    )}*/}
                    <div className='principle-results'>
                      {Object.entries(principleScores).map(
                        ([principle, score], index) => (
                          <div className='principle-result' key={principle}>
                            <Text>
                              <b>
                                {principle}: {Math.ceil((score / 15) * 100)}%
                              </b>
                            </Text>
                            <div style={{ textAlign: 'center' }}>
                              <Button
                                onClick={() => toggleVisibilityPrinciple(index)}
                                variant='text'
                                style={{ color: 'white' }}
                              >
                                {isVisiblePrinciple[index]
                                  ? 'View less details'
                                  : 'View more details'}
                              </Button>
                            </div>
                            {isVisiblePrinciple[index] && (
                              <div>
                                <Text as='div'>
                                  {getPrincipleFeedback(principle, score)}
                                </Text>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                    <View className='criteria-scores'>
                      {Object.entries(personaPeer.criteriaScores).map(
                        ([criteria, score], index) => (
                          <div className='criteria-score' key={criteria}>
                            <Text>
                              <b>
                                {criteria}: {Math.ceil((score / 25) * 100)}%
                              </b>
                            </Text>
                            <Button
                              onClick={() => toggleVisibilityCriteria(index)}
                              variant='text'
                              style={{ color: 'white' }}
                            >
                              {isVisibleCriteria[index]
                                ? 'View less details'
                                : 'View more details'}
                            </Button>
                            {isVisibleCriteria[index] && (
                              <div>
                                <Text as='div'>
                                  <div style={{ color: 'white' }}>
                                    {getScoreMessage(getScore(score), criteria)}
                                  </div>
                                </Text>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </View>
                  </Flex>
                </div>
              );
            })}
            {selfAssessments.length > 1 && (
              <Flex justifyContent='center' mt='1rem'>
                <Button
                  onClick={handleToggleShowAllResults}
                  variant='text'
                  style={{ color: 'white' }}
                >
                  {showAllResults ? 'View Less Results' : 'View All Results'}
                </Button>
              </Flex>
            )}
          </View>
        </div>
      </View>
      <View className='self-assessment' ref={selfAssessmentRef}>
        <div className='profile-section'>
          <h2>Self Assessment Info</h2>
          <View margin='1rem 0'>
            {displayResults.map(selfAssessment => {
              const selectedButton = JSON.parse(selfAssessment.name);
              const persona = calculatePersona(selectedButton);
              const formattedDate = new Date(
                selfAssessment.createdAt
              ).toLocaleString();
              const { principleScores } = persona;
              return (
                <div
                  className='result-box'
                  key={selfAssessment.id || selfAssessment.name}
                >
                  <Flex
                    justifyContent={['center', 'flex-start']}
                    direction='column'
                    alignItems='center'
                  >
                    <h2 as='strong'>{persona?.persona.name}</h2>
                    <div>Result Date: {formattedDate}</div>
                    {/*<Button
                      onClick={toggleVisibility}
                      variant='text'
                      style={{ color: 'white' }}
                    >
                      {isVisible ? 'View less details' : 'View more details'}
                    </Button>
                    {isVisible && (
                      <div>
                        <h4>{persona?.persona.description}</h4>
                      </div>
                    )}*/}
                    <div className='principle-results'>
                      {Object.entries(principleScores).map(
                        ([principle, score], index) => (
                          <div className='principle-result' key={principle}>
                            <Text>
                              <b>
                                {principle}: {Math.ceil((score / 15) * 100)}%
                              </b>
                            </Text>
                            <div style={{ textAlign: 'center' }}>
                              <Button
                                onClick={() =>
                                  toggleVisibilityPrinciple2(index)
                                }
                                variant='text'
                                style={{ color: 'white' }}
                              >
                                {isVisiblePrinciple[index]
                                  ? 'View less details'
                                  : 'View more details'}
                              </Button>
                            </div>
                            {isVisiblePrinciple2[index] && (
                              <div>
                                <Text as='div'>
                                  {getPrincipleFeedback(principle, score)}
                                </Text>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                    <View className='criteria-scores'>
                      {Object.entries(persona.criteriaScores).map(
                        ([criteria, score], index) => (
                          <div className='criteria-score' key={criteria}>
                            <Text>
                              <b>
                                {criteria}: {Math.ceil((score / 25) * 100)}%
                              </b>
                            </Text>
                            <Button
                              onClick={() => toggleVisibilityCriteria2(index)}
                              variant='text'
                              style={{ color: 'white' }}
                            >
                              {isVisibleCriteria2[index]
                                ? 'View less details'
                                : 'View more details'}
                            </Button>
                            {isVisibleCriteria2[index] && (
                              <div>
                                <Text as='div'>
                                  <div style={{ color: 'white' }}>
                                    {getScoreMessage(getScore(score), criteria)}
                                  </div>
                                </Text>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </View>
                  </Flex>
                </div>
              );
            })}
            {selfAssessments.length > 1 && (
              <Flex justifyContent='center' mt='1rem'>
                <Button
                  onClick={handleToggleShowAllResults}
                  variant='text'
                  style={{ color: 'white' }}
                >
                  {showAllResults ? 'View Less' : 'View All Results'}
                </Button>
              </Flex>
            )}
          </View>
        </div>
      </View>
    </div>
  );
};

export default MyProfile;
