import React from 'react';
import './HeroFramework.css';

function HeroFramework() {
  return (
    <div>
      <div className='framework-hero-container-sa'>
        <div className='framework-hero-left-sa'>
          <img
            src='/images/framework2.png'
            alt='fully-principles-sa'
            className='framework-hero-image-sa'
          />
        </div>
        <div className='framework-hero-right-sa'>
          <div className='framework-hero-content-sa'>
            <h1>Self Assessment </h1>
          </div>
          <div className='framework-hero-content-sa-body'>
            <h4>
              Ready for some self-discovery? With 15 questions, the test
              identifies patterns in your responses to categorize you into one
              of eight unique work personality types. The tool also explores
              your approach to fairness, understanding, humor, learning, and
              curiosity in your work environment. Upon completion of the
              Self-Assessment, you’ll receive your results and personalised tips
              for improvement.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroFramework;
