// Register.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GrClose } from 'react-icons/gr';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import { useLoginTheme } from './../LoginTheme.js';
import { formFields } from './../FormFields.js';
import { useStyles } from './../RegisterStyles.js';

const Register = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useLoginTheme();

  const handleCloseAuthenticator = () => {
    navigate("/");
  };

  return (
    <div style={{ position: 'relative', backgroundColor: '#200063' }}>
      <div className={classes.authenticatorWrapper}>
        <ThemeProvider theme={theme}>
          <Authenticator formFields={formFields} initialState="signUp">
            <div slot="sign-up" className={classes.signUpTab}></div>
          </Authenticator>
        </ThemeProvider>
      </div>
      <div className={classes.closeIconContainer}>
        <button className={classes.closeIcon} onClick={handleCloseAuthenticator}>
          <GrClose />
        </button>
      </div>
    </div>
  );
};

export default Register;