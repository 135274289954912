import React from 'react';
import './Hero.css';
import '../styles.css';

function Hero() {
  return (
    <div className='hero-container'>
      <div className='hero-contents'>
        <div className='hero-content'>
          <h1 className='fade-in-text'>
            Let's create a world where everyone has the opportunity to thrive
            and succeed, together.
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Hero;
