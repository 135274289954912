import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className='terms-container'>
      <div className='t-and-c-title'>
        <h1>Terms and Conditions</h1>
      </div>
      <br />
      Last Updated: June 9, 2023
      <br />
      <br />
      <b>Introduction:</b>
      <p>
        Welcome to our wrkfully website. Before you make use of our products,
        which include our website, self-assessment, peer review, and guide, we
        ask you to carefully read the following conditions of use.
      </p>
      <b>Acceptance of Terms:</b>
      <p>
        By accessing and using our website and any of the services offered
        through our site, including but not limited to tools, assessments, and
        educational materials (collectively, the "Services"), you agree to be
        bound by these Terms and Conditions.
      </p>
      <b>Changes to Terms:</b>
      <p>
        We reserve the right to modify these terms at any time. Any changes will
        be effective immediately upon posting to our website. Your continued use
        of the Services constitutes your agreement to all such terms.
      </p>
      <b>User Responsibilities:</b>
      <p>
        You agree to use the Services in compliance with all applicable laws and
        regulations. You are solely responsible for your conduct while using the
        Services.
      </p>
      <b>Intellectual Property:</b>
      <p>
        All content, including but not limited to text, images, graphics, logos,
        tools, and assessments, are our property or the property of our
        licensors and are protected by copyright, trademark, and other laws.
      </p>
      <b>Privacy & Data Collection:</b>
      <p>
        By using our Services, you agree to the collection and use of your
        information as outlined in our Privacy Policy, including but not limited
        to your username, email, answers to assessment questions, results, IP
        address, and data collected through Google ads. You acknowledge that
        this data will be used for providing and improving our Services and may
        be stored in your user profile.
      </p>
      <b>User Account:</b>
      <p>
        If you create an account on our website, you are responsible for
        maintaining the confidentiality of your account and password, as well as
        for all activities that occur under your account.
      </p>
      <b>Limitations of Liability:</b>
      <p>
        To the fullest extent permitted by law, we disclaim all liability for
        any loss or damage of any kind arising out of your use of the Services.
      </p>
      <b>Indemnity:</b>
      <p>
        You agree to indemnify and hold harmless wrkfully, its officers, agents
        and representatives from and against any and all claims, damages,
        obligations, losses, liabilities, costs or debt, and expenses (including
        but not limited to attorney's fees) arising from your use of the
        Services or your violation of any term of these Terms.
      </p>
      <b>Contact Us:</b>
      <p>
        If you have any questions about these Terms, please contact us at
        info@wrkfully.com.
      </p>
    </div>
  );
};

export default TermsAndConditions;
