export const PillarTwoContent = [
  {
    key: 'pillar-two',
    title:
      'Pillar 2: Respectfully - Support collaborative interactions, embrace continuous learning and appreciate different perspectives and diverse views.',
    links: [
      {
        key: 'link-be-fair',
        title: 'Be fair',
        text: 'Treat all individuals with respect and dignity, creating an inclusive environment where everyone feels heard and valued. (Promoting fairness by ensuring people are equally heard and valued)',
      },
      {
        key: 'link-understand',
        title: 'Understand',
        text: 'Cultivate empathy by seeking to understand different views and experiences. Empathize with others and create opportunities for meaningful communication. (Fostering empathy and compassion in interactions)',
      },
      {
        key: 'link-laugh',
        title: 'Laugh',
        text: 'Foster a positive and friendly work atmosphere where humor is used to reduce tension and build meaningful connections, creating a sense of belonging. (Supporting a positive and inclusive work environment through laughter)',
      },
      {
        key: 'link-learn',
        title: 'Learn',
        text: 'Promote a culture of continuous learning and growth, embracing new ideas and perspectives. Encourage open-mindedness and a willingness to learn from others. (Creating a culture of continuous learning)',
      },
      {
        key: 'link-ask-why',
        title: 'Ask why',
        text: 'Ask questions, challenge assumptions, consider why things are the way they are, make better-informed decisions and drive positive change. Encourage others to ask why and support their efforts for clarity. (Promoting curiosity and making better-informed decisions) ',
      },
    ],
  },
];
