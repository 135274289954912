import React, { useState } from 'react';
import './AccordionFramework.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const AccordionFramework = ({ title, links, style }) => {
  const [isActive, setIsActive] = useState(false);
  //test
  return (
    <div className='framework-accordion-item'>
      <div
        className='framework-accordion-title'
        style={style}
        onClick={() => setIsActive(!isActive)}
      >
        <div className='framework-icons'>{isActive ? '▼' : '▶'}</div>
        <h4>{title}</h4>
      </div>
      {isActive && (
        <Grid
          container
          justifyContent='center'
          spacing={2}
          className='framework-accordion-content'
        >
          {links.map((link, index) => (
            <Grid item xs={12} sm={12} md={4} lg={4} key={link.key}>
              <Card
                className='framework-accordion-link'
                style={{
                  width: '100%',
                  height: '100%',
                  Color: 'black',
                }}
              >
                <CardContent className='card-content'>
                  <Typography variant='h5' component='div'>
                    {link.title}
                  </Typography>
                  <Typography
                    className='div'
                    style={{ lineHeight: '1.1', Color: 'black' }}
                  >
                    {link.text}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default AccordionFramework;
