export const accordionData = [
  {
    title: 'Assessments',
    links: [
      {
        title: 'Self assessment',
        url: '/self-assessment',
        hasChildren: true,
        key: 'self-assessment',
      },
      {
        title: 'Peer review',
        url: '/peer-review',
        hasChildren: true,
        key: 'peer-review',
      },
    ],
  },
  {
    title: 'Framework',
    links: [
      {
        title: 'Framework',
        url: '/framework',
        hasChildren: true,
        key: 'framework',
      },
      {
        title: 'Your Journey',
        url: '/your-journey',
        hasChildren: true,
        key: 'your-journey',
      },
    ],
  },
  {
    title: 'About Us',
    links: [
      {
        title: 'About Us',
        url: '/about-us',
        hasChildren: false,
        key: 'about-us',
      },
    ],
  },
  {
    title: 'Inspiration Area',
    links: [
      {
        title: 'Inspiration Area',
        url: '/inspiration-area',
        hasChildren: false,
        key: 'inspiration-area',
      },
    ],
  },
  {
    title: 'Blog',
    links: [
      {
        title: 'Blog',
        url: '/blog',
        hasChildren: false,
        key: 'blog',
      },
    ],
  },
  {
    title: 'Contact Us',
    links: [
      {
        title: 'Contact Us',
        url: '/contact-us',
        hasChildren: false,
        key: 'contact-us',
      },
    ],
  },
];
