import React from 'react';
import './Tabs.css';

const TabContent = ({ className, content, isActive, buttonText, link, }) => {
    const handleClick = () => {
        window.open(link, '_blank'); // open link in a new tab
      };
    
  return (
    <div className={`tab-content ${isActive ? 'active' : ''}`}>
      {content}
    </div>
  );
};

export default TabContent;
