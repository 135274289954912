import React, { useState } from 'react';
import './NewsletterSubscription.css';
import MyButton from '@mui/material/Button';
import { API, graphqlOperation } from 'aws-amplify';
import { createEmailSubscription } from '../../../graphql/mutations';

function NewsletterSubscription() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const handleInputChange = e => {
    setEmail(e.target.value);
    setEmailError(false);
    setSuccessMessage(false);
  };

  const handleSubscribe = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      setTimeout(() => {
        setEmailError(false);
      }, 2000);
      return;
    }
    try {
      await API.graphql(
        graphqlOperation(createEmailSubscription, { input: { email } })
      );
      console.log('Email subscribed successfully:', email);
      setSuccessMessage(true);
      setTimeout(() => {
        setSuccessMessage(false);
      }, 2000);
    } catch (error) {
      console.error('Error subscribing:', error);
    }
  };

  return (
    <div className='newsletter-background'>
      <div className='newsletter-container'>
        <div className='newsletter-image-container'>
          <img
            className='newsletter-image'
            src='../images/newsletter.png'
            alt='newsletter'
          />
        </div>
        <div className='newsletter-details-container'>
          <h4 className='newsletter-title'>Join our Newsletter</h4>
          <h5 className='newsletter-text'>
            Sign up for our newsletter to receive helpful tips, industry
            insights, and inspiring stories.
          </h5>
          <form
            className='newsletter-form'
            onSubmit={e => {
              e.preventDefault();
              handleSubscribe();
            }}
          >
            <div className='subscribe-input-box'>
              <input
                type='email'
                placeholder='Enter your email address'
                value={email}
                onChange={handleInputChange}
              />
              <div className='message-box'>
                {emailError && (
                  <div className='error-message'>Invalid email address</div>
                )}
                {successMessage && (
                  <div className='success-message'>
                    Thank you for subscribing!
                  </div>
                )}
              </div>
            </div>
            <div>
              <MyButton
                size='medium'
                outline='true'
                onClick={handleSubscribe}
                type='submit'
                style={{
                  textTransform: 'capitalize',
                  backgroundColor: '#0D99FF',
                  color: '#FEFEFE',
                }}
              >
                Subscribe
              </MyButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewsletterSubscription;
