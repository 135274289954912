import React from 'react';
import './Licenses.css';

const Licenses = () => {
  return (
    <div className='licenses-container'>
      <div className='licenses-page-title'>
        <h1>Licenses</h1>
      </div>
      <p>
        This page lists the licenses for third-party libraries and resources
        used in our website.
      </p>
      <h2>Images</h2>
      <ul>
        <li>
          Image from Freepik:
          <br />
          <a
            href='https://www.freepik.com/free-photo/senior-woman-superhero-with-glasses-wearing-red-cape-posing-camera-raising-fists-like-winner-showing-power-strength_15717160.htm#page=2&query=empowerment%20funny&position=0&from_view=search&track=ais'
            target='_blank'
            style={{ color: 'blue' }}
          >
            Image by stockking
          </a>{' '}
          on Freepik
          <br />
          <a
            href='https://www.freepik.com/free-photo/fish-eye-upper-angle-shot-thoughtful-suspicious-young-man-having-doubts-staring-with-disbelief-working-with-laptop_9226857.htm'
            target='_blank'
            style={{ color: 'blue' }}
          >
            Image by cookie_studio
          </a>{' '}
          on Freepik
          <br />
          <a
            href='https://www.freepik.com/free-photo/positive-bearded-man-has-cheerful-expression_12349722.htm#query=empowerment%20funny&position=15&from_view=search&track=ais'
            target='_blank'
            style={{ color: 'blue' }}
          >
            Image by wayhomestudio
          </a>{' '}
          on Freepik
          <br />
          <a
            href='https://www.freepik.com/free-photo/man-with-sad-travel-hat-backpack-looking-map-fro_17530137.htm'
            target='_blank'
            style={{ color: 'blue' }}
          >
            Image by wirestock
          </a>{' '}
          on Freepik
          <br />
          <a
            href='https://www.freepik.com/free-photo/senior-woman-superhero-with-glasses-wearing-red-cape-looking-camera-with-serious-face-standing-orange-background_17054284.htm#from_view=detail_alsolike#position=1'
            target='_blank'
            style={{ color: 'blue' }}
          >
            Image by stockking
          </a>{' '}
          on Freepik
          <br />
          <a
            href='https://www.freepik.com/free-photo/man-with-sad-travel-hat-backpack-looking-map-fro_17530137.htm'
            target='_blank'
            style={{ color: 'blue' }}
          >
            Image by wirestock
          </a>{' '}
          on Freepik
        </li>
        <li>
          <a href='https://www.freepik.com/free-photo/humanoid-robot-working-with-machine-factory-ai-generative_41369247.htm#page=2&query=ai&position=32&from_view=search&track=sph'>
            Image by chandlervid85
          </a>{' '}
          on Freepik
        </li>
        <li>
          Image by Freepik
          <a href='https://www.freepik.com/free-photo/man-shaking-hand-with-robotic-arm_23992679.htm#query=AI%20human%20agree&position=4&from_view=search&track=ais'>
            Freepik
          </a>
        </li>
        <li>
          Image from Pexels:
          <br />
          <a
            href='https://www.pexels.com/photo/photo-of-man-holding-white-paper-6802047/'
            target='_blank'
            style={{ color: 'blue' }}
          >
            Image from Pexels
          </a>{' '}
          <br />
          <a
            href='https://www.pexels.com/photo/photo-of-woman-showing-frustrations-on-her-face-4458420/'
            target='_blank'
            style={{ color: 'blue' }}
          >
            Image from Pexels
          </a>{' '}
          <br />
          <a
            href='https://www.pexels.com/photo/a-robot-holding-a-wine-8439089/'
            target='_blank'
            style={{ color: 'blue' }}
          >
            Image from Pexels
          </a>{' '}
        </li>
      </ul>
    </div>
  );
};

export default Licenses;
