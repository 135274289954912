export const MenuItems = {
  Assessments: [
    {
      title: 'Self Assessment',
      url: '/self-assessment',
      cName: 'dropdown-link',
      hasChildren: true,
      key: 'self-assessment',
    },
    {
      title: 'Peer Review',
      url: '/peer-review',
      cName: 'dropdown-link',
      hasChildren: true,
      key: 'peer-review',
    },
  ],
  Framework: [
    {
      title: 'wrkfully Framework',
      url: '/framework',
      cName: 'dropdown-link',
      hasChildren: true,
      key: 'wrkfully-framework',
    },
    {
      title: 'Start Your Journey',
      url: '/your-journey',
      cName: 'dropdown-link',
      hasChildren: true,
      key: 'start-your-journey',
    },
  ],
  'About Us': [
    {
      title: 'About Us',
      url: '/about-us',
      cName: 'dropdown-link',
      hasChildren: false,
      key: 'about-us',
    },
  ],
  'Inspiration Area': [
    {
      title: 'Inspiration Area',
      url: '/inspiration-area',
      cName: 'dropdown-link',
      hasChildren: false,
      key: 'inspiration-area',
    },
  ],
  Blog: [
    {
      title: 'Blog',
      url: '/blog',
      cName: 'blog',
      hasChildren: false,
      key: 'blog',
    },
  ],
  'Contact Us': [
    {
      title: 'Contact Us',
      url: '/contact-us',
      cName: 'dropdown-link',
      hasChildren: false,
      key: 'contact-us',
    },
  ],
};
