export const getScoreMessage = (score, category) => {
  if (category === 'workfully') {
    if (score >= 80) {
      return (
        <>
          {/*<p>
            Your responses tell us that you're playing a great game! Like a
            top-notch coach, you're all about creating teams with players from
            different backgrounds. You're not just reading the game plan; you're
            understanding it inside and out. And just like the best coaches, you
            don't shy away from asking the tough questions and shaking up the
            playbook. Even when the game gets tough, you keep the team's spirits
            high.
          </p>*/}
          <p>
            <strong>Tips for Improvement:</strong>
          </p>
          <ul>
            <li>
              Keep Being Inclusive: Continue to value and seek out diverse
              perspectives. They enrich your decision-making process. Remember,
              there's always a new perspective to consider, so never stop
              listening and learning from others.
            </li>
            <li>
              Maintain the Challenge: Keep challenging norms and widely accepted
              practices. This is key for innovation and growth. And remember,
              the world never stops changing, so don't be afraid to challenge
              the status quo again and again.
            </li>
            <li>
              Understanding is Crucial: You're good at understanding the
              objectives of your projects. Keep this up, but also always look
              for ways to dive even deeper. There may be layers you haven't
              discovered yet!
            </li>
            <li>
              Stay Positive: Your ability to keep a positive attitude during
              challenging situations is fantastic. But remember, positivity is
              like a muscle - the more you use it, the stronger it becomes. Keep
              working out that positivity muscle!
            </li>
            <li>
              Equality is Paramount: You're doing great at promoting equal
              opportunities. But remember, equity is an ongoing journey. Stay
              open, be ready to stand up for fairness whenever needed, and never
              stop advocating for what's right.
            </li>
          </ul>
        </>
      );
    } else if (score >= 61) {
      return (
        <>
          {/*<p>
            Your responses are like a sandwich with a bit of everything. You
            sometimes try different ingredients and understand that it's good to
            know what you're cooking before you start. When things get tough,
            you mix things up - sometimes you're serious, other times you try to
            lighten things up. You also occasionally enjoy a good chat that goes
            against the grain.
          </p>*/}
          <p>
            <strong>Tips for Improvement:</strong>
          </p>
          <ul>
            <li>
              Stay Curious: Like exploring a new city, keep trying to learn from
              everyone around you. This will make your work and relationships
              more exciting and rewarding.
            </li>
            <li>
              Think Outside the Box: Try to stir things up more often. It's like
              trying a new dish at your favorite restaurant - you might discover
              a new favorite!
            </li>
            <li>
              Stand Up for Fairness: Make sure everyone gets their turn on the
              stage. When everyone's voice is heard, we can make beautiful music
              together.
            </li>
            <li>
              Stay Positive: Try to keep your chin up when things get tough.
              It's like turning on a light in a dark room - it can make
              everything a bit easier.
            </li>
            <li>
              Value Everyone's Voice: Like adding different instruments to a
              band, include ideas from all sorts of people. It'll make your
              decisions sound much better!
            </li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          {/*<p>
            Your answers suggest that you might be leaning a bit too much on
            your own ideas. It's like you're cooking with the same ingredients
            every time. How about adding a dash of something new? It seems you
            might also be following rules without asking "why?" sometimes. And
            when things get tough, you might forget that a laugh or two can make
            things feel a lot lighter.
          </p>*/}
          <p>
            <strong>Tips for Improvement:</strong>
          </p>
          <ul>
            <li>
              Spice it up with Diversity: Like trying a new recipe, seek out
              ideas from colleagues of different backgrounds. You'll be
              surprised how this can change the taste of your work!
            </li>
            <li>
              Break the Mold: Next time you're in a conversation, why not stir
              things up? Question the usual way of doing things and get ready
              for some fresh insights.
            </li>
            <li>
              Know the Game: Get the big picture of your projects before you
              jump in. It's like reading the rules before playing a game - it's
              gonna help you win!
            </li>
            <li>
              Be the Fair-Play Captain: Make sure everyone gets a shot at
              scoring a goal. Stand up for equal opportunities no matter who's
              on the team.
            </li>
            <li>
              Smile through the Storm: When things get tough, remember that a
              good laugh can turn a stormy day into a fun adventure!
            </li>
          </ul>
        </>
      );
    }
  }
  if (category === 'respectfully') {
    if (score >= 80) {
      return (
        <>
          {/*<p>
            Your responses tell us you're like a seasoned captain, steering the
            ship while considering the comfort of all on board. You're not just
            setting the course, you're making sure it's the best one for
            everyone involved. And just like the best captains, you don't shy
            away from navigating through different viewpoints. Even when the
            seas get rough, you keep the crew's spirits high with a dash of
            humor. Your love for learning is like your compass, guiding you to
            ask the right questions and understand new concepts.
          </p>*/}
          <p>
            <strong>Tips for Improvement:</strong>
          </p>
          <ul>
            <li>
              Keep Steering Together: Continue to make decisions with everyone
              on board in mind. It's like plotting a course that's best for all
              crew members.
            </li>
            <li>
              Navigate Different Waters: Embrace the thrill of navigating
              diverse seas. Understanding different viewpoints can lead to more
              rewarding journeys.
            </li>
            <li>
              Maintain a Jovial Ship: Your ability to keep the mood light with
              humor is like a sea shanty that lifts everyone's spirits. Keep
              this up - it makes the voyage more enjoyable!
            </li>
            <li>
              Continue to Explore: Keep up your curiosity! Like a captain
              seeking new lands, your joy of learning leads to amazing
              discoveries.
            </li>
            <li>
              Keep Asking 'Why': Your inquisitiveness is your compass. Continue
              to use it to navigate through new concepts and ideas. It's crucial
              for steering your ship successfully.
            </li>
          </ul>
        </>
      );
    } else if (score >= 61) {
      return (
        <>
          {/*<p>
            Your responses show that you're like a balanced cyclist. You
            sometimes consider the impact on all riders before choosing the
            route. You appreciate different sceneries, but there may be a few
            more exciting paths to explore. Your journey isn't always filled
            with laughs, but you know it can make the ride more enjoyable. And
            while you sometimes stop to learn about interesting landmarks, there
            might be more to discover if you do it more often.
          </p>*/}
          <p>
            <strong>Tips for Improvement:</strong>
          </p>
          <ul>
            <li>
              Consider All Cyclists: When choosing a route, consider all riders.
              Like planning a group bike ride, this can lead to more enjoyable
              journeys for everyone.
            </li>
            <li>
              Enjoy Different Sceneries: Embrace the beauty of diverse
              landscapes. Like trying different biking trails, understanding
              diverse viewpoints can enhance your ride.
            </li>
            <li>
              Fill the Ride with Laughs: Laughter can lighten the mood like a
              fun cycling anthem. Include it more often to strengthen your
              relationships.
            </li>
            <li>
              Explore New Landmarks: It's exciting to stop and learn about
              interesting sights just for fun. Why not try it more often? Who
              knows what fascinating landmarks you might discover!
            </li>
            <li>
              Ask 'Why': Always ask 'why' like you're studying a biking trail
              map. It's crucial for understanding the route and for a successful
              ride.
            </li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          {/*<p>
            Your responses hint that your approach may be a bit like going on a
            solo hike. You tend to make decisions without considering how it
            might affect everyone on the trail. You might be sticking to your
            own path, not often venturing into the unexplored terrains of
            different viewpoints. A good joke or funny story doesn't seem to be
            part of your backpack. And you don't often go on detours to learn
            something new just for fun.
          </p>*/}
          <p>
            <strong>Tips for Improvement:</strong>
          </p>
          <ul>
            <li>
              Navigate the Impact: When making a decision, think of it as
              planning a hike. Who will be joining? How will this path affect
              them? This can lead to more enjoyable journeys for everyone.
            </li>
            <li>
              Broaden Your Horizon: Embrace the beauty of diverse landscapes.
              Understanding different viewpoints is like exploring new terrains
              - it can enrich your overall experience.
            </li>
            <li>
              Pack Some Humor: Humor can lighten the mood like a funny campfire
              story. Try using it to strengthen your relationships.
            </li>
            <li>
              Learn for Fun: Sometimes, it's fun to take a detour just to learn
              something new. Try to do this more often - who knows what
              fascinating things you might discover!
            </li>
            <li>
              Ask 'Why': Always ask 'why' like you're checking your map. It's
              crucial for you to understand the lay of the land and to navigate
              your journey successfully.
            </li>
          </ul>
        </>
      );
    }
  }
  if (category === 'kindfully') {
    if (score >= 80) {
      return (
        <>
          {/*<p>
            Your answers suggest you're like a fantastic leader! You make sure
            everyone gets a fair shot, and you're keen on understanding their
            viewpoints. You know how to brighten up the mood with a good laugh.
            Every bump in the road is seen as a lesson, which is a great
            mindset! Plus, you're not one to just accept a decision. You're
            always interested in the 'why' behind it.
          </p>*/}
          <p>
            <strong>Tips for Improvement:</strong>
          </p>
          <ul>
            <li>
              Promote Fairness: Keep treating everyone like a VIP. Remember,
              there's always a new way to make someone feel special!
            </li>
            <li>
              Empathize More: Keep trying to understand others' viewpoints.
              Remember, there's always a new part of the city to explore!
            </li>
            <li>
              Spread Laughter: You're doing great at keeping the mood light.
              Remember, there's always room for more sunshine in the day!
            </li>
            <li>
              Keep Learning: Keep seeing every bump in the road as a chance to
              learn. Remember, every setback is a new opportunity for growth!
            </li>
            <li>
              Stay Curious: Keep digging into the 'why' behind decisions.
              Remember, there's always a new gem to discover!
            </li>
          </ul>
        </>
      );
    } else if (score >= 61) {
      return (
        <>
          {/*<p>
            Your answers make it seem like you're exploring with a good map in
            your hands. You're often making sure that everyone gets a fair shot,
            and you sometimes try to understand their viewpoints. You know that
            a good laugh can brighten up a cloudy day, but you may not always
            remember to bring out the sunshine. While you see the bumps in the
            road as chances to learn, there might be times you forget to reflect
            on them. When it comes to understanding why a decision was made,
            you're usually interested, but there's room to dig a bit deeper.
          </p>*/}
          <p>
            <strong>Tips for Improvement:</strong>
          </p>
          <ul>
            <li>
              Boost Fairness: Try to always treat everyone like they're special.
              Remember, the best journeys are the ones where everyone feels
              valued!
            </li>
            <li>
              Understand More: Try to see things from other people's
              perspective. It's like exploring a new part of the city!
            </li>
            <li>
              Laugh More: Laughter can turn a cloudy day into a sunny one. Try
              to bring more laughter into your day!
            </li>
            <li>
              Reflect on the Bumps: Every bump in the road is a chance to grow.
              Make sure to take a moment and learn from them.
            </li>
            <li>
              Seek Understanding: Keep on asking about the 'why' behind
              decisions. It's like finding a hidden gem!
            </li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          {/*<p>
            Looking at your answers, it feels like you might be on a solo
            journey a bit too much, like a lone traveler in a big city. You
            might not always give a fair shot to everyone, and it might be tough
            for you to hop into their shoes and take a walk around. When things
            get cloudy, a good laugh can be like a burst of sunshine, but you
            might sometimes forget. It also seems like you might skip over the
            bumps in the road instead of learning from them. Lastly, when a
            decision is made, you might not always dig into the 'why' behind it.
          </p>*/}
          <p>
            <strong>Tips for Improvement:</strong>
          </p>
          <ul>
            <li>
              Keep it Fair: Try to treat everyone like a VIP, no matter how you
              feel about them. It makes the journey a whole lot more enjoyable!
            </li>
            <li>
              Jump into Their Shoes: Try to understand where others are coming
              from. You might find some interesting paths!
            </li>
            <li>
              Laugh it Off: When things get tough, remember that laughter can be
              the best remedy. Don't forget to crack a smile!
            </li>
            <li>
              Learn from the Bumps: Every bump in the road is a chance to grow.
              Take a moment to think about what you can learn from them.
            </li>
            <li>
              Dig Deeper: If a decision is made, ask about the 'why' behind it.
              It's like uncovering a hidden treasure!
            </li>
          </ul>
        </>
      );
    }
  }
};
