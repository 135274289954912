import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  WorkplaceShareButton,
  WorkplaceIcon,
  ViberShareButton,
  ViberIcon,
} from 'react-share';
import './ShareBox.css';

const ShareBox = ({ link }) => {
  const summary =
    'I would really appreciate your feedback with this Peer Review.\n\nIt will only take 1 minute to fill. Thank you!';
  const image =
    'https://wrkfully-storage-71adb504225013-staging.s3.eu-central-1.amazonaws.com/public/peer-review-assessment.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAQT5ADM4NQ2H3F2VW%2F20230616%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230616T223156Z&X-Amz-Expires=300&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDGV1LWNlbnRyYWwtMSJIMEYCIQDbnWD%2BsowgmkJjeW26Os5uSpb6SLVPWddDu0U6Fg%2BTmgIhAPFiXhWBtNl5vUwKH5G8HFLbNzMWfwLMns4VKVGHgZ1iKtYECE8QABoMMDQyNzQ4NTY5MzcxIgwIZD8w728OrdgKWFUqswQK%2B62zGjQV%2BVEnuWHm0r43%2FdiUPhIforGZS%2BxW6TKvjF7f8PTUEss67NRo4jAqlOqt5Iz44yLibbMMIwPM1Se5pcqJ5X2vcG7j0yOaWP9Brj0VqTOu0OrNCQD%2B4IpjEzcd7qz4iq2402npKAOjbCrOUUW%2BbXDcVZylflU0jRZdolDJg4%2FlEokH1AqIopidrvtBBxJcPmnmyQ4%2FTL8XzkKzOwGhYeIniNURGW9aFwH0G05e1dSzNAQIXhsRilz8E3wVjTXnRdakSpgY6FsToCaAClQVHlcz9x%2Fis%2BPE52a4deaTptfgO0nUXGCE7G9id05%2BsLNl0dqOREtTR49JjtbZnB6sTDVKWKAqFmnBG6SwYkrRdKhZxrxBx%2FXk2m%2BaIebMPLesxBnA9wV1SmuDHXGkNowUrfPbY89IPYJNWXYPCHkGAXWUuF0iEY0N8AVTaMhKC1DTK4D7jYfjVl%2B1CRiqIpnSJ4nJvf7kWIew%2BDXwtd8iTkQJwZA1Te0spu%2BHUqFfaXNfQFG7l%2B8GfV%2BGp%2FuUOveu9bWgEVucFsK0ECHq0DwDt6NmLnVjyGifm%2FdCkgvzvXQEbFHxQb1sTHaGu0UaPHvydkGcESvdnHXX0g7IIBMrHSYqz5%2FBW%2FazAWSTZJZ2dwNyZC%2FcrUfYi3XxyiqbjMWUin2Ly0uwHWoX%2FxK0YH0GpzmUKbagybi7v53mglI6QbxO5lTM%2BULyZ%2FkUN5NvWB1oOLFN2Os2b9PUiVV5ZuFZNTCLqrOkBjqEAgXsyKdIksLHtYiNYQfwHIvZ0XbKWsf%2B7awYkdQyEH%2FL5hL%2B3xggcy26RRorcqohJueEPfMyKIgwDOr4q8N3jvJR9Zw7jtUdpD04iK%2Fn3c2FrqrIFLa7NGAcNFHkg4DvEtRYIN93QNg2ax2HhRFDSYWesWXogmJDi5vOX38gr4dobQ5H%2FDOz8lK1hnamL5XjnO6luH6tpUUyFT33TI1rmRXUaef1p7kavgNLRghKIohTIvN2tb5rP%2FuErcsVp2pKH34GYuaNVqP1qpLSjoh29k1BNBU%2FMiZXCncRrRCbxDcuGpH6bT1FXnz2c%2FnJ9%2BNImL8RfN%2Fs7p9TDPgby0quEkvIUSrm&X-Amz-Signature=e4abd457fdcd5643ddabf66d081a3da45226303f65d2acac9a934745a3b50048&X-Amz-SignedHeaders=host'; // Replace with the absolute URL of your image

  return (
    <>
      <div className='share-box'>
        <div className='social-media-icons'>
          <div>
            <EmailShareButton
              url={link}
              subject='Peer Review - To better enhance my skills'
              body={`I would really appreciate your feedback with this Peer Review. It will only take 1 minute to fill. Thank you!\n\n`}
              media={image}
            >
              <EmailIcon size={28} round />
            </EmailShareButton>
          </div>
          <div>
            <LinkedinShareButton
              url={link}
              summary={summary}
              source={image}
              hashtag='#wrkfully'
            >
              <LinkedinIcon size={28} round />
            </LinkedinShareButton>
          </div>
          <div>
            <WhatsappShareButton
              url={link}
              quote='I would really appreciate your feedback with this Peer Review, will only take 1 minute to fill, thanks!'
              hashtag='#wrkfully'
            >
              <WhatsappIcon size={28} round />
            </WhatsappShareButton>
          </div>
          <div>
            <WorkplaceShareButton
              url={link}
              quote='I would really appreciate your feedback with this Peer Review, will only take 1 minute to fill, thank you!'
              hashtag='#wrkfully'
            >
              <WorkplaceIcon size={28} round />
            </WorkplaceShareButton>
          </div>
          <div>
            <ViberShareButton
              url={link}
              quote='I would really appreciate your feedback with this Peer Review, will only take 1 minute to fill, thank you!'
              hashtag='#wrkfully'
            >
              <ViberIcon size={28} round />
            </ViberShareButton>
          </div>
          <div>
            <TwitterShareButton
              url={link}
              title='Peer Review - To better enhance my skills'
              via='wrkfully'
              hashtags={['wrkfully']}
              related={['wrkfully']}
              media={image}
            >
              <TwitterIcon size={28} round />
            </TwitterShareButton>
          </div>
          <div>
            <FacebookShareButton
              url={link}
              quote='I would really appreciate your feedback with this Peer Review, will only take 1 minute to fill, thank you!'
              hashtag='#wrkfully'
              media={image}
            >
              <FacebookIcon size={28} round />
            </FacebookShareButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareBox;
