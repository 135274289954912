import Home from './pages/home/Home';
import './App.css';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import AboutUs from './pages/aboutUs/AboutUs';
import Assessments from './pages/assessments/Assessments';
import SelfAssessmentForm2 from './pages/selfAssessmentForm/SelfAssessmentForm';
import PeerReviewForm from './pages/peerReview/PeerReview';
import PeerReviewInfo from './pages/peerReviewInfo/PeerReviewInfo';
import Framework from './pages/framework/Framework';
import YourJourney from './pages/yourJourney/YourJourney';
import ContactUs from './pages/contactUs/ContactUs';
import TermsAndConditions from './pages/termsAndConditions/TermsAndConditions';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import CookiePolicy from './pages/cookiePolicy/CookiePolicy';
import MyProfileContainer from './pages/myProfile/MyProfileContainer';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './pages/components/navbar/Navbar';
import CookieConsentBar from './pages/components/cookieConsentBar/CookieConsentBar';
import Footer from './pages/components/footer/Footer';
import Licenses from './pages/licenses/Licenses';
import InspirationPage from './pages/inspiration/InspirationPage';
import BlogPage from './pages/blogPage/BlogPage';
import BlogPostPage from './pages/blogPage/BlogPostPage';
import frames from './pages/BlogData';
import AdsComponent from './AdsComponent';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route exact path='/about-us' element={<AboutUs />} />
        <Route exact path='/assessments' element={<Assessments />} />
        <Route
          exact
          path='/self-assessment'
          element={<SelfAssessmentForm2 />}
        />
        <Route
          exact
          path='/self-assessment-form'
          element={<SelfAssessmentForm2 />}
        />
        <Route exact path='/peer-review/' element={<PeerReviewInfo />} />
        <Route
          exact
          path='/peer-review/:uniqueId'
          element={<PeerReviewForm />}
        />
        <Route exact path='/framework' element={<Framework />} />
        <Route exact path='/my-profile' element={<MyProfileContainer />} />
        <Route exact path='/your-journey' element={<YourJourney />} />
        <Route exact path='/contact-us' element={<ContactUs />} />
        <Route exact path='/inspiration-area' element={<InspirationPage />} />
        <Route exact path='/blog' element={<BlogPage />} />
        {frames.map(post => (
          <Route
            key={post.link}
            path={post.link}
            element={<BlogPostPage post={post} />}
          />
        ))}
        <Route
          exact
          path='/terms-and-conditions'
          element={<TermsAndConditions />}
        />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route exact path='/cookie-policy' element={<CookiePolicy />} />
        <Route exact path='/licenses' element={<Licenses />} />
      </Routes>
      <div className='footer-section'>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
