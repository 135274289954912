import Products3x2x2 from './components/products3x2x2/Products3x2x2'
import Hero from './components/hero/Hero'

const AboutUs = () => {

  return (
    <div>
      <Hero />
      <Products3x2x2 />
    </div>
  );
};

export default AboutUs;
