import React, { useState, useEffect } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';
import useMediaQuery from '@mui/material/useMediaQuery';
import './Navbar.css';
import { MenuItems } from './NavbarInputData.js';
import '../styles.css';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Accordion from '../accordion/Accordion';
import { accordionData } from './AccordionContent';
import MyButton from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth, Hub } from 'aws-amplify';
import { useStyles } from '../../RegisterStyles.js';
import { useLoginTheme } from '../../LoginTheme.js';
import { formFields } from '../../FormFields.js';

const Navbar = () => {
  const classes = useStyles();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1150px)');
  const [anchorEl, setAnchorEl] = useState({});
  const navigate = useNavigate();
  const [showAuthenticator, setShowAuthenticator] = useState(false);
  const [authenticatorState, setAuthenticatorState] = useState('');
  const [user, setUser] = useState(null);
  const [parentUrl, setParentUrl] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const theme = useLoginTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const checkUser = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    checkUser();
    const listener = Hub.listen('auth', data => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        setUser(payload.data);
        window.location.reload();
      } else if (payload.event === 'signOut') {
        setUser(null);
        window.location.reload();
      }
    });

    return () => Hub.remove('auth', listener);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1201) {
        setIsOpen(false);
        setShowHamburgerMenu(false); // Add this line
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCloseAuthenticator = () => {
    setShowAuthenticator(false);
  };

  const handleLinkClick = () => {
    setShowHamburgerMenu(false);
  };

  const navigateToAuth = form => {
    setAuthenticatorState(form);
    setShowAuthenticator(true);
  };

  const handleClick = (index, event, url) => {
    setAnchorEl({ ...anchorEl, [index]: event.currentTarget });
    setParentUrl(url);
  };

  const handleClose = (url, hasChildren) => {
    setAnchorEl({});
    if (!hasChildren && parentUrl) {
      navigate(parentUrl);
    } else if (url) {
      navigate(url);
    }
  };

  const toggleHamburgerMenu = () => {
    setShowHamburgerMenu(!showHamburgerMenu);
  };

  const handleLogoClick = () => {
    setShowHamburgerMenu(false);
    navigate('/');
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <div className='navbar-wrapper'>
      <div className='nav-container'>
        <div className='nav-left'>
          <a onClick={handleLogoClick}>
            <img src='/logocontainer@2x.png' alt='logo' className='nav-image' />
          </a>
        </div>
        <div className='nav-center'>
          {isMobile ? (
            <div className='accordion-data'>
              {accordionData.map(({ title, links }) => (
                <Accordion key={title} title={title} links={links} />
              ))}
            </div>
          ) : (
            <div className={classes.root}>
              <Toolbar className={classes.toolbar}>
                {Object.keys(MenuItems).map((item, index) => (
                  <div className={classes.navButton} key={item}>
                    <Button
                      color='inherit'
                      onClick={e => {
                        if (MenuItems[item][0].hasChildren) {
                          handleClick(index, e);
                        } else {
                          navigate(MenuItems[item][0].url);
                        }
                        e.preventDefault();
                      }}
                      style={{
                        textTransform: 'capitalize',
                        fontWeight: 250,
                        color: 'white',
                        margin: 8,
                        fontWeight: 400,
                      }}
                    >
                      {item}
                      {MenuItems[item][0].hasChildren && (
                        <i className='fas fa-caret-down' />
                      )}
                    </Button>
                    {MenuItems[item][0].hasChildren && (
                      <Menu
                        disableScrollLock={true}
                        anchorEl={anchorEl[index]}
                        keepMounted
                        open={Boolean(anchorEl[index])}
                        onClose={() => handleClose('')}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        {MenuItems[item].map(menuitem => (
                          <MenuItem
                            key={menuitem.title}
                            selected={menuitem === item}
                            onClick={() =>
                              handleClose(menuitem.url, menuitem.hasChildren)
                            }
                          >
                            {menuitem.title}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </div>
                ))}
              </Toolbar>
            </div>
          )}
        </div>
        {isAuthenticated ? (
          <div className='nav-right'>
            <div className='nav-button'>
              <MyButton
                size='medium'
                onClick={() => navigate('/my-profile')}
                style={{
                  textTransform: 'capitalize',
                  backgroundColor: '#0D99FF',
                  color: '#FEFEFE',
                }}
              >
                My Profile
              </MyButton>
              <MyButton
                size='medium'
                outline='true'
                onClick={handleSignOut}
                style={{ color: '#FEFEFE', textTransform: 'capitalize' }}
              >
                Logout
              </MyButton>
            </div>
          </div>
        ) : (
          <div className='nav-right'>
            <div className='nav-button'>
              <MyButton
                size='medium'
                onClick={() => navigateToAuth('signUp')}
                style={{
                  textTransform: 'capitalize',
                  backgroundColor: '#0D99FF',
                  color: '#FEFEFE',
                }}
              >
                Register
              </MyButton>
            </div>
            <div className='nav-button nav-button-login'>
              <MyButton
                size='medium'
                outline='true'
                onClick={() => navigateToAuth('signIn')}
                style={{ color: '#FEFEFE', textTransform: 'capitalize' }}
              >
                Login
              </MyButton>
            </div>
          </div>
        )}
        <button className='hamburger-btn' onClick={toggleHamburgerMenu}>
          <GiHamburgerMenu className='hamburger-icon' />
        </button>
      </div>
      <div className={`hamburger-menu ${showHamburgerMenu ? 'show' : ''}`}>
        <div className='accordion-data'>
          {accordionData.map(({ title, links }) => (
            <Accordion
              key={title}
              title={title}
              links={links}
              onClose={handleLinkClick}
            />
          ))}
        </div>
        {isAuthenticated ? (
          <div className='hamburger-menu-logged-out'>
            <div className='nav-button'>
              <MyButton
                size='medium'
                onClick={() => {
                  navigate('/my-profile');
                  setShowHamburgerMenu(false);
                }}
                style={{ backgroundColor: '#0D99FF', color: '#FEFEFE' }}
              >
                My Profile
              </MyButton>

              <MyButton
                size='medium'
                onClick={handleSignOut}
                style={{ color: '#0D99FF' }}
              >
                Logout
              </MyButton>
            </div>
          </div>
        ) : (
          <div className='hamburger-menu-logged-in'>
            <div className='nav-button'>
              <MyButton
                size='medium'
                outline='true'
                onClick={() => navigateToAuth('signUp')}
                style={{
                  textTransform: 'capitalize',
                  backgroundColor: '#0D99FF',
                  color: '#FEFEFE',
                }}
              >
                Register
              </MyButton>
            </div>
            <div className='nav-button nav-button-login'>
              <MyButton
                size='medium'
                outline='true'
                onClick={() => navigateToAuth('signIn')}
                style={{ color: '#0D99FF', textTransform: 'capitalize' }}
              >
                Login
              </MyButton>
            </div>
          </div>
        )}
      </div>
      {showAuthenticator && (
        <div className='authenticator-container'>
          <div className='authenticator-overlay' />
          <div className='authenticator'>
            <ThemeProvider theme={theme}>
              <Authenticator
                formFields={formFields}
                initialState={authenticatorState}
                onStateChange={authState => {
                  if (authState === 'signedIn') {
                    setIsAuthenticated(true);
                    setShowAuthenticator(false);
                    navigate('/');
                  } else if (authState === 'signedOut') {
                    setIsAuthenticated(false);
                  }
                }}
              />
            </ThemeProvider>
          </div>
          <div className='close-icon-container'>
            <button className='close-icon' onClick={handleCloseAuthenticator}>
              <GrClose />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
