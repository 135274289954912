import React from 'react';
import './HeroFramework.css';
import 'animate.css';

function HeroFramework() {
  return (
    <div className='framework-hero-container'>
      <div className='framework-hero-left'>
        <img
          src='/images/framework.png'
          alt='fully-principles'
          className='framework-hero-image'
        />
      </div>
      <div className='framework-hero-right'>
        <div className='framework-hero-right-padding'>
          <div className='framework-hero-content animate__animated animate__zoomIn'>
            <h1>Empowering workplaces with the wrkfully framework</h1>
          </div>
          <div className='framework-hero-content animate__animated animate__zoomIn'>
            <h4>
              The wrkfully framework is your ultimate guide to creating an
              inspiring workplace, where everyone feels heard, respected, and
              valued. With our Dynamic Trio - Workfully, Respectfully, and
              Kindfully - you will learn how to apply the fully principles in
              your everyday work life, while following best practices on ways of
              working. Embrace continuous improvement and transform your
              workplace into a space supporting creativity and innovation.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroFramework;
