import React from 'react';
import frames from '../BlogData';
import { useNavigate } from 'react-router-dom';
import './BlogPage.css';
import Button from '@mui/material/Button';

const BlogPage = () => {
  const navigate = useNavigate();

  const handleReadMoreClick = link => {
    navigate(link);
  };

  return (
    <div className='all-frames-container'>
      <div>
        <h1 style={{ color: 'white' }}>Blog</h1>
      </div>
      <div className='all-frames-page'>
        {frames.map((frame, index) => (
          <section key={index} className='frame-section'>
            <div className='frame-image'>
              <img src={frame.image} alt={`Frame ${index}`} />
            </div>
            <div className='frame-details'>
              <h2>{frame.title}</h2>
              <p>{frame.paragraph}</p>
              <Button
                onClick={() => handleReadMoreClick(frame.link)}
                color='primary'
                style={{
                  textTransform: 'capitalize',
                  color: '#0D99FF',
                }}
              >
                Read More
              </Button>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;
