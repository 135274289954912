import React from 'react';
import './Tabs.css';

const Tab = ({ label, content, isActive, onClick }) => {
  return (
    <button
      className={`tab-btn ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Tab;
