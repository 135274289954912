import React from 'react';
import './BlogPostPage.css';

const BlogPostPage = ({ post }) => {
  return (
    <div className='blog-post-page'>
      <h1 className='blog-post-title'>{post.title}</h1>
      <img src={post.image} alt={post.title} className='blog-post-image' />
      <p className='blog-post-paragraph'>{post.article}</p>
    </div>
  );
};

export default BlogPostPage;
