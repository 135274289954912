import { useTheme } from '@aws-amplify/ui-react';

export const useLoginTheme = () => {
  const { tokens } = useTheme();

  const theme = {
    name: 'Login and Register Theme',
    tokens: {
      colors: {
        font: {
          interactive: {
            value: tokens.colors.red.value,
          },
        },
        brand: {
          primary: {
            '10': '#F9F9F9',
            '80': '#05A6F0',
            '90': '#78D5FF',
            '100': '#565656',
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: '#7A7A7A',
              },
            },
            _hover: {
              color: {
                value: '#ADADAD',
              },
            },
            _active: {
              color: {
                value: '#565656',
              },
            },
          },
        },
      },
    },
  };

  return theme;
};
