import React from 'react';
import './CookiePolicy.css';

const CookiePolicy = () => {
  return (
    <div className='privacy-container'>
      <div className='privacy-policy-title'>
        <h1>Cookie Policy</h1>
      </div>
      <br />
      Last Updated: June 9, 2023
      <br />
      <br />
      <b>Introduction:</b>
      <p>
        Our website uses cookies. By using our website and agreeing to this
        policy, you consent to our use of cookies in accordance with the terms
        of this policy.
      </p>
      <b>What Are Cookies:</b>
      <p>
        Cookies are small files that are placed on your computer by websites
        that you visit. They are widely used in order to make websites work, or
        work more efficiently, as well as to provide information to the owners
        of the site.
      </p>
      <b>How We Use Cookies:</b>
      <p>
        We may use cookies to::
        <br />
        Recognize you when you visit our website.
        <br />
        Remember your preferences.
        <br />
        Analyze how our website is performing.
        <br />
        Personalize content and ads shown to you.
        <br />
        Our use of cookies is designed to provide you with a good experience
        while you browse our website, improve our site, and provide you with
        personalized content and ads.
      </p>
      <b>Types of Cookies We Use:</b>
      <p>
        We use both session and persistent cookies on our website:
        <br />
        Session cookies are temporary cookies that are used to remember you
        during the course of your visit to the website, and they expire when you
        close the web browser.
        <br />
        Persistent cookies are used to remember your preferences within our
        website and remain on your desktop or mobile device even after you close
        your browser or restart your computer.
        <br />
        We also use third-party cookies, such as Google Analytics, to assist
        with analyzing performance. Any third party cookie usage is governed by
        the privacy policy of the third party placing the cookie.
      </p>
      <b>Opting Out of Cookies:</b>
      <p>
        You can choose to delete cookies at any time via your browser settings.
        You can also choose to disable cookies from your browser settings, but
        this may prevent you from taking full advantage of the website since
        some cookies might be necessary for the website to function properly.
      </p>
      <b>Changes to This Cookie Policy:</b>
      <p>
        We may update this Cookie Policy from time to time. If we make
        significant changes, we will notify you of the changes through our
        website or through others means, such as email.
      </p>
      <b>Contact Us:</b>
      <p>
        If you have any questions about these Terms, please contact us at
        info@wrkfully.com.
      </p>
    </div>
  );
};

export default CookiePolicy;
