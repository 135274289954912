import React from 'react';
import './ImageRightTextLeft.css';

function ImageRightTextLeft() {
  return (
    <div>
      <div className='image-right-text-left-container-top'>
        <div className='image-right-text-left-right-top'>
          <div className='image-right-text-left-content-top'>
            <h1>wrkfully Framework</h1>
          </div>
          <div className='image-right-text-left-content-top'></div>
        </div>
        <div className='image-right-text-left-left-top'>
          <img
            src='/images/wrkfully-framework.svg'
            alt='fully-principles'
            className='image-right-text-left-image-top'
          />
        </div>
      </div>
      <div className='body-image-right-top-section'>
        <h4>
          An innovative guide designed to foster a culture of diversity and
          inclusion, empowering individuals and organizations to revolutionize
          their work culture. Promoting the values of fairness, understanding,
          laughter, learning, and curiosity, we transform workplaces into spaces
          for innovation and growth.
          <br />
          <br />
          Our framework promotes a holistic approach to workplace
          transformation, incorporating three key pillars - Workfully,
          Respectfully, and Kindfully (our Dynamic Trio). With a commitment to
          an all-around improved work environment, this dynamic approach helps
          us think outside the box, be creative, and explore innovative
          solutions.
          <br />
          <br />
          Each pillar addresses crucial aspects of a thriving work culture,
          empowering individuals and teams to unlock their full potential.
        </h4>
        <br />
        <br />
        <h3>
          <strong>Workfully</strong> emphasizes the importance of working smart
          in a diverse and inclusive environment, promoting a culture of
          continuous growth.
          <br />
          <br />
          <strong>Respectfully</strong> focuses on cultivating a culture of
          respect and appreciation, emphasizing the importance of continuous
          learning.
          <br />
          <br />
          <strong>Kindfully</strong> is about promoting kindness and empathy
          within the workplace, while striving for continuous improvement.
        </h3>
        <br />
        <br />
        <h4>
          The wrkfully Framework is more than just a set of principles. It's a
          roadmap to transform your workplace into a better, happier place. It's
          time to reimagine the way we work. Embrace the power of the Dynamic
          Trio and let the journey to an inspiring workplace begin.
        </h4>
        <br />
      </div>
    </div>
  );
}

export default ImageRightTextLeft;
