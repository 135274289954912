import React from 'react';
import './HeroFramework.css';
import { useNavigate } from 'react-router-dom';

function HeroFramework() {
  const navigate = useNavigate();

  return (
    <div className='framework-hero-container-pr'>
      <div className='framework-hero-left-pr'>
        <img
          src='/images/peer-review-assessment.png'
          alt='fully-principles-pr'
          className='framework-hero-image-pr'
        />
      </div>
      <div className='framework-hero-right-pr'>
        <div className='framework-hero-content-pr'>
          <h1>Request a Peer Review</h1>
        </div>
        <div className='framework-hero-content-pr'>
          <h4>
            Invite colleagues and friends to assess your work personality with
            the Peer Review. With 15 questions, this tool captures your work
            persona through other’s perspectives, giving you insights into how
            you're perceived when it comes to fairness, understanding, humor,
            learning, and curiosity. Ready to find out?
          </h4>
        </div>
        <div className='button-pr-container'>
          <button
            className='peer-review-button'
            onClick={() => navigate('/my-profile')}
          >
            Request a Peer Review
          </button>
        </div>
      </div>
    </div>
  );
}

export default HeroFramework;
