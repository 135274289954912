import React, { useState, useEffect } from 'react';
import questions from './questions';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate } from 'react-router-dom';
import './ResultsPage.css';

const ResultsPage = ({ selectedAnswers }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className='results-page'>
      <div className='hero-container-results'>
        <div className='hero-contents-results'>
          <div className='hero-content'>
            <h1 className='fade-in-text'>Thank you!</h1>
            <h4>
              Thank you for completing the peer review. Your feedback is greatly
              appreciated.
              <br />
              <br />
              Why not try the Self Assessment?
            </h4>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#200063' }}>
        <div className='result-section-container'>
          <div className='button-container'>
            <button
              className='share-button'
              onClick={() => navigate('/self-assessment')}
            >
              Continue to the Self Assessment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsPage;
