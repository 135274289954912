const principleFeedback = {
  fair: {
    '0-5':
      'It appears that you may struggle with ensuring fairness in your interactions and decisions. You may need to reflect more on how your actions impact others, and how that in turn impacts your own work environment. In any group, fairness is vital. It helps build relationships of trust and improves group dynamics!',
    '6-11':
      "Some days you're a champion of justice, others... not so much. While you try to provide equal opportunities and consider the impact of your actions, there is much room for improvement. Be fair more consistently. Be impartial, unbiased, and ensure equitable treatment of all – including yourself!",
    '12-15':
      'Your actions show that you strive for an impartial, unbiased, and equitable treatment of all. You understand the power of justice. You always try to provide equal opportunities, make balanced decisions, and treat everyone fairly. Keep up the good work and continuously try to improve further!',
  },
  understand: {
    '0-5':
      'It seems you find it challenging to fully understand different perspectives or the objectives of projects/tasks. It’s not all Greek! Just try to be more open-minded. Start by actively listening to others and try to understand their viewpoints. This will surely improve your relationships and decision-making!',
    '6-11':
      "You do know that 'uh huh' isn't always the best response, right? Practice being more consistent in understanding the specifics of the task/project you are dealing with, and in appreciating others' views and perspectives. Try to be more open-minded and promote a culture of empathy and inclusion!",
    '12-15':
      'Ever considered a career in diplomacy? Your understanding skills are top-notch! Your ability to appreciate different perspectives and views is admirable. You lead by example, with empathy and compassion. Keep up the excellent work! Continue building solid relationships and making well-informed decisions.',
  },
  laugh: {
    '0-5':
      'Bring more joy into your life! You may find it difficult to be positive, especially during challenging situations. But remember, life is too short to be too serious! Humor helps in reducing stress, boosting mood, and improving overall well-being. Laughter helps break barriers and brings people closer together. Sounds fun, right?',
    '6-11':
      'So, you laugh… but only on Fridays? Try to find happiness in everyday moments. Make more time for activities that relieve stress and bring joy and laughter into your daily routine. Keep working on using humor as a tool to promote inclusion, break down barriers, build strong relationships, and improve your work-life balance!',
    '12-15':
      'Is your middle name Joy? Your humor and laughter are infectious! You value the importance of a positive work environment and use laughter as a tool to promote inclusivity, build strong relationships, and maintain a healthy work-life balance. Keep it up - humor is a powerful tool for fostering creativity!',
  },
  learn: {
    '0-5':
      "There's a world of knowledge out there! What are you waiting for? Improve your skills, expand your knowledge, adapt, and discover new ways to tackle challenges. Embrace a growth mindset and view mistakes as opportunities to learn and improve. ‘Learn as if you were to live forever’!",
    '6-11':
      'It seems that you don’t always see the value in continuous learning. But we assure you, an investment in learning pays the best interest! So, have a growth mindset! Treat every situation as an opportunity to learn. Seek out diverse perspectives and viewpoints. Stay up to date and make well informed decisions.',
    '12-15':
      "You're a lifelong learner, always curious and eager to know more. Your dedication to learning and growth is impressive. Your willingness to treat mistakes as learning opportunities is inspiring. Keep exploring new ideas, perspectives, and knowledge. This mindset will serve you well in all aspects of life!",
  },
  'ask why': {
    '0-5':
      'Start asking ‘why’ more often. Developing a habit of asking ‘why’ encourages curiosity, open-mindedness, and critical thinking. By asking ‘why’, you deepen your understanding, identify root causes of problems, and find innovative solutions. When you question assumptions and challenge the status quo, you make positive change!',
    '6-11':
      'You occasionally ask ‘why’, and sometimes question assumptions. But it’s not enough. Having a deeper understanding, challenging assumptions and questioning decisions, can lead to breakthroughs. Encourage yourself to question the status quo more frequently, and open new avenues of thought and innovation!',
    '12-15':
      'Your habit of asking ‘why’ is evidence of your active mind and critical thinking skills. The ability to question, investigate, and analyze sets you apart. You are great at identifying root causes of problems, digging deeper, challenging the status quo, and driving innovative solutions. Keep pushing boundaries!',
  },
};

export default principleFeedback;
