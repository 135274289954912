import React from 'react';
import './YourJourney.css';

function YourJourney() {
  return (
    <div className='container-journey'>
      <div className='your-journey-container'>
        <h1>Your Journey</h1>
        <br />
        <br />
        <div className='journey-info'>
          <h4>
            Embark on the 5-Step wrkfully Journey, a cyclical process meant to
            guide you through transforming your work life into a more
            fulfilling, enjoyable experience. Whether you're an individual or
            part of a team, our approach provides a clear roadmap to enhance
            well-being, promote open communication, and spark innovation at
            every stage.
          </h4>
        </div>
        <div className='timeline'>
          <div className='timeline-item'>
            <div className='timeline-content'>
              <h3>Step 1: Assess</h3>
              <p>
                Begin by self-evaluating or collectively examining your work
                environment's psychological safety, trust, and overall
                well-being using our wrkfully questionnaires.
              </p>
            </div>
          </div>
          <div className='timeline-item'>
            <div className='timeline-content'>
              <h3>Step 2: Learn</h3>
              <p>
                Familiarize yourself with the foundational concepts of our
                Dynamic Trio (Workfully, Respectfully, Kindfully) and the Fully
                principles (be fair, understand, laugh, learn, ask why).
              </p>
            </div>
          </div>
          <div className='timeline-item'>
            <div className='timeline-content'>
              <h3>Step 3: Implement</h3>
              <p>
                Integrate the wrkfully framework into your daily work routine
                and interactions, applying the Dynamic Trio and Fully principles
                through practical steps and tips.
              </p>
            </div>
          </div>
          <div className='timeline-item'>
            <div className='timeline-content'>
              <h3>Step 4: Review</h3>
              <p>
                Periodically revisit your progress, celebrating your victories
                and identifying areas for enhancement.
              </p>
            </div>
          </div>
          <div className='timeline-item'>
            <div className='timeline-content'>
              <h3>Step 5: Improve</h3>
              <p>
                Embark on a journey of continuous learning and refinement of the
                wrkfully approach, adapting to feedback and cultivating a
                growth-oriented mindset.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YourJourney;
