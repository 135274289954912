import React from 'react';
import books from './bookData';
import './inspirationPage.css';

const InspirationPage = () => {
  return (
    <div className='inspiration-page'>
      <header className='header'>
        <div>
          <img
            className='header-image'
            src='\heroimagecontainer2.png'
            alt='Inspiring'
          />
        </div>
        <div className='header-content'>
          <div className='header-text'>
            <h1>Discover Inspirational Books</h1>
            <p>"Books are a uniquely portable magic." - Stephen King</p>
          </div>
        </div>
      </header>
      <div className='book-list'>
        {books.map(book => (
          <div className='book-item' key={book.id}>
            <div className='book-details'>
              <div className='book-info'>
                <div className='book-image'>
                  <img src={book.coverImage} alt={book.title} />
                </div>
                <div className='book-text'>
                  <h2>{book.title}</h2>
                  {book.authors ? (
                    <div className='author-list'>
                      <span>By </span>
                      {book.authors.map((author, index) => (
                        <span key={index}>
                          {author}
                          {index !== book.authors.length - 1 && <br />}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <div className='author-list'>By {book.author}</div>
                  )}
                </div>
              </div>
              <p>{book.summary}</p>
              <div className='purchase-links'>
                <a
                  href={book.affiliateLinkUK}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='purchase-link'
                >
                  Buy From Amazon UK
                </a>
                <a
                  href={book.affiliateLinkUS}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='purchase-link'
                >
                  Buy From Amazon US
                </a>
                {book.audioBookAffiliateLink && (
                  <a
                    href={book.audioBookAffiliateLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='purchase-link'
                  >
                    Get it for Free from Audible
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InspirationPage;
