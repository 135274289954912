import React from 'react';
import './Products3x2x2.css';

function Products3x2x2() {
  return (
    <div style={{ backgroundColor: '#200063' }}>
      <div className='section-container'>
        <div
          className='frame frame-full'
          style={{ backgroundColor: '#05A6F0' }}
        >
          <h2>Empowering people to create inspiring workplaces</h2>
          <h3>
            We want a world where well-being, growth, and open communication are
            prioritized in every workplace. Creating environments that foster a
            culture where everyone feels heard, valued, and empowered.
          </h3>
          {/* <a href='#'>Read More</a> */}
        </div>
        <div
          className='frame frame-half-1'
          style={{ backgroundColor: '#F005A6' }}
        >
          <h2>Vision</h2>
          <h3>
            A world where well-being, creativity, and joy are priorities in
            every workplace.
          </h3>
          {/* <a href='#'>Read More</a> */}
        </div>
        <div
          className='frame frame-half-2'
          style={{ backgroundColor: '#F005A6' }}
        >
          <h2>Mission</h2>
          <h3>
            Empowering people to create their work reality and build thriving,
            nurturing, and inclusive workspaces.
          </h3>
          {/* <a href='#'>Read More</a> */}
        </div>
        <div
          className='frame frame-half-3'
          style={{ backgroundColor: '#A6F005', color: '#1B1B1B' }}
        >
          <h2>Values</h2>
          <h3>
            Be fair, understand, laugh, learn and ask why. Fostering a culture
            of diversity and inclusion.
          </h3>
          {/* <a href='#'>Read More</a> */}
        </div>
        <div
          className='frame frame-half-4'
          style={{ backgroundColor: '#A6F005', color: '#1B1B1B' }}
        >
          <h2>Culture</h2>
          <h3>
            A culture of empowerment, fostering creativity, innovation, and
            continuous growth.{' '}
          </h3>
          {/* <a href='#'>Read More</a> */}
        </div>
      </div>
    </div>
  );
}

export default Products3x2x2;
