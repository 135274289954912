import React from 'react';
import './Hero.css';
import MyButton from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();

  return (
    <div className='hero-section'>
      <div className='hero-content'>
        <div className='left-content'>
          <img src='/hero-container-home.jpg' alt='hero' />
        </div>
        <div className='right-content'>
          <img src='/logo.svg' alt='logo' />
          <div className='right-text'>
            <h1 className='animate__animated animate__fadeIn'>
              Work Persona <br />
              Self Assessment
            </h1>
            <h3 className='animate__animated animate__fadeIn'>
              Want a happier workplace?
              <br />
              Assess yourself and discover your unique work persona.{' '}
            </h3>
            <MyButton
              className='my-button animate__animated animate__fadeIn'
              size='small'
              onClick={() => navigate('/self-assessment')}
              style={{
                textTransform: 'capitalize',
                backgroundColor: '#0D99FF',
                color: '#FEFEFE',
              }}
            >
              Get Started
            </MyButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
