import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStyles, ThemeProvider } from '@mui/styles';
import { Amplify, AuthModeStrategyType } from 'aws-amplify';
import awsconfig from './aws-exports';
import AWS from 'aws-sdk';
import awsmobile from './aws-exports';

AWS.config.update({
  region: awsmobile.aws_cognito_region,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: awsmobile.aws_cognito_identity_pool_id
  })
});
Amplify.configure({
  ...awsconfig,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  },
});

const theme = createStyles({
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#333',
    },
    action: {
      hover: 'rgba(0, 0, 0, 0.08)',
    },
  },
});

const root = document.getElementById('root');
render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
