import React, { useState, useRef, useEffect } from 'react';
import './PeerReview.css';
import questions from './questions'; // Import 'principles' array
import calculatePersona from '../calculatePersona';
import ResultsPage from './ResultsPage';
import { DataStore, Auth } from 'aws-amplify';
import { useParams } from 'react-router-dom';
import { PeerReview } from '../../models';

const PeerReviewForm = () => {
  const [peerReviews, setPeerReviews] = useState([]);
  const [selectedButton, setSelectedButton] = useState({});
  const [activeQuestion, setActiveQuestion] = useState(0);
  const questionsRef = useRef([]);
  const [showResults, setShowResults] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { uniqueId } = useParams();
  const [comment, setComment] = useState('');
  const [reviewer, setReviewer] = useState('');

  useEffect(() => {
    checkAuthStatus();
  }, []);

  async function checkAuthStatus() {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  }

  useEffect(() => {
    fetchPeerReviews();
  }, []);

  useEffect(() => {
    fetchPeerReviews();
  }, [uniqueId]);

  useEffect(() => {
    // Enable scroll restoration when the component is mounted
    window.history.scrollRestoration = 'manual';

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    saveSelectedAnswers();
  }, [selectedButton]);

  async function fetchPeerReviews() {
    const apiData = await DataStore.query(PeerReview);
    console.log(apiData);
    const peerReviewsFromAPI = apiData.items;
    setPeerReviews(peerReviewsFromAPI);
  }

  const saveSelectedAnswers = () => {
    localStorage.setItem('peerSelectedAnswers', JSON.stringify(selectedButton));
  };

  const handleInputChange = (event, questionIndex) => {
    const { value } = event.target;

    setSelectedButton(prevState => ({
      ...prevState,
      [questionIndex]: value,
    }));

    // Find the index of the next unanswered question
    const nextUnansweredIndex = Object.keys(selectedButton).length + 1;

    setActiveQuestion(nextUnansweredIndex);

    // Scroll to the next question
    setTimeout(() => {
      if (questionsRef.current[nextUnansweredIndex]) {
        const nextQuestion = questionsRef.current[nextUnansweredIndex];
        const viewportHeight = window.innerHeight;
        const nextQuestionOffset = nextQuestion.offsetTop;
        const yOffset = nextQuestionOffset - viewportHeight / 2;
        window.scrollTo({ top: yOffset, behavior: 'smooth' });
      }
    }, 0);
  };

  const handleSubmit = async event => {
    const queryParams = new URLSearchParams(window.location.search);
    const owner = queryParams.get('owner');
    event.preventDefault();
    const { principleScores, criteriaScores } =
      calculatePersona(selectedButton);

    try {
      await DataStore.save(
        new PeerReview({
          name: JSON.stringify(selectedButton),
          description: JSON.stringify(principleScores),
          comment: comment,
          uniqueId: uniqueId,
          owner: owner,
          reviewer: reviewer,
        })
      );
    } catch (error) {
      console.log('Error saving self-assessment:', error);
    }
    fetchPeerReviews();
    setShowResults(true);
  };

  const handleStartNewAssessment = () => {
    localStorage.removeItem('selectedAnswers');
    setSelectedButton({});
    setShowIntro(false);
  };

  const isLocalStorageEmpty = !localStorage.getItem('selectedAnswers');

  const hasSelectedAnswers =
    !isLocalStorageEmpty && Object.keys(selectedButton).length > 0;
  const isAllQuestionsAnswered =
    Object.keys(selectedButton).length === questions.length;

  return (
    <>
      {showResults ? (
        <ResultsPage
          persona={calculatePersona(selectedButton)}
          selectedAnswers={selectedButton}
        />
      ) : (
        <form onSubmit={handleSubmit} className='likert-questionnaire-form'>
          <div className='likert-form'>
            <div className='likert-title'>
              <h1>Peer Review</h1>
            </div>
            <div className='likert-body'>
              <p>
                Welcome to our Work Persona Peer Review! For each statement
                below, please indicate your level of agreement from 'strongly
                disagree' to 'strongly agree.
              </p>
            </div>
            <div className='reviewer-text'>
              <label htmlFor='reviewer'>Reviewer Name (Optional):</label>
              <textarea
                className='reviewer-peer-review'
                id='reviewer'
                name='reviewer'
                value={reviewer}
                onChange={e => setReviewer(e.target.value)} // Update this line
              ></textarea>
            </div>
            {questions.map((question, index) => (
              <div
                key={index}
                className='questionName'
                style={{ textAlign: 'center' }}
              >
                {question.name}
                <div
                  className={`questionContainer ${
                    activeQuestion === index ? 'active' : ''
                  } ${selectedButton[index] ? 'answered' : ''}`}
                  key={index}
                  ref={el => (questionsRef.current[index] = el)}
                >
                  <div className='questionTextLeft'>
                    <div>{question.left}</div>
                  </div>
                  <div className='likertScale'>
                    {[1, 2, 3, 4, 5].map(value => (
                      <label
                        key={value}
                        className={`likert-button likert-button-${value}`}
                      >
                        <input
                          type='radio'
                          name={question.name}
                          value={value}
                          checked={selectedButton[index] === String(value)}
                          onChange={event => handleInputChange(event, index)}
                        />
                        <span className='checkmark'></span>
                      </label>
                    ))}
                  </div>

                  <div className='questionTextRight'>
                    <div>{question.right}</div>
                  </div>
                </div>
              </div>
            ))}
            <label htmlFor='comment'>Comment:</label>
            <textarea
              className='comment-peer-review'
              id='comment'
              name='comment'
              value={comment}
              onChange={e => setComment(e.target.value)}
            ></textarea>
            <button
              type='submit'
              className='likert-submit-button'
              disabled={!isAllQuestionsAnswered}
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default PeerReviewForm;
