import React, { useState, useEffect } from 'react';
import questions from './questions';
import calculatePersona from '../calculatePersona';
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import ShareBox from './ShareBox';
import './ResultsPage.css';
import principleFeedback from '../principleFeedback';
import { getScoreMessage } from '../wrkScore';
import Button from '@mui/material/Button';

const getScoreRange = score => {
  if (score <= 5) return '0-5';
  if (score <= 10) return '6-11';
  return '12-15';
};

const ResultsPage = ({ selectedAnswers }) => {
  const [showAuth, setShowAuth] = useState(false);
  const navigate = useNavigate();
  const [authenticatorState, setAuthenticatorState] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showShareBox, setShowShareBox] = useState(false);
  const principles = ['fair', 'understand', 'laugh', 'learn', 'ask why'];
  const [isVisiblePrinciple, setIsVisiblePrinciple] = useState(
    principles.map(() => false)
  );
  const [isVisibleWorkfully, setIsVisibleWorkfully] = useState(false);
  const [isVisibleRespectfully, setIsVisibleRespectfully] = useState(false);
  const [isVisibleKindfully, setIsVisibleKindfully] = useState(false);

  const toggleVisibilityWorkfully = () => {
    setIsVisibleWorkfully(!isVisibleWorkfully);
  };

  const toggleVisibilityRespectfully = () => {
    setIsVisibleRespectfully(!isVisibleRespectfully);
  };

  const toggleVisibilityKindfully = () => {
    setIsVisibleKindfully(!isVisibleKindfully);
  };

  useEffect(() => {
    checkAuthStatus();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const checkAuthStatus = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  const {
    persona: personaName,
    criteriaScores,
    principleScores,
  } = calculatePersona(selectedAnswers);

  const totalScores = questions.reduce((acc, question, index) => {
    const answer = selectedAnswers[index];
    if (!answer) {
      return acc;
    }

    const value = parseInt(answer);

    question.criteria.forEach(criteria => {
      acc[criteria] = (acc[criteria] || 0) + value;
    });

    question.principle.forEach(principle => {
      acc[principle] = (acc[principle] || 0) + value;
    });

    return acc;
  }, {});

  const totalWorkfully = Math.ceil(
    ((totalScores['workfully'] || 0) / 25) * 100
  );
  const totalRespectfully = Math.ceil(
    ((totalScores['respectfully'] || 0) / 25) * 100
  );
  const totalKindfully = Math.ceil(
    ((totalScores['kindfully'] || 0) / 25) * 100
  );

  const handleSignInButtonClick = () => {
    setShowAuth(false);
    setAuthenticatorState('signIn');
    setShowAuth(true);
    navigate('/my-profile', {
      state: {
        criteriaScores,
        principleScores,
        assessmentType: 'self',
      },
    });
  };

  const workfullyMessage = getScoreMessage(totalWorkfully, 'workfully');
  const respectfullyMessage = getScoreMessage(
    totalRespectfully,
    'respectfully'
  );
  const kindfullyMessage = getScoreMessage(totalKindfully, 'kindfully');

  const toggleVisibilityPrinciple = index => {
    setIsVisiblePrinciple(prevState => {
      const updatedVisibility = [...prevState];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
  };

  return (
    <div className='results-page'>
      <div className='hero-container-results'>
        <div className='hero-contents-results'>
          <div>
            <h1 className='fade-in-text'>Your Work Persona</h1>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#200063' }}>
        <div className='result-section-container'>
          <div
            className='frame-result-page'
            style={{ backgroundColor: '#05A6F0' }}
          >
            <h3>{personaName.name}</h3>
            <div>
              {/*<Button
                onClick={toggleVisibility}
                variant='text'
                style={{ color: 'white' }}
              >
                {isVisible ? 'View less details' : 'View more details'}
              </Button>
              {isVisible && (
                <div>
                  <p>{personaName.description}</p>
                </div>
              )}*/}
            </div>
          </div>
          <div className='principles'>
            {principles.map((principle, index) => {
              const principleScore = principleScores[principle];
              const principleScoreRange = getScoreRange(principleScore);
              const principlePercentage = Math.ceil(
                (principleScore / 15) * 100
              );

              return (
                <div key={principle} className='principle-box'>
                  <div className='principle-box-name'>
                    <div className='principle-name'>{principle}</div>
                    &nbsp;
                    <div className='principle-score'>
                      {principlePercentage}%
                    </div>
                  </div>
                  <div className='principle-details'>
                    <div>
                      <Button
                        onClick={() => toggleVisibilityPrinciple(index)}
                        variant='text'
                        style={{ color: 'white' }}
                      >
                        {isVisiblePrinciple[index]
                          ? 'View less details'
                          : 'View more details'}
                      </Button>
                      {isVisiblePrinciple[index] && (
                        <div>
                          <p>
                            {principleFeedback[principle][principleScoreRange]}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className='orange-box-container'>
            <div className='orange-box'>
              <div className='score'>
                <span className='score-criteria'>Workfully&nbsp;</span>
                <span className='score-value'>{totalWorkfully}%</span>
              </div>
              <div className='score-message'>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    onClick={toggleVisibilityWorkfully}
                    variant='text'
                    style={{ color: 'white' }}
                  >
                    {isVisibleWorkfully
                      ? 'View less details'
                      : 'View more details'}
                  </Button>
                  {isVisibleWorkfully && (
                    <div>
                      <p>{workfullyMessage}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='orange-box'>
              <div className='score'>
                <span className='score-criteria'>Respectfully&nbsp;</span>
                <span className='score-value'>{totalRespectfully}%</span>
              </div>
              <div className='score-message'>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    onClick={toggleVisibilityRespectfully}
                    variant='text'
                    style={{ color: 'white' }}
                  >
                    {isVisibleRespectfully
                      ? 'View less details'
                      : 'View more details'}
                  </Button>
                  {isVisibleRespectfully && (
                    <div>
                      <p>{respectfullyMessage}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='orange-box'>
              <div className='score'>
                <span className='score-criteria'>Kindfully&nbsp;</span>
                <span className='score-value'>{totalKindfully}%</span>
              </div>
              <div className='score-message'>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    onClick={toggleVisibilityKindfully}
                    variant='text'
                    style={{ color: 'white' }}
                  >
                    {isVisibleKindfully
                      ? 'View less details'
                      : 'View more details'}
                  </Button>
                  <br />
                  {isVisibleKindfully && (
                    <div>
                      <p>{kindfullyMessage}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='all-buttons'>
            <div className='button-container'>
              <button
                className='share-button'
                onClick={() => setShowShareBox(true)}
              >
                Share Score
              </button>
            </div>

            {showShareBox && (
              <div className='share-box'>
                <div className='share-box-title'>
                  <h2>Share your score on social media!</h2>
                </div>
                <div className='social-media-icons'>
                  <ShareBox />
                </div>
                <button
                  className='close-button'
                  onClick={() => setShowShareBox(false)}
                >
                  Close
                </button>
              </div>
            )}

            <div className='button-container'>
              {!isAuthenticated && (
                <button
                  className='sign-in-button'
                  onClick={handleSignInButtonClick}
                >
                  Save Score and Sign In
                </button>
              )}
            </div>

            <div className='button-container'>
              <button
                className='peer-review-button'
                onClick={() => navigate('/my-profile')}
              >
                Request Peer Review
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsPage;
