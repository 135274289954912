export const PillarThreeContent = [
  {
    key: 'pillar-three',
    title:
      'Pillar 3: Practice compassion and empathy, strive for continuous improvement, and build stronger relationships.',
    links: [
      {
        key: 'link-be-fair',
        title: 'Be fair',
        text: 'Create an environment where everyone feels welcome and part of the team. Embrace fairness as a foundation for building strong and positive relationships of trust. (Promoting fairness by treating all people equally, free from any bias)',
      },
      {
        key: 'link-understand',
        title: 'Understand',
        text: 'Cultivate empathy by seeking to understand others emotions and views. Foster a culture of active listening, where individuals feel genuinely heard and supported. (Designing an environment of trust empathy, and understanding)',
      },
      {
        key: 'link-laugh',
        title: 'Laugh',
        text: 'Encourage a workplace where laughter and light-heartedness are embraced. Use humor as a way to build trust and connect with others. (Creating a positive atmosphere that nurtures meaningful interactions and encourages open-mindedness)',
      },
      {
        key: 'link-learn',
        title: 'Learn',
        text: 'Embrace a mindset of continuous improvement. Encourage individuals to learn from each others experiences, fostering collaboration and mutual support. (Fostering a culture of continuous improvement through shared knowledge)',
      },
      {
        key: 'link-ask-why',
        title: 'Ask why',
        text: 'Foster a culture of curiosity, promoting open dialogue and encouraging individuals to ask why things are done a certain way and seek deeper understanding. (Promoting critical thinking and enabling positive change)',
      },
    ],
  },
];
