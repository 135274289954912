import React from 'react';
import './ImageLeftTextRight.css';

function ImageLeftTextRight() {
  return (
    <div>
      <div className='image-right-text-left-container-bottom'>
        <div className='image-right-text-left-right-bottom'>
          <div className='image-right-text-left-content-bottom'>
            <h1>Dynamic Trio</h1>
          </div>
          <div className='image-right-text-left-content-bottom'></div>
        </div>
        <div className='image-right-text-left-left-bottom'>
          <img
            src='/images/wrkfully-image-3.png'
            alt='fully-principles'
            className='image-right-text-left-image-bottom'
          />
        </div>
      </div>
      <div className='body-image-right'>
        <h4>
          <strong>Workfully - Continuous Growth</strong>
          <br />
        </h4>
        <h4>
          Workfully is about working smart, in a diverse and inclusive
          environment, where everyone’s success is recognized. It's about
          inspiring innovation and fostering continuous growth through the power
          of diverse thinking and collective wisdom. Workfully is where
          adaptability meets productivity, where we learn to dance to the rhythm
          of change, encouraging group effort and effective communication.
        </h4>
        <br />
        <h4>
          <strong>Respectfully - Continuous Learning</strong>
          <br />
        </h4>
        <h4>
          Respectfully promotes respect in every interaction, every
          communication, and every decision. Its about understanding each
          other's position and challenges, learning from our shared experiences,
          and questioning the status quo. Through open communication, active
          listening, and valuing diverse perspectives, Respectfully nurtures
          trust, collaboration, and mutual support.
        </h4>
        <br />
        <h4>
          <strong>Kindfully - Continuous Improvement</strong>
        </h4>
        <h4>
          Kindfully is about building meaningful relationships, creating a
          supportive environment where everyone feels psychologically safe. It's
          about recognizing the power of small acts of kindness and their impact
          on individual well-being and team dynamics. With Kindfully, we create
          a positive and supportive work environment that enhances satisfaction
          and improves engagement.
        </h4>
      </div>
    </div>
  );
}
export default ImageLeftTextRight;
