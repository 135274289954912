import React from 'react';
import './Footer.css';
import NewsletterSubscription from '../newsletterSubscribtion/NewsletterSubscription';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handleSitemapClick = () => {
    window.location.href = '/sitemap.xml';
  };

  return (
    <div className='main-footer-container'>
      <NewsletterSubscription />
      <div className='footer-container'>
        <div className='footer-details'>
          <h5>wrkfully</h5>
          <ul>
            <li>
              <button onClick={() => navigate('/')}>Home</button>
            </li>
            <li>
              <button onClick={() => navigate('/about-us')}>About Us</button>
            </li>
            <li>
              <button onClick={handleSitemapClick}>Sitemap</button>
            </li>
          </ul>
        </div>
        <div className='footer-details'>
          <h5>Assessments</h5>
          <ul>
            <li>
              <button onClick={() => navigate('/self-assessment')}>
                Self Assessment
              </button>
            </li>
            <li>
              <button onClick={() => navigate('/peer-review')}>
                Peer Review
              </button>
            </li>
          </ul>
        </div>
        <div className='footer-details'>
          <h5>Contact Us</h5>
          <ul>
            <li>
              <button onClick={() => navigate('/contact-us')}>
                Contact Us
              </button>
            </li>
            <li>
              <a
                href='https://www.linkedin.com/company/wrkfully'
                target='_blank'
                rel='noopener noreferrer'
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href='https://www.youtube.com/@wrkfully'
                target='_blank'
                rel='noopener noreferrer'
              >
                YouTube
              </a>
            </li>
          </ul>
        </div>
        <div className='footer-details'>
          <h5>Legal</h5>
          <ul>
            <li>
              <button onClick={() => navigate('/terms-and-conditions')}>
                Terms & Conditions
              </button>
            </li>
            <li>
              <button onClick={() => navigate('/privacy-policy')}>
                Privacy Policy
              </button>
            </li>
            <li>
              <button onClick={() => navigate('/cookie-policy')}>
                Cookie Policy
              </button>
            </li>
            <li>
              <button onClick={() => navigate('/licenses')}>Licenses</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
