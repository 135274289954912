import React from 'react';
import '../styles.css';

const MyButton = ({ children, className, onClick, size, style, outline }) => {
  const btnClassName = `btn ${size ? `btn-${size}` : ''} ${className ? className : ''}`;
  
  const outlineStyle = outline
    ? {
        backgroundColor: 'transparent',
        borderColor: style.color,
        borderWidth: '1px',
        borderStyle: 'solid',
      }
    : {};

  const combinedStyle = { ...style, ...outlineStyle };

  return (
    <button className={btnClassName} onClick={onClick} style={combinedStyle}>
      {children}
    </button>
  );
};

export default MyButton;