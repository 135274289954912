import React, { useState } from 'react';
import './FAQ.css';

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = index => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const faqItems = [
    {
      question: 'What is the wrkfully framework?',
      answer:
        'The wrkfully framework is our guiding philosophy for creating a positive, productive, and inclusive work environment. It encompasses three pillars - Workfully, Respectfully, and Kindfully - each of which focuses on our five key values: Be Fair, Understand, Laugh, Learn, and Ask Why. This framework is designed to foster an atmosphere of fairness, empathy, joy, continuous learning, and curiosity, encouraging every team member to contribute to their fullest potential.',
    },
    {
      question: 'How can I apply the wrkfully framework in my daily work?',
      answer:
        'You can apply the wrkfully framework by incorporating its values into your daily interactions and tasks. Treat everyone fairly, seek to understand other perspectives, bring joy and humor into your work, embrace a mindset of continuous learning, and do not be afraid to ask why things are done the way they are. By embodying these values, you will be aligning with the wrkfully framework and contributing positively to our organizational culture.',
    },
    {
      question:
        'How does the wrkfully framework contribute to a positive work environment?',
      answer:
        'The wrkfully framework promotes an inclusive, empathetic, and engaging work environment. By encouraging fairness, understanding, humor, continuous learning, and curiosity, it helps build strong relationships, promotes open dialogue, fosters creativity and innovation, and empowers everyone to contribute meaningfully. Its a formula for nurturing not only a positive work environment but also a high-performing and innovative organization.',
    },
    {
      question:
        'Are there any tools available to help us implement the wrkfully framework?',
      answer:
        'We offer three key resources to facilitate the implementation of the wrkfully framework, The Guide: This comprehensive guide offers detailed advice, including practical tips and real-life examples on how to adopt and implement the wrkfully values in your everyday work life. Self Assessment: With our Self Assessment tool, you have the opportunity to uncover your unique work persona through a simple questionnaire. You will receive your results along with personalized tips for self-improvement. This tool is designed to help you understand yourself better, grow personally and professionally, and be the positive change you wish to see at work. Peer Review: If you are ready for feedback, our Peer Review tool allows you to discover how others perceive your work persona. By requesting a Peer Review, you will receive valuable insights from colleagues and friends, and results that reflects your alignment with the fully principles. This external perspective can be a catalyst for further improvement and a key step towards creating a happier work reality. These resources are designed to give you a comprehensive understanding of how well you align with the wrkfully values and equip you with actionable insights for personal and professional growth.',
    },
  ];

  return (
    <div className='faq-container'>
      <h2>Frequently Asked Questions</h2>
      <div className='faq-items'>
        {faqItems.map((item, index) => (
          <div className='faq-item' key={index}>
            <div
              className='faq-question'
              onClick={() => toggleAccordion(index)}
            >
              <h3>{item.question}</h3>
              <div
                className={`arrow ${activeIndex === index ? 'active' : ''}`}
              />
            </div>
            <div
              className={`faq-answer ${activeIndex === index ? 'active' : ''}`}
            >
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;
