import React, { useState, useEffect } from 'react';
import Tab from './Tab';
import TabContent from './TabContent';
import './Tabs.css';
import MyButton from '../button/Button.js';
import { useNavigate } from 'react-router-dom';

const Tabs = ({ tabData }) => {
  const [activeTab, setActiveTab] = useState(tabData[0].id);
  const [animateTab, setAnimateTab] = useState(false);
  const [autoSwitch, setAutoSwitch] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let interval;

    if (autoSwitch) {
      interval = setInterval(() => {
        const currentIndex = tabData.findIndex(tab => tab.id === activeTab);
        const nextIndex = (currentIndex + 1) % tabData.length;
        setActiveTab(tabData[nextIndex].id);
        setAnimateTab(true);
      }, 5000); // Interval in milliseconds (5 seconds)
    }

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [activeTab, animateTab, autoSwitch, tabData]);

  const handleClick = tabId => {
    setActiveTab(tabId);
    setAnimateTab(true);
    setAutoSwitch(false); // Stop automatic switching when a tab is manually clicked
  };

  const handleClickLearnMore = tabId => {
    setActiveTab(tabId);
    setAnimateTab(true);
    setAutoSwitch(false); // Stop automatic switching when a tab is manually clicked

    const selectedTab = tabData.find(tab => tab.id === tabId);
    if (selectedTab && selectedTab.link) {
      navigate(selectedTab.link);
    }
  };

  const renderTabContent = tab => {
    const isActiveTab = activeTab === tab.id;

    return (
      <TabContent
        className={`tab-feature-container ${isActiveTab ? 'active' : ''}`}
        key={tab.id}
        content={
          <div className='tabs-content-container'>
            <div className='content-image'>
              <img src={tab.imgSrc} alt={tab.label} />
            </div>
            <div className='content-text-container'>
              <div className='title-content'>
                <h2>{tab.label}</h2>
              </div>
              <div className='body-content'>
                <h4>{tab.content}</h4>
              </div>
              {tab.link && (
                <MyButton
                  className='tab-link-button'
                  size='medium'
                  onClick={() => handleClickLearnMore(tab.id)}
                  style={{ backgroundColor: '#F005A6', color: '#FEFEFE' }}
                >
                  Learn More
                </MyButton>
              )}
            </div>
          </div>
        }
        isActive={isActiveTab}
        link={tab.link}
        buttonText={tab.buttonText}
        onAnimationEnd={() => setAnimateTab(false)}
        style={{
          opacity: isActiveTab && animateTab ? 1 : 0,
          transition: 'opacity 500ms',
          position: isActiveTab ? 'relative' : 'absolute',
        }}
      />
    );
  };

  return (
    <div className='tab-feature-container'>
      <div className='tabs-header'>
        {tabData.map(tab => (
          <Tab
            key={tab.id}
            label={tab.label}
            onClick={() => handleClick(tab.id)}
            isActive={activeTab === tab.id}
          />
        ))}
      </div>
      <div className='tabs-content-container2'>
        {tabData.map(tab => renderTabContent(tab))}
      </div>
    </div>
  );
};

export default Tabs;
