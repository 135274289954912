import '../tabComponent/Tabs.css';

const TabData = [
  {
    id: 'Our-Framework',
    label: 'Our Framework',
    imgSrc: '../images/wrkfully-image-1.png',
    content:
      'A unique approach to create a positive workplace and a positive you. Focusing on our three pillars: Workfully, Respectfully, and Kindfully. Enhance well-being, open communication, continuous learning and growth.',
    buttonText: 'Learn more',
    link: '/framework',
  },
  {
    id: 'the-dynamix-trio',
    label: 'Dynamix Trio',
    imgSrc: '/images/wrkfully-image-3.png',
    content:
      'Our dynamic trio: Workfully, Respectfully, and Kindfully. Each pillar uniquely applies the Fully Principles in its own context, creating a harmonious blend. When implemented together, they empower individuals and teams to thrive, fostering a flourishing workplace.',
    buttonText: 'Learn more',
    link: '/framework',
  },
  {
    id: 'fully-principles',
    label: 'Fully Principles',
    imgSrc: './images/wrkfully-image-2.png',
    content:
      "The Fully Principles are at the heart of everything we do. Fully means fairness, understanding, laughter, learning, and asking 'why'. These core values underpin our approach to enhancing workplace dynamics and promoting personal and professional growth.",
    buttonText: 'Learn more',
    link: '/framework',
  },
];

export default TabData;
