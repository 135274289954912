import React, { useState, useRef, useEffect } from 'react';
import './SelfAssessmentForm.css';
import questions from './questions'; // Import 'principles' array
import calculatePersona from '../calculatePersona';
import ResultsPage from './ResultsPage';
import HeroFramework from './heroFramework/HeroFramework';
import { DataStore, Auth } from 'aws-amplify';
import { SelfAssessment } from '../../models';

const IntroPage = ({
  handleStartAssessment,
  handleStartNewAssessment,
  hasSelectedAnswers,
}) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='intro-page'>
      <h1 className='intro-title'>Welcome to the Self-Assessment</h1>
      <p className='intro-text'>
        Ready to explore your unique work persona and receive personalized tips
        for growth? Take our 15-question self-assessment.
      </p>
      <div className='button-group'>
        {hasSelectedAnswers && (
          <button
            onClick={() => {
              handleStartAssessment();
              scrollToTop();
            }}
            className='continue-button'
          >
            Continue Assessment
          </button>
        )}
        <button
          onClick={() => {
            handleStartNewAssessment();
            scrollToTop(); // Scroll to top when starting a new assessment
          }}
          className='start-new-button'
        >
          Start New Assessment
        </button>
      </div>
      <HeroFramework />
    </div>
  );
};

const SelfAssessmentForm2 = () => {
  const [selectedButton, setSelectedButton] = useState({});
  const [activeQuestion, setActiveQuestion] = useState(0);
  const questionsRef = useRef([]);
  const [showResults, setShowResults] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const isAuthenticatedRef = useRef(false);

  useEffect(() => {
    // Enable scroll restoration when the component is mounted
    window.history.scrollRestoration = 'manual';

    // Scroll to the top of the page
    window.scrollTo(0, 0);
    loadSelectedAnswers();
  }, []);

  useEffect(() => {
    saveSelectedAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedButton]);

  const loadSelectedAnswers = () => {
    const storedAnswers = localStorage.getItem('selectedAnswers');
    if (storedAnswers) {
      setSelectedButton(JSON.parse(storedAnswers));
    }
  };

  const saveSelectedAnswers = () => {
    localStorage.setItem('selectedAnswers', JSON.stringify(selectedButton));
  };

  const handleStartAssessment = () => {
    setShowIntro(false);
  };

  const handleInputChange = (event, questionIndex) => {
    const { value } = event.target;

    setSelectedButton(prevState => ({
      ...prevState,
      [questionIndex]: value,
    }));

    // Find the index of the next unanswered question
    const nextUnansweredIndex = Object.keys(selectedButton).length + 1;

    setActiveQuestion(nextUnansweredIndex);

    // Scroll to the next question
    setTimeout(() => {
      if (questionsRef.current[nextUnansweredIndex]) {
        const nextQuestion = questionsRef.current[nextUnansweredIndex];
        const viewportHeight = window.innerHeight;
        const nextQuestionOffset = nextQuestion.offsetTop;
        const yOffset = nextQuestionOffset - viewportHeight / 2;
        window.scrollTo({ top: yOffset, behavior: 'smooth' });
      }
    }, 0);
  };

  async function checkAuthStatus() {
    try {
      await Auth.currentAuthenticatedUser();
      isAuthenticatedRef.current = true;
    } catch (error) {
      isAuthenticatedRef.current = false;
    }
  }

  const handleSubmit = async event => {
    event.preventDefault();
    const { principleScores } =
      calculatePersona(selectedButton);

    await checkAuthStatus();
    if (isAuthenticatedRef.current) {
      const user = await Auth.currentAuthenticatedUser();
      try {
        await DataStore.save(
          new SelfAssessment({
            name: JSON.stringify(selectedButton),
            description: JSON.stringify(principleScores),
            owner: user.attributes.sub.toString(),
          })
        );
      } catch (error) {
        console.log('Error saving self-assessment:', error);
      }
    }

    setShowResults(true);
  };

  const handleStartNewAssessment = () => {
    localStorage.removeItem('selectedAnswers');
    setSelectedButton({});
    setShowIntro(false);
  };

  const isLocalStorageEmpty = !localStorage.getItem('selectedAnswers');

  const hasSelectedAnswers =
    !isLocalStorageEmpty && Object.keys(selectedButton).length > 0;
  const isAllQuestionsAnswered =
    Object.keys(selectedButton).length === questions.length;

  return (
    <>
      {showIntro ? (
        <IntroPage
          handleStartAssessment={handleStartAssessment}
          handleStartNewAssessment={handleStartNewAssessment}
          hasSelectedAnswers={hasSelectedAnswers}
        />
      ) : showResults ? (
        <ResultsPage
          persona={calculatePersona(selectedButton)}
          selectedAnswers={selectedButton}
        />
      ) : (
        <form onSubmit={handleSubmit} className='likert-questionnaire-form'>
          <div className='likert-form'>
            <div className='likert-title'>
              <h1>Work Personality Test</h1>
            </div>
            <div className='likert-body'>
              <p>
                Welcome to our work persona self-assessment! For each statement
                below, please indicate your level of agreement from 'strongly
                disagree' to 'strongly agree.
              </p>
            </div>
            {questions.map((question, index) => (
              <div
                key={index}
                className='questionName'
                style={{ textAlign: 'center' }}
              >
                {question.name}
                <div
                  className={`questionContainer ${
                    activeQuestion === index ? 'active' : ''
                  } ${selectedButton[index] ? 'answered' : ''}`}
                  key={index}
                  ref={el => (questionsRef.current[index] = el)}
                >
                  <div className='questionTextLeft'>
                    <div>{question.left}</div>
                  </div>
                  <div className='likertScale'>
                    {[1, 2, 3, 4, 5].map(value => (
                      <label
                        key={value}
                        className={`likert-button likert-button-${value}`}
                      >
                        <input
                          type='radio'
                          name={question.name}
                          value={value}
                          checked={selectedButton[index] === String(value)}
                          onChange={event => handleInputChange(event, index)}
                        />
                        <span className='checkmark'></span>
                      </label>
                    ))}
                  </div>

                  <div className='questionTextRight'>
                    <div>{question.right}</div>
                  </div>
                </div>
              </div>
            ))}

            <button
              type='submit'
              className='likert-submit-button2'
              disabled={!isAllQuestionsAnswered}
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default SelfAssessmentForm2;
