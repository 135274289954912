import React, { useEffect, useState, useRef } from 'react';
import '../tabComponent/Tabs.css';
import Tabs from '../tabComponent/Tabs';
import TabsData from './TabData';
import './WrkfullyFramework.css';

function WrkfullyFramework() {
  const [activeTab, setActiveTab] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setActiveTab(prevTab => (prevTab + 1) % TabsData.length);
    }, 2000); // Interval in milliseconds (5 seconds)

    return () => {
      clearInterval(intervalRef.current); // Clear the interval when the component unmounts
    };
  }, []);

  const handleClick = buttonType => {
    console.log(`Clicked ${buttonType} button`);
    clearInterval(intervalRef.current); // Stop the automatic tab activation when a tab is clicked
  };

  return (
    <div className='tab-info'>
      <div
        className='title-frameworks'
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <h2>
          <i>
            "Every workplace can inspire creativity and joy. Together, we can
            create a world where diversity and inclusion are prioritized in
            every workplace. wrkfully offers the framework, tools and mindset
            needed to build environments that inspire innovation, cultivate
            belonging, and pave the way for a happier future."
          </i>
        </h2>
      </div>
      <div className='frameworks-tabs-content'>
        <Tabs
          tabData={TabsData}
          activeTab={activeTab}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
}

export default WrkfullyFramework;
